import React from "react";
import { Route } from "react-router-dom";
import { AdminList, FinanceList, SupervisorList, TokopediaList } from "./pages/redemption";
import {
    AmazingReportList,
    AmazingReportShow,
    TransactionReportList
} from "./pages";
import { UnregisterList } from "./pages/supplier";
import { ActivePromotion } from "./pages/promotion"
import PendingList from "./pages/changeAgent/PendingList";
import { WithPermissions } from "react-admin";
import _ from "lodash";
import AccountEdit from "./pages/account/AccountEdit";

const routes = [
    {
        exact: true,
        path: "/redemptions/check",
        render: ({ staticContext, ...props }) => (
            <WithPermissions
                render={({ permissions }) =>
                    Array.isArray(permissions) &&
                    (permissions &&
                        _.intersection(permissions, [
                            "SUPERADMIN",
                            "SUPERVISOR",
                            "ADMIN"
                        ]).length > 0 && (
                            <AdminList
                                {...props}
                                basePath="/redemptions/check"
                                resource="redemptions"
                                hasCreate={false}
                                hasEdit={false}
                                hasList={false}
                                hasShow={false}
                            />
                        ))
                }
            />
        )
    },
    {
        exact: true,
        path: "/redemptions/finance-approval",
        render: ({ staticContext, ...props }) => (
            <WithPermissions
                render={({ permissions }) =>
                    Array.isArray(permissions) &&
                    (permissions &&
                        _.intersection(permissions, ["SUPERADMIN", "FINANCE"])
                            .length > 0 && (
                            <FinanceList
                                {...props}
                                basePath="/redemptions/finance-approval"
                                resource="redemptions"
                                hasCreate={false}
                                hasEdit={false}
                                hasList={false}
                                hasShow={false}
                            />
                        ))
                }
            />
        )
    },
    {
        exact: true,
        path: "/redemptions/approval",
        render: ({ staticContext, ...props }) => (
            <WithPermissions
                render={({ permissions }) =>
                    Array.isArray(permissions) &&
                    (permissions &&
                        _.intersection(permissions, [
                            "SUPERADMIN",
                            "SUPERVISOR"
                        ]).length > 0 && (
                            <SupervisorList
                                {...props}
                                basePath="/redemptions/approval"
                                resource="redemptions"
                                hasCreate={false}
                                hasEdit={false}
                                hasList={false}
                                hasShow={false}
                            />
                        ))
                }
            />
        )
    },
    {
        exact: true,
        path: "/redemptions/tokopedia",
        render: ({ staticContext, ...props }) => (
            <WithPermissions
                render={({ permissions }) =>
                    Array.isArray(permissions) &&
                    (permissions &&
                        _.intersection(permissions, [
                            "SUPERADMIN",
                            "SUPERVISOR",
                            "ADMIN",
                            "FINANCE"
                        ]).length > 0 && (
                            <TokopediaList
                                {...props}
                                basePath="/redemptions/tokopedia"
                                resource="redemptions"
                                hasCreate={false}
                                hasEdit={false}
                                hasList={false}
                                hasShow={false}
                            />
                        ))
                }
            />
        )
    },
    {
        exact: true,
        path: "/amazing-reports",
        render: ({ staticContext, ...props }) => (
            <AmazingReportList
                {...props}
                basePath="/amazing-reports"
                resource="amazing"
                hasCreate={false}
                hasEdit={false}
                hasList={false}
                hasShow={false}
            />
        )
    },
    {
        exact: true,
        path: "/amazing-reports/:id",
        render: ({ staticContext, ...props }) => (
            <AmazingReportShow {...props} />
        )
    },
    {
        exact: true,
        path: "/change-agent/pending",
        render: ({ staticContext, ...props }) => (
            <PendingList
                {...props}
                basePath="/change-agent/pending"
                resource="change-agent"
                hasCreate={false}
                hasEdit={false}
                hasList={false}
                hasShow={false}
            />
        )
    },
    {
        exact: true,
        path: "/transactions/reports",
        render: ({ staticContext, ...props }) => (
            <TransactionReportList
                {...props}
                basePath="/transactions/reports"
                resource="transactions/reports"
                hasCreate={false}
                hasEdit={false}
                hasList={false}
                hasShow={false}
            />
        )
    },
    {
        exact: true,
        path: "/unregister-suppliers",
        render: ({ staticContext, ...props }) => (
            <UnregisterList
                {...props}
                basePath="/unregister-suppliers"
                resource="suppliers/unregister-suppliers"
                hasCreate={false}
                hasEdit={false}
                hasList={false}
                hasShow={false}
            />
        )
    },
    {
        exact: true,
        path: "/active-promotion",
        render: ({ staticContext, ...props }) => (
            <ActivePromotion
                {...props}
                basePath="/active-promotion"
                resource="promotions/active-promotion"
                hasCreate={false}
                hasEdit={false}
                hasList={false}
                hasShow={false}
            />
        )
    },
    {
        exact: true,
        path: "/my-account",
        render: ({ staticContext, ...props }) => (
            <AccountEdit
                {...props}
                basePath="/my-account"
                resource="admins"
                hasCreate={false}
                hasEdit={false}
                hasList={false}
                hasShow={false}
            />
        )
    }
];

export default routes.map(route => <Route {...route} />);
