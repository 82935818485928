import React from "react";
import { Datagrid, DateField, List, NumberField, Pagination, TextField } from "react-admin";
import Filter from "./Filter.js";
import Actions from './Action'

export default props => (
    <List
        {...props}
        bulkActionButtons={false}
        actions = {<Actions />}
        exporter={false}
        filters={<Filter />}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
        perPage={25}
        sort={{
            field: 'CheckOut',
            order: 'DESC',
        }}
    >
        <Datagrid rowClick="show">
            <TextField source="CustomerCode" label="Agent Code" sortable={false} />
            <TextField source="UserID" label="User ID" sortable={false} />
            <TextField source="HotelName" label="Hotel" sortable={false} />
            <DateField source="BookingDate" label="Booking Date" sortable={false} />
            <DateField source="CheckIn" label="Check-In" />
            <DateField source="CheckOut" label="Check-Out" />
            <NumberField source="Room" sortable={false} />
            <NumberField source="Night" sortable={false} />
            <NumberField source="Quantity" label="RN" sortable={false} />
            <TextField source="PNRNumber" label="PNR Number" sortable={false} />
            <TextField source="VoucherNumber" label="Voucher Number" sortable={false} />
            <TextField source="PaxName" label="Pax Name" sortable={false} />
        </Datagrid>
    </List>
);