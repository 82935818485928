import React from 'react';
import { BooleanInput, ImageField, ImageInput, LongTextInput, required, SimpleForm, TextInput } from 'react-admin';

export default props => (
    <SimpleForm
        {...props}
        redirect="show"
    >
            <TextInput source="Agent" label="Agent Name" validate={[required()]} />
            <TextInput source="Name" validate={[required()]} />
            <TextInput source="City" validate={[required()]} />
            <LongTextInput source="Testimony" validate={[required()]} />
            <LongTextInput source="TestimonyEn" validate={[required()]} />
            <BooleanInput source="Approved" validate={[required()]} />
            <ImageInput source="Image" accept="image/*" validate={props.mode === "CREATE" ? [required()] : undefined}>
                <ImageField source="src" />
            </ImageInput>
    </SimpleForm>
)