import React from "react"
import {
    Show,
    TextField,
    DateField,
    NumberField,
    TabbedShowLayout,
    Tab,
    ArrayField,
    Datagrid
} from "react-admin"

export default props => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label="Summary">
                {/* <TextField source="id" /> */}
                {/* <TextField source="UUID" /> */}
                <DateField source="BookingDate" label="Booking Date" />
                <TextField source="HotelName" label="Hotel" />
                <DateField source="CheckIn" label="Check-In" />
                <DateField source="CheckOut" label="Check-Out" />
                <NumberField source="Room" />
                <NumberField source="Night" />
                <NumberField source="Quantity" />
                <TextField source="City"/>
                <TextField source="Country" />
                <TextField source="PaxName" label="Pax Name" />
                <NumberField source="SalesBase" label="Sales Base" />
                <TextField source="CustomerCode" label="Customer Code" />
                <TextField source="UserID" label="User ID" />
                <NumberField source="GroupLineNo" label="Group Line No." />
                <TextField source="CustomerName" label="Customer Name" />
                <TextField source="CustomerCity" label="Customer City" />
                <TextField source="CustomerCountry" label="Customer Country" />
                <NumberField source="Points" />
                {/* <TextField source="CurrSalesBiling" label="Currency" /> */}
                {/* <TextField source="HotelId" label="Hotel ID" /> */}
                {/* <DateField source="InvoiceDate" label="Invoice Date" /> */}
                {/* <TextField source="InvoiceNumber" label="Invoice Number" /> */}
                {/* <TextField source="PNR" label="PNR" /> */}
                {/* <TextField source="PNRNumber" label="PNR Number" /> */}
                {/* <TextField source="PONO" label="PONO" /> */}
                {/* <TextField source="PsCustomerCode" label="PS Customer Code" /> */}
                {/* <TextField source="PsHotelId" label="PS Hotel ID" /> */}
                {/* <TextField source="PsSupplierId" label="PS Supplier ID" /> */}
                {/* <TextField source="SOREF" label="SOREF" /> */}
                {/* <DateField source="SatlementDate" label="Settlement Date" /> */}
                {/* <TextField source="SupplierId" label="Supplier ID" /> */}
                {/* <TextField source="VoucherNumber" label="Voucher Number" /> */}
                {/* <TextField source="WebInvoiceNumber" label="Web Invoice Number" /> */}
                {/* <BooleanField source="Processed" /> */}
                {/* <DateField source="UTCCreated" /> */}
                {/* <TextField source="CreatedBy" /> */}
                {/* <DateField source="UTCModified" /> */}
                {/* <TextField source="ModifiedBy" /> */}
            </Tab>
            <Tab label="Details" path="details">
                <ArrayField source="details.rows" label="Details">
                    <Datagrid>
                        {/* <TextField source="id" /> */}
                        {/* <TextField source="UUID" /> */}
                        {/* <TextField source="TransactionUUID" /> */}
                        <TextField source="PromotionID" label="Promotion ID" />
                        <DateField source="StayDate" label="Stay Date" />
                        <NumberField source="Points" />
                        {/* <DateField source="UTCCreated" /> */}
                        {/* <TextField source="CreatedBy" /> */}
                        {/* <DateField source="UTCModified" /> */}
                        {/* <TextField source="ModifiedBy" /> */}
                    </Datagrid>
                </ArrayField>
            </Tab>
        </TabbedShowLayout>
    </Show>
)