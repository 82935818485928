import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput
} from "react-admin";

export const AdminsCreate = props => (
  <Create title={"Create Admin"} {...props}>
    <SimpleForm redirect="list">
      <TextInput label="User ID" source="UserId" />
      <TextInput label="Password" source="Password" type="password" />
      <ReferenceArrayInput
        label="Country"
        source="CountryName"
        reference="countries"
        sort={{ field: "Name", order: "ASC" }}
      >
        <SelectArrayInput optionText="Name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        label="Permission"
        source="Permission"
        reference="permissions"
        sort={{ field: "id", order: "ASC" }}
      >
        <SelectArrayInput optionText="id" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);
