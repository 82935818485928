import React from "react";
import { Create } from "react-admin";
import Form from './Form';

export default props => (
  <Create
    {...props}
  >
    <Form mode="CREATE" />
  </Create>
);
