import React from "react";
import { BooleanField, DateField, ImageField, RichTextField, Show, SimpleShowLayout, TextField } from "react-admin";

export default props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="Agent" label="Agent Name" />
      <TextField source="Name" />
      <TextField source="City" />
      <RichTextField source="Testimony" label="Local" />
      <RichTextField source="TestimonyEn" label="Global" />
      <BooleanField source="Approved" />
      <DateField source="UTCCreated" label="Date Created" showTime />
      <TextField source="CreatedBy" label="Created By" />
      <DateField source="UTCModified" label="Date Modified" showTime />
      <TextField source="ModifiedBy" label="Modified By" />
      <ImageField source="Image" />
    </SimpleShowLayout>
  </Show>
);