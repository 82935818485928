import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { showNotification, Button } from "react-admin";
import { push } from "react-router-redux";
import DeactivateIcon from "@material-ui/icons/Clear";
import Tooltip from "@material-ui/core/Tooltip";

class DeactivateButton extends Component {
    handleClick = () => {
        const { push, record, showNotification } = this.props;
        fetch(
            `${process.env.REACT_APP_API_URL}/faq/${record.id}/deactivate`,
            {
                method: "PUT",
                headers: {
                    accept: "application/json",
                    "content-type": "application/json; charset=utf-8",
                    authorization: `Bearer ${localStorage.token}`,
                    "x-api-key": process.env.REACT_APP_API_KEY
                }
            }
        )
            .then(() => {
                showNotification("Deactivate Success");
                push("/faq");
            })
            .catch(e => {
                showNotification("Error: Deactivate problem", "warning");
            });
    };

    render() {
        const {
            record: { Active }
        } = this.props;
        return (
            Active === true && (
                <Button onClick={this.handleClick}>
                    <Tooltip title="Deactivate">
                        <DeactivateIcon />
                    </Tooltip>
                </Button>
            )
        );
    }
}

DeactivateButton.propTypes = {
    push: PropTypes.func,
    record: PropTypes.object,
    showNotification: PropTypes.func
};

export default connect(
    null,
    {
        showNotification,
        push
    }
)(DeactivateButton);
