import React from "react";
import {
  Filter,
  TextInput,
  SelectInput
} from "react-admin";

export default props => (
  <Filter {...props}>
    {/* <TextInput label="Search" source="q" /> */}
    <TextInput label="Agent Code" source="AgentCode" />
    <TextInput label="User Id" source="UserId" />
    <SelectInput source="Source" choices={[
        { id: "REDEMPTION", name:"Redemption" },
        { id: "MIGRATION", name:"Migration" },
        { id: "TRANSACTION", name:"Transaction" },
        { id: "REGISTRATION", name:"Registration" },
        { id: "TRANSFER", name:"Transfer" },
      ]} />
      <SelectInput source="Account" choices={[
        { id: "CREDIT", name:"Credit" },
        { id: "DEBIT", name:"Debit" },
      ]} />
      <TextInput source="Description" />
  </Filter>
)