import Collapse from "@material-ui/core/Collapse";
import MenuList from "@material-ui/core/MenuList";
import { createStyles, withStyles } from "@material-ui/core/styles";
import _ from "lodash";
// import ExpandLess from '@material-ui/icons/ExpandLess';
// import ExpandMore from '@material-ui/icons/ExpandMore';
// import DefaultIcon from '@material-ui/icons/ViewList';
import SettingIcon from "@material-ui/icons/Settings";
import SuperadminIcon from "@material-ui/icons/Group";
import SuperadminListIcon from "@material-ui/icons/GroupAdd";
import GiftIcon from "@material-ui/icons/LocalAtm";
import TransactionIcon from "@material-ui/icons/SwapVert";
import MemberIcon from "@material-ui/icons/Person";
import MemberApprovalIcon from "@material-ui/icons/PersonAdd";
import PromotionIcon from "@material-ui/icons/Style";
import ImageIcon from "@material-ui/icons/Collections";
import TestimoniIcon from "@material-ui/icons/Forum";
// import PointIcon from "@material-ui/icons/ControlPoint";
import AgentIcon from "@material-ui/icons/AccountBox";
import HotelIcon from "@material-ui/icons/Hotel";
import CountryIcon from "@material-ui/icons/Public";
import MemberChangeIcon from "@material-ui/icons/PersonOutline";
import ImportIcon from "@material-ui/icons/Unarchive";
import ReportIcon from "@material-ui/icons/Archive";
import PublishIcon from '@material-ui/icons/Publish';
// import GeniusIcon from "@material-ui/icons/VerifiedUser";
import AccountStatementIcon from "@material-ui/icons/SwapHoriz";
import RedeemIcon from "@material-ui/icons/Redeem";
import PermissionIcon from "@material-ui/icons/Visibility";
import EventIcon from "@material-ui/icons/EventAvailable";
import SupplierIcon from "@material-ui/icons/Business";
import Event from '@material-ui/icons/Event'
import SettingsRemote from '@material-ui/icons/SettingsRemote'
import StarsIcon from '@material-ui/icons/Stars'
import LiveHelp from '@material-ui/icons/LiveHelp'
import HelpOutline from '@material-ui/icons/HelpOutline'
import SyncDisabled from '@material-ui/icons/SyncDisabled'
import Gavel from '@material-ui/icons/Gavel'
import RedeemRequest from '@material-ui/icons/Timelapse'
import LocalActivity from '@material-ui/icons/LocalActivity'
import classnames from "classnames";
// import inflection from 'inflection';
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import {
    DashboardMenuItem,
    getResources,
    MenuItemLink,
    Responsive,
    translate,
    WithPermissions
} from "react-admin";
import { connect } from "react-redux";
import compose from "recompose/compose";
import MyMenuItemLink from "./MenuItemLink";

const styles = createStyles({
    main: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start"
    }
});

// const translatedResourceName = (resource, translate) => translate(`resources.${resource.name}.name`, {
//     smart_count: 2,
//     _: resource.options && resource.options.label ? translate(resource.options.label, { smart_count: 2, _: resource.options.label, }) : inflection.humanize(inflection.pluralize(resource.name)),
// });

class Menu extends React.Component {
    state = {
        target: undefined,
        open: false
    };

    handleClick = target => e => {
        e.preventDefault();
        this.setState(() => ({
            target,
            open: true
        }));
    };

    render() {
        const {
            classes,
            className,
            dense,
            hasDashboard,
            onMenuClick,
            open,
            pathname,
            resources,
            translate,
            logout,
            ...rest
        } = this.props;
        return (
            <MenuList className={classnames(classes.main, className)} {...rest}>
                {hasDashboard && <DashboardMenuItem onClick={onMenuClick} />}
                {/* {resources
                    .filter(r => r.hasList)
                    .map(resource => (
                        <MenuItemLink
                            key={resource.name}
                            to={`/${resource.name}`}
                            primaryText={translatedResourceName(resource, translate)}
                            leftIcon={
                                resource.icon ? <resource.icon /> : <DefaultIcon />
                            }
                            onClick={onMenuClick}
                            dense={dense}
                        />
                    ))} */}

                {/* Agents */}
                <WithPermissions
                    render={({ permissions }) =>
                        Array.isArray(permissions) &&
                        ((permissions &&
                            _.intersection(permissions, [
                                "SUPERADMIN",
                                "SUPERVISOR",
                                "ADMIN"
                            ]).length > 0 && (
                                <MyMenuItemLink
                                    to="/"
                                    primaryText="Agents"
                                    leftIcon={<AgentIcon />}
                                    onClick={this.handleClick("agents")}
                                    dense={dense}
                                >
                                    <Collapse
                                        in={
                                            this.state.target === "agents" &&
                                            this.state.open
                                        }
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <MenuList disablePadding>
                                            <MenuItemLink
                                                to="/agents"
                                                primaryText="Agent List"
                                                leftIcon={<AgentIcon />}
                                                onClick={onMenuClick}
                                                dense={dense}
                                            />
                                            <MenuItemLink
                                                to="/members"
                                                primaryText="Member List"
                                                leftIcon={<MemberIcon />}
                                                onClick={onMenuClick}
                                                dense={dense}
                                            />
                                            <MenuItemLink
                                                to="/members/approval"
                                                primaryText="Registration Approval"
                                                leftIcon={
                                                    <MemberApprovalIcon />
                                                }
                                                onClick={onMenuClick}
                                                dense={dense}
                                            />
                                            <MenuItemLink
                                                to="/change-agent/pending"
                                                primaryText="Change Agent Request"
                                                leftIcon={<MemberChangeIcon />}
                                                onClick={onMenuClick}
                                                dense={dense}
                                            />
                                            <MenuItemLink
                                                to="/change-agent"
                                                primaryText="Change Agent Report"
                                                leftIcon={<ReportIcon />}
                                                onClick={onMenuClick}
                                                dense={dense}
                                            />
                                            <MenuItemLink
                                                to="/jarvis-code/create"
                                                primaryText="Update Jarvis Code"
                                                leftIcon={<ReportIcon />}
                                                onClick={onMenuClick}
                                                dense={dense}
                                            />
                                            {/*
                                            <MenuItemLink
                                                to="/unclaimed-points"
                                                primaryText="Unclaimed Points"
                                                leftIcon={<ReportIcon />}
                                                onClick={onMenuClick}
                                                dense={dense}
                                            /> */}
                                        </MenuList>
                                    </Collapse>
                                </MyMenuItemLink>
                            )) ||
                            (permissions &&
                                _.intersection(permissions, ["MARKETING"])
                                    .length > 0 && (
                                    <MyMenuItemLink
                                        to="/"
                                        primaryText="Agents"
                                        leftIcon={<AgentIcon />}
                                        onClick={this.handleClick("agents")}
                                        dense={dense}
                                    >
                                        <Collapse
                                            in={
                                                this.state.target ===
                                                    "agents" && this.state.open
                                            }
                                            timeout="auto"
                                            unmountOnExit
                                        >
                                            <MenuList disablePadding>
                                                <MenuItemLink
                                                    to="/agents"
                                                    primaryText="Agent List"
                                                    leftIcon={<AgentIcon />}
                                                    onClick={onMenuClick}
                                                    dense={dense}
                                                />
                                            </MenuList>
                                        </Collapse>
                                    </MyMenuItemLink>
                                )))
                    }
                />

                {/* Transactions */}
                <WithPermissions
                    render={({ permissions }) =>
                        Array.isArray(permissions) &&
                        (permissions &&
                            _.intersection(permissions, [
                                "SUPERADMIN",
                                "SUPERVISOR",
                                "ADMIN"
                            ]).length > 0 && (
                                <MyMenuItemLink
                                    to="/"
                                    primaryText="Transactions"
                                    leftIcon={<TransactionIcon />}
                                    onClick={this.handleClick("transactions")}
                                    dense={dense}
                                >
                                    <Collapse
                                        in={
                                            this.state.target ===
                                                "transactions" &&
                                            this.state.open
                                        }
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <MenuList disablePadding>
                                            <MenuItemLink
                                                to="/transactions"
                                                primaryText="Transaction List"
                                                leftIcon={<TransactionIcon />}
                                                onClick={onMenuClick}
                                                dense={dense}
                                            />
                                            <MenuItemLink
                                                to="/account-statements"
                                                primaryText="Account Statement List"
                                                leftIcon={
                                                    <AccountStatementIcon />
                                                }
                                                onClick={onMenuClick}
                                                dense={dense}
                                            />
                                            <WithPermissions
                                                render={({ permissions }) =>
                                                    permissions &&
                                                    _.intersection(
                                                        permissions,
                                                        ["SUPERADMIN", "ADMIN"]
                                                    ).length > 0 && (
                                                        <Fragment>
                                                            <MenuItemLink
                                                                to="/transactions/reports"
                                                                primaryText="Report Transactions"
                                                                leftIcon={
                                                                    <ReportIcon />
                                                                }
                                                                onClick={
                                                                    onMenuClick
                                                                }
                                                                dense={dense}
                                                            />
                                                            <MenuItemLink
                                                                to="/powersuite"
                                                                primaryText="Import Powersuite Transactions"
                                                                leftIcon={
                                                                    <ImportIcon />
                                                                }
                                                                onClick={
                                                                    onMenuClick
                                                                }
                                                                dense={dense}
                                                            />
                                                            <MenuItemLink
                                                                to="/powersuite/from-supplier/create"
                                                                primaryText="Distribute Point From Supplier"
                                                                leftIcon={<PublishIcon />}
                                                                onClick={onMenuClick}
                                                                dense={dense}
                                                            />
                                                        </Fragment>
                                                    )
                                                }
                                            />
                                            <WithPermissions
                                                render={({ permissions }) =>
                                                    permissions &&
                                                    _.intersection(
                                                        permissions,
                                                        [
                                                            "SUPERADMIN",
                                                            "ADMIN",
                                                            "SUPERVISOR"
                                                        ]
                                                    ).length > 0 && (
                                                        <MenuItemLink
                                                            to="/opsigo"
                                                            primaryText="Import Opsigo Transactions"
                                                            leftIcon={
                                                                <ImportIcon />
                                                            }
                                                            onClick={
                                                                onMenuClick
                                                            }
                                                            dense={dense}
                                                        />
                                                    )
                                                }
                                            />
                                        </MenuList>
                                    </Collapse>
                                </MyMenuItemLink>
                            ))
                    }
                />

                {/* Rewards */}
                <WithPermissions
                    render={({ permissions }) =>
                        Array.isArray(permissions) &&
                        (permissions &&
                            _.intersection(permissions, [
                                "SUPERADMIN",
                                "SUPERVISOR",
                                "ADMIN",
                                "MARKETING",
                                "FINANCE"
                            ]).length > 0 && (
                                <MyMenuItemLink
                                to="/"
                                primaryText="Rewards"
                                leftIcon={<GiftIcon />}
                                onClick={this.handleClick("rewards")}
                                dense={dense}
                            >
                            <Collapse
                                in={this.state.target === "rewards" && this.state.open}
                                timeout="auto"
                                unmountOnExit
                            >
                            <MenuList disablePadding>
                                <WithPermissions
                                    render={({ permissions }) =>
                                        permissions &&
                                        _.intersection(
                                            permissions,
                                            [
                                                "SUPERADMIN",
                                                "SUPERVISOR",
                                                "MARKETING",
                                                "ADMIN"
                                            ]
                                        ).length > 0 && (
                                            <MenuItemLink
                                                to="/rewards"
                                                primaryText="Reward List"
                                                leftIcon={
                                                    <GiftIcon />
                                                }
                                                onClick={
                                                    onMenuClick
                                                }
                                                dense={dense}
                                            />
                                        )
                                    }
                                />
                                <WithPermissions
                                    render={({ permissions }) =>
                                        permissions &&
                                        _.intersection(
                                            permissions,
                                            [
                                                "SUPERADMIN",
                                                "SUPERVISOR",
                                                "ADMIN"
                                            ]
                                        ).length > 0 && (
                                            <MenuItemLink
                                                to="/redemptions/check"
                                                primaryText="Redemption - Check"
                                                leftIcon={
                                                    <RedeemIcon />
                                                }
                                                onClick={
                                                    onMenuClick
                                                }
                                                dense={dense}
                                            />
                                        )
                                    }
                                />
                                <WithPermissions
                                    render={({ permissions }) =>
                                        permissions &&
                                        _.intersection(
                                            permissions,
                                            [
                                                "SUPERADMIN",
                                                "SUPERVISOR"
                                            ]
                                        ).length > 0 && (
                                            <MenuItemLink
                                                to="/redemptions/approval"
                                                primaryText="Redemption - Approval"
                                                leftIcon={
                                                    <RedeemIcon />
                                                }
                                                onClick={
                                                    onMenuClick
                                                }
                                                dense={dense}
                                            />
                                        )
                                    }
                                />
                                <WithPermissions
                                    render={({ permissions }) =>
                                        permissions &&
                                        _.intersection(
                                            permissions,
                                            [
                                                "SUPERADMIN",
                                                "FINANCE"
                                            ]
                                        ).length > 0 && (
                                            <MenuItemLink
                                                to="/redemptions/finance-approval"
                                                primaryText="Redemption - Finance Approval"
                                                leftIcon={
                                                    <RedeemIcon />
                                                }
                                                onClick={
                                                    onMenuClick
                                                }
                                                dense={dense}
                                            />
                                        )
                                    }
                                />
                                <WithPermissions
                                    render={({ permissions }) =>
                                        permissions &&
                                        _.intersection(
                                            permissions,
                                            [
                                                "SUPERADMIN",
                                                "SUPERVISOR",
                                                "ADMIN",
                                                "FINANCE"
                                            ]
                                        ).length > 0 && (
                                            <MenuItemLink
                                                to="/redemptions"
                                                primaryText="Redemption - Report"
                                                leftIcon={
                                                    <ReportIcon />
                                                }
                                                onClick={
                                                    onMenuClick
                                                }
                                                dense={dense}
                                            />
                                        )
                                    }
                                />
                                <WithPermissions
                                    render={({ permissions }) =>
                                        permissions &&
                                        _.intersection(
                                            permissions,
                                            [
                                                "SUPERADMIN",
                                                "SUPERVISOR",
                                                "ADMIN",
                                                "FINANCE"
                                            ]
                                        ).length > 0 && (
                                            <MenuItemLink
                                                to="/redemptions/tokopedia"
                                                primaryText="Redemption - Tokopedia"
                                                leftIcon={
                                                    <RedeemIcon />
                                                }
                                                onClick={
                                                    onMenuClick
                                                }
                                                dense={dense}
                                            />
                                        )
                                    }
                                />
                                <WithPermissions
                                    render={({ permissions }) =>
                                        permissions &&
                                        _.intersection(
                                            permissions,
                                            [
                                                "SUPERADMIN",
                                                "SUPERVISOR",
                                                "MARKETING",
                                                "ADMIN"
                                            ]
                                        ).length > 0 && (
                                            <MenuItemLink
                                                to="/redemption-request"
                                                primaryText="Redemption Request"
                                                leftIcon={
                                                    <RedeemRequest />
                                                }
                                                onClick={
                                                    onMenuClick
                                                }
                                                dense={dense}
                                            />
                                        )
                                    }
                                />
                            </MenuList>
                            </Collapse>
                        </MyMenuItemLink>
                        ))
                    }
                />

                {/* Promotions */}
                <WithPermissions
                    render={({ permissions }) =>
                        Array.isArray(permissions) &&
                        (permissions &&
                            _.intersection(permissions, [
                                "SUPERADMIN",
                                "SUPERVISOR",
                                "ADMIN",
                                "MARKETING",
                                "FINANCE"
                            ]).length > 0 && (
                                <MyMenuItemLink
                                    to="/"
                                    primaryText="Promotions"
                                    leftIcon={<PromotionIcon />}
                                    onClick={this.handleClick("promotions")}
                                    dense={dense}
                                >
                                <Collapse in={
                                    this.state.target === "promotions" &&
                                    this.state.open
                                }
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <MenuList disablePadding>
                                        <WithPermissions
                                            render={({ permissions }) =>
                                                permissions &&
                                                _.intersection(
                                                    permissions,
                                                    [
                                                        "SUPERADMIN",
                                                        "SUPERVISOR",
                                                        "ADMIN",
                                                        "MARKETING"
                                                    ]
                                                ).length > 0 && (
                                                    <Fragment>
                                                        <MenuItemLink
                                                            to="/promotions"
                                                            primaryText="Promo List"
                                                            leftIcon={
                                                                <PromotionIcon />
                                                            }
                                                            onClick={
                                                                onMenuClick
                                                            }
                                                            dense={dense}
                                                        />
                                                        <MenuItemLink
                                                            to="/pages"
                                                            primaryText="Page/Banner List"
                                                            leftIcon={
                                                                <ImageIcon />
                                                            }
                                                            onClick={
                                                                onMenuClick
                                                            }
                                                            dense={dense}
                                                        />
                                                        <MenuItemLink
                                                            to="/events"
                                                            primaryText="MG Event"
                                                            leftIcon={
                                                                <Event />
                                                            }
                                                            onClick={
                                                                onMenuClick
                                                            }
                                                            dense={dense}
                                                        />
                                                        <MenuItemLink
                                                            to="/testimonials"
                                                            primaryText="Testimonials"
                                                            leftIcon={
                                                                <TestimoniIcon />
                                                            }
                                                            onClick={
                                                                onMenuClick
                                                            }
                                                            dense={dense}
                                                        />
                                                        <MenuItemLink
                                                            to="/active-promotion"
                                                            primaryText="Active Promotion"
                                                            leftIcon={
                                                                <LocalActivity />
                                                            }
                                                            onClick={
                                                                onMenuClick
                                                            }
                                                            dense={dense}
                                                        />
                                                    </Fragment>
                                                )
                                            }
                                        />
                                        <MenuItemLink
                                            to="/amazing-reports"
                                            primaryText="Amazing Race - Report"
                                            leftIcon={<ReportIcon />}
                                            onClick={onMenuClick}
                                            dense={dense}
                                        />
                                    </MenuList>
                                </Collapse>
                            </MyMenuItemLink>
                        ))
                    }
                />

                {/* Settings */}
                <WithPermissions
                    render={({ permissions }) =>
                        Array.isArray(permissions) &&
                        (permissions &&
                            _.intersection(permissions, [
                                "SUPERADMIN",
                                "SUPERVISOR",
                                "ADMIN",
                                "MARKETING"
                            ]).length > 0 && (
                                <MyMenuItemLink
                                    to="/"
                                    primaryText="Settings"
                                    leftIcon={<SettingIcon />}
                                    onClick={this.handleClick("settings")}
                                    dense={dense}
                                >
                                    <Collapse
                                        in={
                                            this.state.target === "settings" &&
                                            this.state.open
                                        }
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <MenuList disablePadding>
                                            <WithPermissions
                                                render={({ permissions }) =>
                                                    permissions &&
                                                    _.intersection(
                                                        permissions,
                                                        [
                                                            "SUPERADMIN",
                                                            "MARKETING"
                                                        ]
                                                    ).length > 0 && (
                                                        <Fragment>
                                                            <MenuItemLink
                                                                to="/tnc"
                                                                primaryText="Terms & Conditions"
                                                                leftIcon={
                                                                    <Gavel />
                                                                }
                                                                onClick={
                                                                    onMenuClick
                                                                }
                                                                dense={dense}
                                                            />
                                                            <MenuItemLink
                                                                to="/faq"
                                                                primaryText="FAQ"
                                                                leftIcon={
                                                                    <LiveHelp />
                                                                }
                                                                onClick={
                                                                    onMenuClick
                                                                }
                                                                dense={dense}
                                                            />
                                                            <MenuItemLink
                                                                to="/faq-category"
                                                                primaryText="FAQ Category"
                                                                leftIcon={
                                                                    <HelpOutline />
                                                                }
                                                                onClick={
                                                                    onMenuClick
                                                                }
                                                                dense={dense}
                                                            />
                                                            <MenuItemLink
                                                                to="/lookup"
                                                                primaryText="Lookup"
                                                                leftIcon={
                                                                    <SettingsRemote />
                                                                }
                                                                onClick={
                                                                    onMenuClick
                                                                }
                                                                dense={dense}
                                                            />
                                                            <MenuItemLink
                                                                to="/point-value"
                                                                primaryText="MGF Point"
                                                                leftIcon={
                                                                    <StarsIcon />
                                                                }
                                                                onClick={
                                                                    onMenuClick
                                                                }
                                                                dense={dense}
                                                            />
                                                        </Fragment>
                                                    )
                                                }
                                            />
                                            <WithPermissions
                                                render={({ permissions }) =>
                                                    permissions &&
                                                    _.intersection(
                                                        permissions,
                                                        [
                                                            "SUPERADMIN",
                                                            "SUPERVISOR",
                                                            "ADMIN"
                                                        ]
                                                    ).length > 0 && (
                                                        <Fragment>
                                                            <MenuItemLink
                                                                to="/suppliers"
                                                                primaryText="Supplier List"
                                                                leftIcon={
                                                                    <SupplierIcon />
                                                                }
                                                                onClick={
                                                                    onMenuClick
                                                                }
                                                                dense={dense}
                                                            />
                                                            <MenuItemLink
                                                                to="/unregister-suppliers"
                                                                primaryText="Unregister Supplier"
                                                                leftIcon={
                                                                    <SyncDisabled />
                                                                }
                                                                onClick={
                                                                    onMenuClick
                                                                }
                                                                dense={dense}
                                                            />
                                                        </Fragment>
                                                    )
                                                }
                                            />
                                            <MenuItemLink
                                                to="/hotels"
                                                primaryText="Hotel List"
                                                leftIcon={<HotelIcon />}
                                                onClick={onMenuClick}
                                                dense={dense}
                                            />
                                            {/*<MenuItemLink
                                                to="/bulk-upload-hotels/create"
                                                primaryText="Upload Hotels"
                                                leftIcon={<HotelIcon />}
                                                onClick={onMenuClick}
                                                dense={dense}
                                            />*/}
                                            {/* <MenuItemLink
                                to="/genius"
                                primaryText="Genius"
                                leftIcon={<GeniusIcon/>}
                                onClick={onMenuClick}
                                dense={dense}
                            />
                            <MenuItemLink
                                to="/base-points"
                                primaryText="Base Points"
                                leftIcon={<PointIcon/>}
                                onClick={onMenuClick}
                                dense={dense}
                            /> */}
                                        </MenuList>
                                    </Collapse>
                                </MyMenuItemLink>
                            ))
                    }
                />

                {/* Super Admin */}
                <WithPermissions
                    render={({ permissions }) =>
                        Array.isArray(permissions) &&
                        (permissions.includes("SUPERADMIN") && (
                            <MyMenuItemLink
                                to="/"
                                primaryText="Super Admin"
                                leftIcon={<SuperadminIcon />}
                                onClick={this.handleClick("superadmin")}
                                dense={dense}
                            >
                                <Collapse
                                    in={
                                        this.state.target === "superadmin" &&
                                        this.state.open
                                    }
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <MenuList disablePadding>
                                        <MenuItemLink
                                            to="/permissions"
                                            primaryText="Permission List"
                                            leftIcon={<PermissionIcon />}
                                            onClick={onMenuClick}
                                            dense={dense}
                                        />
                                        <MenuItemLink
                                            to="/amazing"
                                            primaryText="Amazing Race"
                                            leftIcon={<EventIcon />}
                                            onClick={onMenuClick}
                                            dense={dense}
                                        />
                                        <MenuItemLink
                                            to="/countries"
                                            primaryText="Country List"
                                            leftIcon={<CountryIcon />}
                                            onClick={onMenuClick}
                                            dense={dense}
                                        />
                                        <MenuItemLink
                                            to="/hotels/cities"
                                            primaryText="City Point"
                                            leftIcon={<CountryIcon />}
                                            onClick={onMenuClick}
                                            dense={dense}
                                        />
                                        <MenuItemLink
                                            to="/bulk-distribute-points/create"
                                            primaryText="Bulk Distribute Points"
                                            leftIcon={<ReportIcon />}
                                            onClick={onMenuClick}
                                            dense={dense}
                                        />
                                        <MenuItemLink
                                            to="/powersuite/bulk-update/create"
                                            primaryText="Bulk Update User ID"
                                            leftIcon={<ReportIcon />}
                                            onClick={onMenuClick}
                                            dense={dense}
                                        />
                                        <MenuItemLink
                                                to="/bulk-upload-hotels/create"
                                                primaryText="Bulk Upload Hotels"
                                                leftIcon={<HotelIcon />}
                                                onClick={onMenuClick}
                                                dense={dense}
                                        />
                                    </MenuList>
                                </Collapse>
                            </MyMenuItemLink>
                        ))
                    }
                />

                {/* User */}
                <WithPermissions
                    render={({ permissions }) =>
                        Array.isArray(permissions) &&
                        (permissions &&
                        _.intersection(permissions, ["USER", "SUPERADMIN"]).length > 0 && (
                            <MyMenuItemLink
                                to="/"
                                primaryText="User"
                                leftIcon={<SuperadminIcon />}
                                onClick={this.handleClick("user")}
                                dense={dense}
                            >
                                <Collapse
                                    in={
                                        this.state.target === "user" &&
                                        this.state.open
                                    }
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <MenuList disablePadding>
                                        <MenuItemLink
                                            to="/admins"
                                            primaryText="Admin List"
                                            leftIcon={<SuperadminListIcon />}
                                            onClick={onMenuClick}
                                            dense={dense}
                                        />
                                    </MenuList>
                                </Collapse>
                            </MyMenuItemLink>
                        ))
                    }
                />

                <Responsive xsmall={logout} medium={null} />
            </MenuList>
        );
    }
}

Menu.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
    dense: PropTypes.bool,
    hasDashboard: PropTypes.bool,
    logout: PropTypes.element,
    onMenuClick: PropTypes.func,
    open: PropTypes.bool,
    pathname: PropTypes.string,
    resources: PropTypes.array.isRequired,
    translate: PropTypes.func.isRequired
};

Menu.defaultProps = {
    onMenuClick: () => null
};

const mapStateToProps = state => ({
    open: state.admin.ui.sidebarOpen,
    resources: getResources(state),
    pathname: state.router.location.pathname // used to force redraw on navigation
});

const enhance = compose(
    translate,
    connect(
        mapStateToProps,
        {}, // Avoid connect passing dispatch in props,
        null,
        {
            areStatePropsEqual: (prev, next) =>
                prev.resources.every(
                    (value, index) => value === next.resources[index] // shallow compare resources
                ) &&
                prev.pathname === next.pathname &&
                prev.open === next.open
        }
    ),
    withStyles(styles)
);

export default enhance(Menu);
