import React from 'react';
import { approvalStatus, approvalStage } from './config';
import CustomList from './CustomList';

export default props => {
    return (
        <CustomList
            {...props}
            filter={{
                ApprovalStatus: approvalStatus.PROCESSING,
                ApprovalStage: approvalStage.SUPERVISOR,
            }}
        />
    )
}