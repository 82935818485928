import React from "react";
import {
    Filter,
    DateInput,
    NullableBooleanInput,
    TextInput,
    ReferenceInput,
    AutocompleteInput
} from "react-admin";

export default props => {
    return (
        <Filter {...props}>
            <DateInput source="CheckOut" alwaysOn />
            <NullableBooleanInput source="HasUser" alwaysOn />
            <NullableBooleanInput source="HasPoints" alwaysOn />
            <NullableBooleanInput source="HasSupplier" alwaysOn />
            <TextInput source="UserID" label="User ID" />
            <TextInput source="CustomerCode" label="Agent Code" />
            <TextInput source="PNRNumber" label="PNR Number" />
            <TextInput source="VoucherNumber" label="Voucher Number" />
            <ReferenceInput label="Supplier" source="JarvisSupplierId" reference="suppliers">
                <AutocompleteInput optionText="Name" />
            </ReferenceInput>
            <TextInput source="HotelName" label="Hotel Name" />
        </Filter>
    );
};
