import React from "react";
import { Filter, TextInput } from "react-admin";

export default props => {
  return (
    <Filter {...props}>
      <TextInput source="q" label="Search" alwaysOn />
      <TextInput source="city" />
      <TextInput source="country" />
    </Filter>
  );
};
