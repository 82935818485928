import React from 'react';
import { DateField, Show, SimpleShowLayout, TextField } from 'react-admin';

export default props => (
    <Show
        {...props}
    >
        <SimpleShowLayout>
            <TextField source="MemberID" label="Member ID" />
            <TextField source="UserID" label="User ID" />
            <TextField source="AgentCode" label="Check-In" />
            <TextField source="NewAgentCode" label="Check-Out" />
            <TextField source="NewUserID" />
            <TextField source="Status" />
            <DateField source="UTCCreated" label="Created Date" showTime />
            <TextField source="CreatedBy" label="Created By" />
            <DateField source="UTCModified" label="Modified Date" showTime />
            <TextField source="ModifiedBy" label="Modified By" />
        </SimpleShowLayout>
    </Show>
)