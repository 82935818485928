import React from "react";
import { required, SimpleForm, TextInput, NumberInput } from "react-admin";
import RichTextInput from "ra-input-rich-text";

export default ({ mode, ...props }) => (
    <SimpleForm
        {...props}
        redirect="list"
    >
        <TextInput source="TierName" label="Tier" validate={[required()]} />
        <TextInput source="TierNameEn" label="Tier En" />
        <RichTextInput source="TierDesc" label="Benefit" />
        <RichTextInput source="TierDescEn" label="Benefit En" />
        {
            mode !== "EDIT" &&
            <>
                <NumberInput source="TierValue" label="Point Value" validate={[required()]} /><br />
                <NumberInput source="DividerValue" label="Divider Value" validate={[required()]} /><br />
                <NumberInput source="MinSalesValue" label="Minimal Sales Value" validate={[required()]} />
            </>
        }
        
    </SimpleForm>
);
