import React from "react";
import { Edit, SimpleForm, TextInput, Toolbar, SaveButton } from "react-admin";

const PostEditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const PermissionEdit = props => (
  <Edit title={"Edit Country"} {...props}>
    <SimpleForm toolbar={<PostEditToolbar />}>
      <TextInput label="Permission" source="id" />
      <TextInput label="Description" source="Description" />
    </SimpleForm>
  </Edit>
);
