import React from 'react';
import { Filter, BooleanInput, SelectArrayInput, SelectInput, TextInput } from 'react-admin';
import { categoryOptions, applicationCategoryOptions } from './config';

export default props => (
  <Filter {...props}>
    <TextInput source="q" label="Search" alwaysOn />
    <SelectInput
      source="Category"
      choices={categoryOptions}
      alwaysOn
    />
    <SelectArrayInput
      label="Application Category"
      source="ApplicationCategory"
      choices={applicationCategoryOptions}
    />
    <BooleanInput source="Active" />
    <BooleanInput source="Publish" label="Published" />
  </Filter>
);