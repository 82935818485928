import React from "react";
import { Edit, ImageField, ImageInput, minValue, maxValue, number, NumberInput, required, SimpleForm, TextInput } from "react-admin";

export default props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="id" label="ID" />
      <TextInput source="PSHotelID" label="Powersuite Hotel ID" />
      <TextInput source="Name" validate={[required()]} />
      <NumberInput source="Rating" validate={[number(), minValue(1), maxValue(5)]}/>
      <TextInput source="Address" />
      <TextInput source="City" validate={[required()]} />
      <TextInput source="Country" validate={[required()]} />
      <ImageInput source="Image" accept="image/*">
        <ImageField source="src" />
      </ImageInput>
    </SimpleForm>
  </Edit>
);
