import { CREATE, UPDATE } from "react-admin";

const enhancedDataProvider = requestHandler => async (
  type,
  resource,
  params
) => {
  try {
    //console.log(type, resource, params);
    const types = [CREATE, UPDATE];
    const resources = [ "hotels","rewards", "pages", "events", "promotions", "testimonials"];
    let data = {
      ...params.data,
      hasFile: false,
      files: []
    };
    if (types.includes(type) && resources.includes(resource)) {
      const fileToDataURI = require("./fileToDataURI");
      for (const key in params.data) {
        if (params.data.hasOwnProperty(key)) {
          const element = params.data[key];
          if (!element) {
            continue;
          }
          if (element.rawFile && element.rawFile instanceof File) {
            data[key] = await fileToDataURI(element);
            data.hasFile = true;
            data.files = data.files.concat(key);
          }
        }
      }
      if (data.hasFile) {
        //console.log(type, resource, {...params, data});
        return requestHandler(type, resource, {
          ...params,
          data
        });
      }
    }
    if (type === CREATE && ["agents", "bulk-upload-hotels","opsigo","jarvis-code","bulk-distribute-points","powersuite/bulk-update"].includes(resource)) {
      const excelToJson = require('./excelToJson');
      const data = await excelToJson(params.data.upload.rawFile);
      console.log(type, resource, params, "TYPE, RSULT  PARAMS")
      return requestHandler(type, resource, {
        ...params,
        data
      });
    }
    //console.log(type, resource, params)
    return requestHandler(type, resource, params);
  } catch (error) {
    throw new Error(error);
  }
};

export default enhancedDataProvider;
