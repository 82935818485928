// in src/pages/memberApproval/MemberApproval.js
import React, { Fragment } from "react";
import { List, Datagrid, EmailField, TextField, ChipField } from "react-admin";
import MemberApprovalFilter from "./Filter";
import MemberApprovalReject from "./MemberApprovalReject";
import MemberApprovalAccept from "./MemberApprovalAccept";

const PostBulkActionButtons = props => (
  <Fragment>
    <MemberApprovalReject {...props} />
    <MemberApprovalAccept {...props} />
  </Fragment>
);

export const MemberApproval = props => (
  <List
    title="All Member Approval List"
    {...props}
    filters={<MemberApprovalFilter />}
    bulkActionButtons={<PostBulkActionButtons />}
    sort={{ field: "id", order: "ASC" }}
    // exporter={false}
  >
    <Datagrid>
      <TextField source="UserId" label="User ID" />
      <TextField source="AgentCode" label="Agent Code" />
      <TextField source="agent.Name" label="Agent Name" sortable={false} />
      <TextField source="agent.Country" label="Agent Country" sortable={false} />
      <EmailField source="Email" label="User Email" />
      <TextField source="UTCCreated" label="Registration Date" />
      <TextField source="Phone" label="Phone Number" />
      <ChipField source="Status" label="Status" />
    </Datagrid>
  </List>
);
