import React from "react";
import { Edit } from "react-admin";
import Form from './Form';

export default props => (
  <Edit
    {...props}
  >
    <Form mode="EDIT" />
  </Edit>
);
