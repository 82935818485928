import React from "react";
import {
    Filter,
    TextInput,
    NullableBooleanInput,
    DateInput,
    SelectInput,
    ReferenceInput
} from "react-admin";
const inflection = require("inflection")

const tier = {
    GOLD: "gold",
    SILVER: "silver"
}

const tierOptions = Object.keys(tier).map(key => {
    return {
        id: tier[key],
        name: inflection.titleize(tier[key]),
    }
});

export default props => (
    <Filter {...props}>
        {/* <TextInput label="Search" source="q" alwaysOn /> */}
        <SelectInput
            source="TierName"
            choices={tierOptions}
            alwaysOn
        />
        <TextInput label="Agent Code" source="AgentCode" />
        <TextInput label="User Id" source="UserId" />
        <ReferenceInput
            source="Country"
            reference="countries"
            sort={{ field: "Name", order: "ASC" }}
        >
            <SelectInput optionText="Name" />
        </ReferenceInput>
        {/* <NullableBooleanInput
            label="Genius Member"
            source="GeniusMember"
            labelValueTrue="Genius Member"
            labelValueFalse="Other"
        /> */}
        <DateInput label="Date From" source="signupFrom" />
        <DateInput label="Date To" source="signupTo" />
        <ReferenceInput source="Status" reference="members/status">
            <SelectInput optionText="Name" />
        </ReferenceInput>
        <NullableBooleanInput source="IsBlacklisted" label="Blacklist" />
        <TextInput label="Email" source="Email" />
    </Filter>
);
