const inflection = require("inflection");

// export const approvalStatus = {
//     APPROVED: "APPROVED",
//     CANCELLED: "CANCELLED",
//     PENDING: "PENDING",
//     REJECTED: "REJECTED",
//     PROCESSING: "PROCESSING",
// };

export const approvalStatus = {
    APPROVED: "APPROVED",
    REJECTED: "REJECTED"
};

export const approvalStatusOptions = Object.keys(approvalStatus).map(key => {
    return {
        id: approvalStatus[key],
        name: inflection.titleize(approvalStatus[key]),
    }
});

// export const approvalStage = {
//     ADMIN: "ADMIN",
//     FINANCE: "FINANCE",
//     SUPERVISOR: "SUPERVISOR",
// };

export const approvalStage = {
    FINANCE: "FINANCE"
};

export const approvalStageOptions = Object.keys(approvalStage).map(key => {
    return {
        id: approvalStage[key],
        name: inflection.titleize(approvalStage[key]),
    }
});

// export const category = {
//     CASH: "CASH",
//     GARUDA: "GARUDA",
//     HOLIDAY: "HOLIDAY",
//     HOTEL: "HOTEL",
//     PRODUCT: "PRODUCT",
//     VOUCHER: "VOUCHER",
//     TOKOPEDIA: "TOKOPEDIA",
//     OVO: "OVO",
//     MAP: "MAP",
//     MAP_FOR_CWT: "MAP_FOR_CWT"
// }

export const category = {
    EWallet: "E-wallet",
    Voucher: "voucher"
}

export const categoryOptions = Object.keys(category).map(key => {
    return {
        id: category[key],
        name: inflection.titleize(category[key]),
    }
});

export const tier = {
    GOLD: "gold",
    SILVER: "silver"
}

export const tierOptions = Object.keys(tier).map(key => {
    return {
        id: tier[key],
        name: inflection.titleize(tier[key]),
    }
});