import React from "react";
import RichTextInput from "ra-input-rich-text";
import { required, SimpleForm } from "react-admin";

const toolbar = [
    [
        "bold",
        "italic",
        "underline",
        "strike",
        { list: "ordered" },
        { list: "bullet" },
        { 'indent': '-1'},
        { 'indent': '+1' }
    ]
];

export default ({ mode, ...props }) => (
    <SimpleForm
        {...props}
        redirect="list"
    >
        <RichTextInput
            source="ContentEn"
            label="Content"
            toolbar={toolbar}
            validate={[required()]}
        />
        <RichTextInput
            source="Content"
            label="Content (Local)"
            toolbar={toolbar}
            validate={[required()]}
        />
    </SimpleForm>
);
