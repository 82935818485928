import React from "react";
import { Filter, DateInput, TextInput } from "react-admin";

export default props => {
    return (
        <Filter {...props}>
            {/* <TextInput label="Year" source="year" /> */}
            <DateInput label="CheckOutFrom" source="CheckOutFrom" />
            <DateInput label="CheckOutTo" source="CheckOutTo" />
            <TextInput source="hotel" />
            <TextInput source="Country" label="Hotel Country" />
            <TextInput source="AgentCode" label="Agent Code" />
            <TextInput source="AgentCountry" label="Agent Country" />
        </Filter>
    );
};
