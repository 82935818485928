import React, { Fragment } from "react";
import {
    Button,
    crudUpdateMany,
    Datagrid,
    DateField,
    Filter,
    List,
    NumberField,
    Pagination,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    TextField,
    TextInput,
    UPDATE,
    withDataProvider,
    setListSelectedIds,
    showNotification,
    refreshView,
    fetchStart,
    fetchEnd
} from "react-admin";
import { categoryOptions, approvalStage, approvalStatus } from "./config";
import { connect } from "react-redux";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import BlockIcon from "@material-ui/icons/Block";
import {exporter} from './Exporter'

const CustomFilter = props => (
    <Filter {...props}>
        <SelectInput source="Category" choices={categoryOptions} alwaysOn />
        <TextInput label="Agent Code" source="AgentCode" />
        <TextInput label="User Id" source="UserId" />
        <ReferenceInput
            source="Country"
            reference="countries"
            sort={{ field: "Name", order: "ASC" }}
            alwaysOn
        >
            <SelectInput optionText="Name" />
        </ReferenceInput>
    </Filter>
);

const approvalData = stage => {
    switch (stage) {
        case approvalStage.ADMIN:
            return {
                ApprovalStatus: approvalStatus.PROCESSING,
                ApprovalStage: approvalStage.SUPERVISOR
            };
        case approvalStage.SUPERVISOR:
            return {
                ApprovalStatus: approvalStatus.PROCESSING,
                ApprovalStage: approvalStage.FINANCE
            };
        case approvalStage.FINANCE:
            return {
                ApprovalStatus: approvalStatus.APPROVED
            };
        default:
            break;
    }
};

const ApproveButton = withDataProvider(
    ({ dataProvider, record, basePath, ...props }) => (
        <Button
            label="Approve"
            onClick={() =>
                dataProvider(
                    UPDATE,
                    "redemptions",
                    {
                        id: record.id,
                        data: approvalData(record.ApprovalStage)
                    },
                    {
                        onSuccess: {
                            notification: {
                                body: `Approved`,
                                level: `info`
                            },
                            basePath,
                            refresh: true,
                            unselectAll: true
                        },
                        onError: {
                            notification: {
                                body: `Error: Approve failed`,
                                level: `warning`
                            },
                            refresh: true
                        },
                        onFailure: {
                            notification: {
                                body: `Error: Approve failed`,
                                level: `warning`
                            },
                            refresh: true
                        }
                    }
                )
            }
        >
            <CheckCircleIcon />
        </Button>
    )
);

const RejectButton = withDataProvider(
    ({ dataProvider, record, basePath, ...props }) => (
        <Button
            label="Reject"
            onClick={() =>
                dataProvider(
                    UPDATE,
                    "redemptions",
                    {
                        id: record.id,
                        data: {
                            ApprovalStatus: approvalStatus.REJECTED
                        }
                    },
                    {
                        onSuccess: {
                            notification: {
                                body: `Rejected`,
                                level: `info`
                            },
                            basePath,
                            refresh: true,
                            unselectAll: true
                        },
                        onError: {
                            notification: {
                                body: `Error: Reject failed`,
                                level: `warning`
                            }
                        },
                        onFailure: {
                            notification: {
                                body: `Error: Reject failed`,
                                level: `warning`
                            }
                        }
                    }
                )
            }
        >
            <BlockIcon />
        </Button>
    )
);

const BulkApproveButton = connect(
    undefined,
    { crudUpdateMany }
)(({ basePath, crudUpdateMany, resource, selectedIds }) => {
    switch (basePath) {
        case "/redemptions/check":
            return (
                <Button
                    label="Approve"
                    onClick={() =>
                        crudUpdateMany(
                            resource,
                            selectedIds,
                            {
                                ApprovalStatus: approvalStatus.PROCESSING,
                                ApprovalStage: approvalStage.SUPERVISOR
                            },
                            basePath
                        )
                    }
                >
                    <CheckCircleIcon />
                </Button>
            );
        case "/redemptions/approval":
            return (
                <Button
                    label="Approve"
                    onClick={() =>
                        crudUpdateMany(
                            resource,
                            selectedIds,
                            {
                                ApprovalStatus: approvalStatus.PROCESSING,
                                ApprovalStage: approvalStage.FINANCE
                            },
                            basePath
                        )
                    }
                >
                    <CheckCircleIcon />
                </Button>
            );
        case "/redemptions/finance-approval":
            return (
                <Button
                    label="Approve"
                    onClick={() =>
                        crudUpdateMany(
                            resource,
                            selectedIds,
                            { ApprovalStatus: approvalStatus.APPROVED },
                            basePath
                        )
                    }
                >
                    <CheckCircleIcon />
                </Button>
            );
        default:
            return <div />;
    }
});

const BulkRejectButton = connect(
    null,
    {
        fetchEnd,
        fetchStart,
        refreshView,
        setListSelectedIds,
        showNotification
    }
)(props => {
    return (
        <Button
            label="Reject"
            onClick={() => {
                console.log(props.resource);
                const {
                    fetchEnd,
                    fetchStart,
                    refreshView,
                    resource,
                    selectedIds,
                    setListSelectedIds,
                    showNotification
                } = props;
                fetchStart();
                fetch(`${process.env.REACT_APP_API_URL}/redemptions/reject`, {
                    method: "POST",
                    headers: {
                        accept: "application/json",
                        "content-type": "application/json; charset=utf-8",
                        authorization: `Bearer ${localStorage.token}`,
                        "x-api-key": process.env.REACT_APP_API_KEY
                    },
                    body: JSON.stringify({
                        id: selectedIds
                    })
                })
                    .then(response => response.json())
                    .then(object => {
                        showNotification(
                            object.message,
                            object.error ? "warning" : "info"
                        );
                        setListSelectedIds(resource, []);
                        refreshView();
                        fetchEnd();
                    })
                    .catch(error => {
                        console.error(error);
                        showNotification("Error: Fetch failed", "warning");
                        refreshView();
                        fetchEnd();
                    });
            }}
        >
            <BlockIcon />
        </Button>
    );
});

const BulkActionButtons = props => (
    <Fragment>
        <BulkApproveButton {...props} />
        <BulkRejectButton {...props} />
    </Fragment>
);

export default props => (
    <List
        {...props}
        bulkActionButtons={<BulkActionButtons />}
        exporter={exporter}
        filters={<CustomFilter />}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
        perPage={25}
        sort={{
            field: "UTCCreated",
            order: "ASC"
        }}
    >
        <Datagrid>
            <DateField source="UTCCreated" label="Date" showTime />
            <TextField source="AgentCode" label="Agent Code" sortable={false} />
            <TextField
                source="agent.Name"
                label="Agent Name"
                sortable={false}
            />
            <TextField
                source="agent.Country"
                label="Agent Country"
                sortable={false}
            />
            <TextField source="UserId" label="User ID" sortable={false} />
            <ReferenceField
                source="Country"
                reference="countries"
                linkType={false}
                sortable={false}
                label="Reward Country"
            >
                <TextField source="Name" />
            </ReferenceField>
            <TextField source="Name" sortable={false} />
            {/* <NumberField source="Value" sortable={false} /> */}
            {/* <NumberField source="BonusValue" label="Bonus Value" sortable={false} /> */}
            <NumberField
                source="TotalValue"
                label="Total Value"
                sortable={false}
            />
            {/* <NumberField source="Points" sortable={false} /> */}
            {/* <NumberField source="PointsDiscount" label="Points Discount" sortable={false} /> */}
            <NumberField
                source="TotalPoints"
                label="Total Points"
                sortable={false}
            />
            <TextField source="CheckedBy" label="Checked By" sortable={false} />
            <ApproveButton />
            <RejectButton />
        </Datagrid>
    </List>
);
