import React from 'react';
import { CardActions, CreateButton, ExportButton, /*RefreshButton*/ } from 'react-admin';

export default ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total,
    permissions,
}) => (
    <CardActions>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }
        
        {
            <CreateButton
                basePath={basePath}
                label="Upload"
            />
        }
        { <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
            maxResults={50000}
        />}
        {/*<RefreshButton />*/ }
    </CardActions>
)