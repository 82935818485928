import React from "react";
import {
    Edit,
    SimpleForm,
    DisabledInput,
    SelectInput,
    TextInput,
    Toolbar,
    SaveButton,
    LongTextInput,
    BooleanInput,
    ReferenceInput,
    required
} from "react-admin";

const PostEditToolbar = props => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);

export default props => (
    <Edit title={"Edit Agent"} {...props}>
        <SimpleForm toolbar={<PostEditToolbar />}>
            <DisabledInput label="Id" source="Agent Code" />
            <DisabledInput label="LegacyAgentCode" source="Legacy Agent Code" />
            <BooleanInput source="IsXML" label="XML Agent" />
            <BooleanInput source="IsPanorama" label="Panorama Agent" />
            <BooleanInput source="IsBlacklisted" label="Blacklist Redemption" />
            <BooleanInput source="IsAffiliate" label="Affiliate" />
            <TextInput source="Name" />
            <TextInput source="Email" />
            <SelectInput
                source="Status"
                choices={[
                    { id: 0, name: "Non active" },
                    { id: 1, name: "Active" }
                ]}
            />
            <LongTextInput source="Address" />
            <TextInput source="Phone" />
            <TextInput source="City" />
            <ReferenceInput
                source="Country"
                reference="countries"
                sort={{
                    field: "Name",
                    order: "ASC"
                }}
                label="Reward Country"
                validate={[required()]}
            >
                <SelectInput optionText={({ id, Name }) => `${id} - ${Name}`} />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);
