import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import axios from "axios";

export class ClaimGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [],
      options: {
        chart: {
          width: 600,
          type: "pie"
        },
        colors: ['#002db3', '#239023', '#6b6b47', '#b30000', '#b36b00', '#b300b3', '#b3b300'], // 35% light        
        labels: []
       
      }
    };
  }
  componentDidMount() {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
    const API_URL = process.env.REACT_APP_API_URL;
    axios
      .get(`${API_URL}/redemptions/redemp`)
      .then(res => {
        
        let top6 = res.data.slice(0, 7);
        let restVal = res.data.slice(7, res.data.length);

        let filtered = top6.filter(function(name) {
          return ((name.name === "Voucher Tunai" || name.name === "Voucher Tunai ") && (name.total_value === 500000)) 
          
        });
        let combineFiltered = filtered.map(data => data.name && data.total_value && data['count(*)']).reduce(function(num, total){
          return num + total
        },0)
        
        for( let i = 0; i< top6.length; i++){
          if (top6[i].name === "Voucher Tunai " && top6[i].total_value === 500000){
            top6.splice(i, 1)
          } else if(top6[i].name === "Voucher Tunai" && top6[i].total_value === 500000) {
            top6[i]['count(*)'] = combineFiltered
          } else {            
          }
        }

        let restCount = [];
        restVal.map(data => restCount.push(data["count(*)"]));
        let totalOtherCount = restCount.reduce(function(total, number) {
          return total + number;
        }, 0);

        let namencount = [];
        let count2 = [];
        
        top6.map(data => namencount.push(`${data.name}- ${data.total_value}`));
        top6.map(data => count2.push(data["count(*)"]));
        namencount.push("Others");
        count2.push(totalOtherCount);    

        this.setState({
          options: {
            labels: namencount
          },
          series: count2
        });
      })
      .catch(error => console.log(error));
  }

  render() {
    
    const styles = {
      center: {
        textAlign: "center",
        fontFamily: "Roboto",
        fontWeight: 'normal'
      },
      max: {
        minwidth: "100%",
        height: 600
      }
    };
    return (
      <div id="chart" style={styles.max}>
        <h3 style={styles.center}>Top Claimed</h3>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="pie"
          height={"auto"}
          width={600}
        />
      </div>
    );
  }
}

export default ClaimGraph;