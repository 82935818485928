import { downloadCSV } from "react-admin";
import { unparse as convertToCSV } from "papaparse/papaparse.min";

const moment = require('moment');

export const exporter = redemptions => {
    const redemptionsForExport = redemptions.map(redemption => {
        let redemptionForExport = redemption;

        redemptionForExport.AgentName =
            redemption.agent === null ? null : redemption.agent.Name;
        redemptionForExport.ClaimBankAccount =
            "'" + redemption.ClaimBankAccount;
        redemptionForExport.ClaimOvoPhone =
            "'" + redemption.ClaimOvoPhone;
        if (redemptionForExport.Category === 'OVO') {
            redemptionForExport.ClaimOvoDescription =
                `TOP UP OVO ${redemptionForExport.TotalValue} Approved Claimed Reward ${redemptionForExport.UTCChecked ? moment.utc(redemptionForExport.UTCChecked).format('D MMMM YYYY') : ''}`;
        }
        redemptionForExport.RewardID = '"' + redemption.RewardID + '"'
        redemptionForExport.NIK = '"' + redemption.NIK + '"'
        redemptionForExport.NPWP = '"' + redemption.NPWP + '"'
        return redemptionForExport;
    });

    const csv = convertToCSV({
        data: redemptionsForExport,
        fields: [
            "id",
            "MemberID",
            "NIK",
            "NPWP",
            "MemberName",
            "AgentCode",
            "AgentName",
            "UserId",
            "TierName",
            "TierValue",
            "ClaimBankAccount",
            "ClaimBankAddress",
            "ClaimBankHolder",
            "ClaimBankName",
            "ClaimBusinessReg",
            "ClaimGarudaMilesID",
            "ClaimPassport",
            "ClaimSwift",
            "RewardID",
            "Country",
            "Category",
            "Name",
            "NameEn",
            "Description",
            "DescriptionEn",
            "Value",
            "BonusValue",
            "TotalValue",
            "Points",
            "PointsDiscount",
            "TotalPoints",
            "ApprovalStage",
            "ApprovalStatus",
            "UTCChecked",
            "CheckedBy",
            "UTCPreApproved",
            "PreApprovedBy",
            "UTCApproved",
            "ApprovedBy",
            "UTCRejected",
            "RejectedBy",
            "UTCCreated",
            "CreatedBy",
            "UTCModified",
            "ModifiedBy",
            "ClaimTokopediaEmail",
            "ClaimOvoPhone",
            "ClaimOvoDescription",
            "Cost",
            "GiftCardCode",
            "GiftCardExpiredDate",
            "GiftCardAddBy",
            "ClaimMapId",
            "ClaimMapPhone",
            "ClaimMapName",
            "VendorRewardId",
            "IsVendorRejection",
            "VendorRejectionDate",
            "VendorRejectionDescription"
        ]
    });
    downloadCSV(csv, "redemptions");
};
