import React from "react";
import {
    Datagrid,
    List,
    Pagination,
    EditButton,
    RichTextField
} from "react-admin";

export default ({ permissions, ...props }) => (
    <List
        {...props}
        exporter={true}
        bulkActionButtons={false}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
        perPage={25}
    >
        <Datagrid>
            <RichTextField
                source="ContentEn"
                label="Content"
                sortable={false}
            />
            <RichTextField
                source="Content"
                label="Content (Local)"
                sortable={false}
            />
            <EditButton />
        </Datagrid>
    </List>
);
