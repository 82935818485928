import React from "react";
import { BooleanField, Datagrid, DateField, ImageField, List, Pagination, TextField } from "react-admin";

export default props => (
  <List
    {...props}
    // exporter={false}
    pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
    perPage={25}
  >
    <Datagrid rowClick="show">
      <TextField source="Agent" label="Agent Name" sortable={false} />
      <TextField source="Name" sortable={false} />
      <TextField source="City" sortable={false} />
      <TextField source="Testimony" label="Locale" sortable={false} />
      <TextField source="TestimonyEn" label="Global" sortable={false} />
      <BooleanField source="Approved" sortable={false} />
      <DateField source="UTCCreated" label="Date Created" showTime sortable={false} />
      <TextField source="CreatedBy" label="Created By" sortable={false} />
      <DateField source="UTCModified" label="Date Modified" showTime sortable={false} />
      <TextField source="ModifiedBy" label="Modified By" sortable={false} />
      <ImageField source="Image" sortable={false} />
    </Datagrid>
  </List>
);
