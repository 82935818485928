import React, { Component } from 'react';
import { Button } from 'react-admin';
import XLSX from 'xlsx';
import { connect } from 'react-redux';
import dataJson from './data'

class ExcelTemplateButton extends Component {

    handleClick = () => {
        const { dataTemplate } = this.props;
        let value, fileName;

        switch (dataTemplate) {
            case "JARVIS_CODE":
                fileName = "UploadJarvisCodeTemplate.xlsx"
                value = dataJson.JARVIS_CODE
                break;
            case "BULK_DISTRIBUTE_POINTS":
                fileName = "BulkDistributePointsTemplate.xlsx"
                value = dataJson.BULK_DISTRIBUTE_POINTS
                break;
            case "BULK_UPDATE_USERID":
                fileName = "BulkUpdateUserIdTemplate.xlsx"
                value = dataJson.BULK_UPDATE_USERID
                break;
            case "UPLOAD_OPSIGO":
                fileName = "UploadOpsigoTemplate.xlsx"
                value = dataJson.UPLOAD_OPSIGO
                break;
            case "UPLOAD_AGENT":
                fileName = "UploadAgentTemplate.xlsx"
                value = dataJson.UPLOAD_AGENT
                break;
            case "UPLOAD_HOTEL":
                fileName = "UploadHotelTemplate.xlsx"
                value = dataJson.UPLOAD_HOTEL
                break;
            default:
              break;
        }
        
        /* make the worksheet */
        let ws = XLSX.utils.json_to_sheet(value);

        /* add to workbook */
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws);

        /* generate an XLSX file */
        XLSX.writeFile(wb, fileName);
    };

    render() {
        return (
            <Button 
                label="Download Template"
                onClick={this.handleClick}
            />
        )
    }
}

export default connect(null)(ExcelTemplateButton);