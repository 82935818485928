import React from "react";
import {
    Datagrid,
    DateField,
    List,
    Pagination,
    TextField
} from "react-admin";
import CustomFilter from "./Filter";

export default props => (
    <List
        {...props}
        // exporter={false}
        bulkActionButtons={false}
        filters={<CustomFilter />}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
        perPage={25}
        sort={{
            field: "UTCCreated",
            order: "DESC"
        }}
    >
        <Datagrid rowClick="show">
            {/* <TextField
                source="MemberID"
                label="Member ID"
                sortable={false}
            /> */}
            <TextField
                source="AgentCode"
                label="Old Agent Code"
                sortable={false}
            />
            <TextField
                source="UserID"
                label="Old User ID"
                sortable={false}
            />
            <TextField
                source="NewAgentCode"
                label="New Agent Code"
                sortable={false}
            />
            <TextField
                source="NewUserID"
                label="New User ID"
                sortable={false}
            />
            <TextField
                source="Status"
                sortable={false}
            />
            <DateField
                source="UTCCreated"
                label="Created Date"
                showTime
                // sortable={false}
            />
            {/* <TextField
                source="CreatedBy"
                label="Created By"
                sortable={false}
            />
            <DateField
                source="UTCModified"
                label="Modified Date"
                showTime
                sortable={false}
            />
            <TextField
                source="ModifiedBy"
                label="Modified By"
                sortable={false}
            /> */}
        </Datagrid>
    </List>
);
