import React from "react";
import {
    Datagrid,
    List,
    Pagination,
    TextField,
    EditButton
} from "react-admin";
import ActivateButton from "./ActivateButton";
import DeactivateButton from "./DeactivateButton";
import _ from "lodash";

export default ({ permissions, ...props }) => (
    <List
        {...props}
        exporter={true}
        bulkActionButtons={false}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
        perPage={25}
    >
        <Datagrid>
            <TextField
                source="Category"
                label="Category"
                sortable={false}
            />
            <TextField
                source="Question"
                label="Question"
                sortable={false}
            />
            <TextField
                source="QuestionEn"
                label="Question En"
                sortable={false}
            />
            <TextField
                source="Answer"
                label="Answer"
                sortable={false}
            />
            <TextField
                source="AnswerEn"
                label="AnserEn"
                sortable={false}
            />
            {permissions &&
                _.intersection(permissions, ["ALL", "SUPERADMIN", "MARKETING"])
                    .length > 0 && <ActivateButton />}
            {permissions &&
                _.intersection(permissions, ["ALL", "SUPERADMIN", "MARKETING"])
                    .length > 0 && <DeactivateButton />}
            <EditButton />
        </Datagrid>
    </List>
);
