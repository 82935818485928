import React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  NumberField,
  DateField
} from "react-admin";

export default props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="member.Name" />
      <TextField source="member.Email" />
      <DateField source="Date" />
      <TextField source="Description" />
      <TextField source="Amount" />
      <NumberField source="PreviousBalance" />
      <NumberField source="Amount" />
      <NumberField source="Balance" />
      <TextField source="Source" />
      <DateField source="UTCCreated" label="Date Created" />
      <TextField source="CreatedBy" label="Created By" />
    </SimpleShowLayout>
  </Show>
);
