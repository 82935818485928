import React from 'react';
import { 
    approvalStatus,
    approvalStage,
    category
} from './config';
import {
    Datagrid,
    DateField,
    Filter,
    List,
    NumberField,
    Pagination,
    ReferenceInput,
    SelectInput,
    TextField,
    TextInput,
    ReferenceField
} from "react-admin";
import AddGCCButton from "./AddGCCButton";

const Filters = props => (
    <Filter {...props}>
        <TextInput label="Agent Code" source="AgentCode" />
        <TextInput label="User Id" source="UserId" />
        <ReferenceInput
            source="Country"
            reference="countries"
            sort={{ field: "Name", order: "ASC" }}
        >
            <SelectInput optionText="Name" />
        </ReferenceInput>
        <SelectInput label="Gift Card Given" source="GiftCardCode" choices={[
            { id: 'Yes', name: 'Yes'},
            { id: 'No', name: 'No' },
        ]} />
    </Filter>
);

export default ({ permissions, ...props }) => (
    <List
        {...props}
        bulkActionButtons={false}
        filterDefaultValues={{
            ApprovalStatus: approvalStatus.APPROVED,
            ApprovalStage: approvalStage.FINANCE,
            Category: category.TOKOPEDIA,
            GiftCardCode: "No"
        }}
        filters={<Filters />}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
        perPage={25}
        sort={{
            field: "UTCCreated",
            order: "ASC"
        }}
    >
        <Datagrid>
            <DateField source="UTCCreated" label="Date" showTime />
            <TextField source="AgentCode" label="Agent Code" sortable={false} />
            <TextField
                source="agent.Name"
                label="Agent Name"
                sortable={false}
            />
            <TextField source="UserId" label="User ID" sortable={false} />
            <ReferenceField
                source="Country"
                reference="countries"
                linkType={false}
                sortable={false}
                label="Reward Country"
            >
                <TextField source="Name" />
            </ReferenceField>
            <TextField source="Name" sortable={false} />
            {/* <NumberField source="Value" sortable={false} /> */}
            {/* <NumberField source="BonusValue" label="Bonus" sortable={false} /> */}
            <NumberField
                source="TotalValue"
                label="Total Value"
                sortable={false}
            />
            {/* <NumberField source="Points" sortable={false} /> */}
            {/* <NumberField source="PointsDiscount" sortable={false} /> */}
            <NumberField
                source="TotalPoints"
                label="Total Points"
                sortable={false}
            />
            <TextField source="CheckedBy" label="Checked By" sortable={false} />
            <TextField source="GiftCardCode" label="Gift Card Tokopedia" sortable={false} />
            <AddGCCButton />
        </Datagrid>
    </List>
);