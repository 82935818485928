import React from 'react';
import { AppBar, UserMenu, MenuItemLink, translate } from 'react-admin';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
};

const CustomUserMenu = translate(({ translate, ...props }) => (
    <UserMenu label="My Account" {...props}>
        <MenuItemLink
            to={`/my-account`}
            primaryText="Account"
            leftIcon={<AccountCircleIcon />}
        />
    </UserMenu>
));

const CustomAppBar = ({ classes, ...props }) => (
    <AppBar {...props} userMenu={<CustomUserMenu />}>
        <span className={classes.spacer} />
        {
            process.env.REACT_APP_ENV && <h1>{process.env.REACT_APP_ENV}</h1>
        }
    </AppBar>
);

export default withStyles(styles)(CustomAppBar);