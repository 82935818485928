import { downloadCSV } from "react-admin";
import { unparse as convertToCSV } from "papaparse/papaparse.min";

export const exporter = redemptionRequest => {
    const csv = convertToCSV({
        data: redemptionRequest,
        fields: [
            "id",
            "Request",
            "OrderId",
            "OrderNo",
            "MemberId",
            "ReceiverName",
            "ReceiverPhone",
            "Status",
            "Message",
            "Response",
            "UTCCreated",
            "UTCModified"
        ]
    })
    downloadCSV(csv, "RedemptionRequest");
}
