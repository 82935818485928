// in src/pages/permission/Permission.js
import React from "react";
import { List, Datagrid, TextField, EditButton } from "react-admin";

export const Permission = props => (
  <List title="All Agent List" {...props} sort={{ field: "id", order: "ASC" }}>
    <Datagrid>
      <TextField source="id" label="Permission" style={{ fontSize: 12 }} />
      <TextField
        source="Description"
        label="Description"
        style={{ fontSize: 12 }}
      />
      <EditButton />
    </Datagrid>
  </List>
);
