import React from "react";
import { Datagrid, List, Pagination, TextField } from "react-admin";
import Filter from "./Filter";
import Actions from './Action'
// import Upload from './HotelUpload'

export default props => (
  <List
    {...props}
    filters={<Filter />}
    sort={{ field: "Name", order: "ASC" }}
    actions={<Actions permissions = {props.permissions} />}
    bulkActionButtons={false}
    pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
    perPage={25}
    // exporter={false}
  >
    <Datagrid rowClick="show">
      <TextField source="id" label="ID" sortable={false} />
      <TextField source="Name" sortable={false} />
      <TextField source="City" sortable={false} />
      <TextField source="Country" sortable={false} />
    </Datagrid>
  </List>
);
