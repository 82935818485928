import React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  NumberField,
  DateField
} from "react-admin";

export default props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="PsCode" label="Powersuite Code" />
      <TextField source="Name" />
      <TextField source="Address" />
      <TextField source="CityCode" />
      <TextField source="City" />
      <TextField source="Country" />
      <TextField source="Phone" />
      <NumberField source="Points" />
      <DateField source="UTCCreated" label="Date Created" />
      <TextField source="CreatedBy" label="Created By" />
      <DateField source="UTCModified" label="Date Modified" />
      <TextField source="ModifiedBy" label="Modified By" />
    </SimpleShowLayout>
  </Show>
);
