import React from "react";
import { Datagrid, List, NumberField, Pagination, TextField } from "react-admin";
import SupplierFilter from "./Filter";

export default props => (
  <List
    {...props}
    bulkActionButtons={false}
    // exporter={false}
    filters={<SupplierFilter />}
    pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
    perPage={25}
    sort={{
      field: "Points",
      order: "DESC",
    }}
  >
    <Datagrid rowClick="show">
      <TextField source="id" label="Code" sortable={false} />
      <TextField source="PsCode" label="Powersuite Code" sortable={false} />
      <TextField source="Name" sortable={false} />
      <TextField source="City" sortable={false} />
      <TextField source="Country" sortable={false} />
      <NumberField source="Points" sortable={false} />
    </Datagrid>
  </List>
);
