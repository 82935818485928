import React from "react";
import { ArrayField, BooleanField, Datagrid, DateField, DeleteButton, EditButton, FunctionField, NumberField, Show, Tab, TabbedShowLayout, TextField } from "react-admin";
import CardActions from '@material-ui/core/CardActions';

const Title = ({record: {Title}}) => (
    <span>{Title}</span>
)

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

const Actions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <EditButton basePath={basePath} record={data} />
        <DeleteButton basePath={basePath} record={data} resource={resource} />
    </CardActions>
);

export default props => (
    <Show {...props} title={<Title />} actions={<Actions />}
    >
        <TabbedShowLayout>
            <Tab label="Summary">
                {/* <TextField source="id" /> */}
                <TextField source="Title" />
                {/* <TextField source="Tagline" /> */}
                {/* <TextField source="Description" /> */}
                <DateField source="Start" />
                <DateField source="End" />
                <NumberField source="Points" />
                <NumberField source="BonusPoints" label="Bonus Points" />
                <BooleanField source="Active" />
                <DateField source="UTCCreated" label="Created Date" showTime />
                <TextField source="CreatedBy" label="Created By" />
                <DateField source="UTCModified" label="Modified Date" showTime />
                <TextField source="ModifiedBy" label="Modified By" />
            </Tab>
            <Tab label="Stages" path="stages">
                <ArrayField source="stages" label="Stages">
                    <Datagrid>
                        {/* <TextField source="id" sortable={false} /> */}
                        {/* <TextField source="AmazingID" sortable={false} /> */}
                        <NumberField source="Stage" sortable={false} />
                        {/* <TextField source="Title" sortable={false} /> */}
                        {/* <TextField source="Description" sortable={false} /> */}
                        <NumberField source="Requirement" sortable={false} />
                        <NumberField source="BonusRequirement" sortable={false} />
                        <NumberField source="TotalRequirement" sortable={false} />
                        <NumberField source="Points" sortable={false} />
                        <NumberField source="BonusPoints" sortable={false} />
                        {/* <DateField source="UTCCreated" label="Date created" sortable={false} showTime /> */}
                        {/* <TextField source="CreatedBy" label="Created by" sortable={false} /> */}
                        {/* <DateField source="UTCModified" label="Date modified" sortable={false} showTime /> */}
                        {/* <TextField source="ModifiedBy" label="Modified by" sortable={false} /> */}
                    </Datagrid>
                </ArrayField>
            </Tab>
            <Tab label="Requirements" path="requirements">
                <ArrayField source="requirements" label="Requirements">
                    <Datagrid>
                        {/* <TextField source="id" sortable={false} /> */}
                        {/* <TextField source="AmazingID" sortable={false} /> */}
                        <NumberField source="Stage" sortable={false} />
                        <TextField source="Category" sortable={false} />
                        <TextField source="Ref" sortable={false} />
                        {/* <TextField source="Include" sortable={false} /> */}
                        <FunctionField label="Include" render={
                            ({Include}) => (
                                <ul>
                                    {
                                        Include && Include.split(',').map((row, i) => (
                                            <li key={i}>{row}</li>
                                        ))
                                    }
                                </ul>
                            )
                        } />
                        <NumberField source="RoomNight" label="Room Night" sortable={false} />
                        <TextField source="Points" sortable={false} />
                        <BooleanField source="Required" sortable={false} />
                        {/* <DateField source="UTCCreated" label="Date created" sortable={false} showTime /> */}
                        {/* <TextField source="CreatedBy" label="Created by" sortable={false} /> */}
                        {/* <DateField source="UTCModified" label="Date modified" sortable={false} showTime /> */}
                        {/* <TextField source="ModifiedBy" label="Modified by" sortable={false} /> */}
                    </Datagrid>
                </ArrayField>
            </Tab>
        </TabbedShowLayout>
    </Show>
)