import React from 'react';
import { ArrayInput, BooleanInput, choices, DateInput, DisabledInput, FormTab, minValue, number, NumberInput, required, SelectInput, SimpleFormIterator, TabbedForm, TextInput } from 'react-admin';
import { requirementCategoryChoices } from './config';

export default ({mode, ...props}) => (
    <TabbedForm
        {...props}
        submitOnEnter={false}
        redirect="show"
    >
        <FormTab label="summary">
            {
                mode === "EDIT" &&
                <DisabledInput source="id" />
            }
            <TextInput fullWidth={true} source="Title" validate={[required()]} />
            {/* <TextInput fullWidth={true} source="Tagline" /> */}
            {/* <TextInput fullWidth={true} source="Description" /> */}
            <DateInput source="Start" validate={[required()]} />
            <DateInput source="End" validate={[required()]} />
            {/* <NumberInput source="Points" validate={[number(), minValue(0)]} /> */}
            {/* <NumberInput source="BonusPoints" validate={[number(), minValue(0)]} /> */}
            {/* <BooleanInput source="Active" validate={[choices([true, false])]} /> */}
        </FormTab>
        <FormTab label="stages" path="stages">
            <ArrayInput source="stages">
                <SimpleFormIterator>
                    {
                        mode === "EDIT" &&
                        <DisabledInput source="id" />
                    }
                    {
                        mode === "EDIT" &&
                        <DisabledInput source="AmazingID" />
                    }
                    <NumberInput source="Stage" validate={[required(), number(), minValue(1)]}/>
                    {/* <TextInput fullWidth={true} source="Title" /> */}
                    {/* <TextInput fullWidth={true} source="Description" /> */}
                    <NumberInput source="Points" validate={[required(), number()]}/>
                    <NumberInput source="BonusPoints" validate={[required(), number()]}/>
                </SimpleFormIterator>
            </ArrayInput>
        </FormTab>
        <FormTab label="requirements" path="requirements">
            <ArrayInput source="requirements">
                <SimpleFormIterator>
                    {
                        mode === "EDIT" &&
                        <DisabledInput source="id" />
                    }
                    {
                        mode === "EDIT" &&
                        <DisabledInput source="AmazingID" />
                    }
                    <NumberInput source="Stage" validate={[required(), number(), minValue(1)]} />
                    <SelectInput source="Category" choices={requirementCategoryChoices} validate={[required(), choices(requirementCategoryChoices.map(({id}) => id))]} />
                    <TextInput fullWidth={true} source="Ref" validate={[required()]} />
                    <TextInput fullWidth={true} source="Include" />
                    <NumberInput source="RoomNight" validate={[required(), number(), minValue(0)]}/>
                    <BooleanInput source="Required" validate={[required(), choices([true, false])]} />
                    {/* <NumberInput source="Points" validate={[number(), minValue(0)]} /> */}
                </SimpleFormIterator>
            </ArrayInput>
        </FormTab>
    </TabbedForm>
)