import React from "react";
import { required, SimpleForm, TextInput, ReferenceInput, SelectInput, BooleanInput, LongTextInput } from "react-admin";

export default ({ mode, ...props }) => (
    <SimpleForm
        {...props}
        redirect="list"
    >
        <ReferenceInput label="Category" source="FaqCategoryId" reference="faq-category">
            <SelectInput optionText="Name" />
        </ReferenceInput>
        <TextInput source="Question" label="Question" validate={[required()]} />
        <TextInput source="QuestionEn" label="Question En" />
        <LongTextInput source="Answer" label="Answer" validate={[required()]} />
        <LongTextInput source="AnswerEn" label="Answer En" />
        <BooleanInput source="IsFrequently" />
    </SimpleForm>
);
