import React from "react";
import { Datagrid, List, Pagination, TextField, DateField, Filter, DateInput } from "react-admin";

const Filters = props => (
  <Filter {...props}>
      <DateInput
          source="BookingFrom"
          options={{ format: "MM-DD-YYYY" }}
      />
      <DateInput
          source="BookingTo"
          options={{ format: "MM-DD-YYYY" }}
      />
      <DateInput
          source="StayFrom"
          options={{ format: "MM-DD-YYYY" }}
      />
      <DateInput
          source="StayTo"
          options={{ format: "MM-DD-YYYY" }}
      />
  </Filter>
);

export default props => (
  <List
    {...props}
    bulkActionButtons={false}
    filters={<Filters />}
    pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
    perPage={25}
    sort={{
      field: "BookingFrom",
      order: "ASC",
    }}
  >
    <Datagrid>
      <TextField source="Name" sortable={false} />
      <DateField source="BookingFrom" sortable={false} />
      <DateField source="BookingTo" sortable={false} />
      <DateField source="StayFrom" sortable={false} />
      <DateField source="StayTo" sortable={false} />
      <TextField source="PromotionPoints" sortable={false} />
      <TextField source="PNR" sortable={false} />
      <DateField source="StayDate" sortable={false} />
      <TextField source="AgentCode" sortable={false} />
      <TextField source="Username" sortable={false} />
      <TextField source="TierName" sortable={false} />
      <TextField source="Point" sortable={false} />
    </Datagrid>
  </List>
);