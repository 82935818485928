import React from "react";
import {
  Filter,
  SelectInput
} from "react-admin";

export default props => (
    <Filter {...props}>
        <SelectInput source="Active" choices={[
            { id: "ALL", name:"All" },
            { id: 1, name: "Yes" },
            { id: 0, name: "No" },
        ]} />
    </Filter>
)