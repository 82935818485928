import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Button, UPDATE, DisabledInput, TextInput, refreshView, required, SaveButton, showNotification, SimpleForm, withDataProvider } from 'react-admin';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { change, isSubmitting, submit } from 'redux-form';

const formName = "post-quick-add-gcc";

const styles = createStyles({
    button: {
        position: 'relative',
    },
    iconPaddingStyle: {
        marginRight: '0.5em',
    },
});

class AddGCCButton extends Component {
    state = {
        error: false,
        showDialog: false,
    };
    
    handleClick = () => {
        this.setState({ showDialog: true });
    };
    
    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    handleSaveClick = () => {
        const { submit } = this.props;

        // Trigger a submit of our custom quick create form
        // This is needed because our modal action buttons are oustide the form
        submit(formName);
    };

    handleSubmit = values => {
        console.log(values)
        const { change, dispatch, dataProvider } = this.props;
        dataProvider(UPDATE, `redemptions`, {
            id: values.id,
            data: {
                GiftCardCode: values.GiftCardCode
            }
        })
            .then(() => {
                dispatch(change(formName, 'GiftCardCode', values.GiftCardCode));
                dispatch(showNotification(`Gift Card Code added`));
                dispatch(refreshView());
            })
            .catch(e => {
                console.error(e);
                dispatch(change(formName, 'GiftCardCode', ''));
                dispatch(showNotification(`Error: Add Gift Card Code failed: ${e}`));
                dispatch(refreshView());
            });
    };

    render() {
        const { showDialog } = this.state;
        const { isSubmitting, record, className, classes, dispatch, refreshView } = this.props;
        // if (record.Status !== 'ACTIVE') {
        //     return (<div />);
        // }
        return (
            <Fragment>
                <Button
                    label="Gift Card Code"
                    style={{ width: "155px", height: "48px" }}
                    onClick={this.handleClick}
                >
                    <AddIcon />
                </Button>
                <Dialog
                    open={showDialog}
                    onClose={this.handleCloseClick}
                    aria-labelledby={`${formName}-${record.id}`}
                    fullWidth={true}
                    onExited={
                        () => {
                            dispatch(change(formName, 'GiftCardCode', ''));
                            dispatch(refreshView());
                        }
                    }
                >
                    <DialogTitle id={`${formName}-${record.id}`}>Gift Card Code</DialogTitle>
                    <DialogContent>
                        <SimpleForm
                            form={formName}
                            resource="redemptions"
                            onSubmit={this.handleSubmit}
                            toolbar={null}
                            defaultValue={{
                                id: record.id,
                                AgentCode: record.AgentCode,
                                UserId: record.UserId,
                                Name: record.Name,
                                TotalValue: record.TotalValue,
                                TotalPoints: record.TotalPoints,
                                GiftCardCode: record.GiftCardCode,
                            }}
                        >
                            <DisabledInput
                                fullWidth
                                source="id"
                                label="ID"
                                validate={[required()]}
                            />
                            <DisabledInput
                                fullWidth
                                source="AgentCode"
                                label="Agent Code"
                                validate={[required()]}
                            />
                            <DisabledInput
                                fullWidth
                                source="UserId"
                                label="User ID"
                                validate={[required()]}
                            />
                            <DisabledInput
                                fullWidth
                                source="Name"
                                label="Reward Name"
                                validate={[required()]}
                            />
                            <DisabledInput
                                fullWidth
                                source="TotalValue"
                                label="Reward Value"
                                validate={[required()]}
                            />
                            <DisabledInput
                                fullWidth
                                source="TotalPoints"
                                label="Point"
                                validate={[required()]}
                            />
                            <TextInput
                                fullWidth
                                source="GiftCardCode"
                                label="Gift Card Code"
                                validate={[required()]}
                            />
                        </SimpleForm>
                    </DialogContent>
                    <DialogActions>
                        <SaveButton
                            saving={isSubmitting}
                            onClick={this.handleSaveClick}
                        />
                        <Button
                            size="large"
                            className={classnames(classes.button, className)}
                            variant="raised"
                            color="default"
                            label="Cancel"
                            onClick={this.handleCloseClick}
                        >
                            <CancelIcon
                                size={25}
                                thickness={2}
                                className={classes.iconPaddingStyle}
                            />
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

AddGCCButton.propTypes = {
    dataProvider: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    isSubmitting: isSubmitting(formName)(state),
});

const mapDispatchToProps = {
    change,
    refreshView,
    showNotification,
    submit
};

const enhance = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles),
    withDataProvider
);

export default enhance(AddGCCButton);