const inflection = require("inflection");

export const categories = {
    CITY: "CITY",
    COUNTRY: "COUNTRY",
    HOTEL: "HOTEL",
};

export const categoryOptions = Object.keys(categories).map(key => {
    return {
        id: categories[key],
        name: inflection.titleize(categories[key]),
    };
});

export const applicationCategories = {
    ALL: "ALL",
    AGENT: "AGENT",
    // CITY: "CITY",
    COUNTRY: "COUNTRY",
};

export const applicationCategoryOptions = Object.keys(applicationCategories).map(key => {
    return {
        id: applicationCategories[key],
        name: inflection.titleize(applicationCategories[key]),
    };
});