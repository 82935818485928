import React from "react";
import {
    Datagrid,
    DateField,
    DateInput,
    Filter,
    List,
    Pagination,
    TextField,
    TextInput
} from "react-admin"
import { exporter } from "./Exporter"

const Filters = props => (
    <Filter {...props}>
        <TextInput label="Order Id" source="OrderId" />
        <TextInput label="Order No" source="OrderNo" />
        <TextInput label="Receiver Name" source="ReceiverName" />
        <TextInput label="Like Order No" source="LikeOrderNo" />
        <DateInput
            source="UTCApproveFrom"
            label="Approved Date From"
            options={{ format: "MM-DD-YYYY" }}
        />
        <DateInput
            source="UTCApproveTo"
            label="Approved Date To"
            options={{ format: "MM-DD-YYYY" }}
        />
        <DateInput
            source="UTCCreated"
            label="Created Date From"
            options={{ format: "MM-DD-YYYY" }}
        />
        <DateInput
            source="UTCCreatedTo"
            label="Created Date To"
            options={{ format: "MM-DD-YYYY" }}
        />
    </Filter>
);

export default ({ permissions, ...props }) => (
    <List
        {...props}
        bulkActionButtons={false}
        exporter={exporter}
        filters={<Filters />}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
        perPage={25}
        sort={{
            field: "UTCCreated",
            order: "DESC"
        }}
    >
        <Datagrid rowClick="show">
            <TextField label="Id" source="id" />
            <DateField source="UTCCreated" label="Date" showTime />
            <TextField source="OrderId" label="Order Id" sortable={false} />
            <TextField source="OrderNo" label="Order No" sortable={false} />
            <TextField source="ReceiverName" label="Receiver Name" sortable={false} />
            <TextField source="ReceiverPhone" label="Receiver Phone" sortable={false} />
            <TextField source="Status" label="Status" sortable={false} />
            <TextField source="Message" label="Message" sortable={false} />
        </Datagrid>
    </List>
);
