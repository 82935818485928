import { withStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React from "react";
import { Button, Mutation } from "react-admin";

const styles =  theme => ({
    button: {
        margin: theme.spacing.unit
    },
    leftIcon: {
        marginRight: theme.spacing.unit
    }
})

const ToggleActiveButton = ({record, classes}) => {
    return (
        <Mutation
            type="UPDATE"
            resource="amazing"
            payload={{
                id: record.id + "/toggleActive",
                // data: {
                //     Active: !record.Active,
                // },
            }}
            options={{
                undoable: false,
                onSuccess: {
                    notification: 'Success',
                    // redirectTo: '/amazing'
                },
                onError: {
                    notification: { body: 'Failed', level: 'warning' },
                    // redirectTo: '/amazing'
                }
            }}
        >
            {
                (activate) => (
                    <Button onClick={activate} label={record.Active ? 'Deactivate' : 'Activate'}>
                        <CheckCircleIcon />
                    </Button>
                )
            }
        </Mutation>
    )
}

export default withStyles(styles)(ToggleActiveButton)