import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_CHECK,
  AUTH_GET_PERMISSIONS
} from "react-admin";
import { AUTH_ERROR } from "ra-core";

const API_URL = process.env.REACT_APP_API_URL;

export default (type, params) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const request = new Request(`${API_URL}/admins/login`, {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: new Headers({
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST"
      })
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(res => {
        localStorage.setItem("id", JSON.stringify(res.data.id));
        localStorage.setItem("token", res.token);
        localStorage.setItem("role", JSON.stringify(res.data.role));
      });
  }
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem("id");
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("permissions");
    return Promise.resolve();
  }
  if (type === AUTH_CHECK) {
    return localStorage.getItem("token") ? Promise.resolve() : Promise.reject();
  }
  if (type === AUTH_GET_PERMISSIONS) {
    const key = "permissions";
    return fetch(new Request(`${API_URL}/admins/permissions`, {
      method: "GET",
      headers: new Headers({
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      })
    }))
      .then(res => res.json())
      .then(json => {
        localStorage.setItem(key, JSON.stringify(json));
        return Promise.resolve(json);
      })
      .catch(error => Promise.reject(error));
  }
  if (type === AUTH_ERROR) {
    const {
      status
    } = params;
    if (status === 401 || status === 403) {
      localStorage.removeItem("id");
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      localStorage.removeItem("permissions");
      return Promise.reject();
    }
    return Promise.resolve();
  }
  return Promise.reject("Unknown method");
};
