import React from "react";
import { Create, SimpleForm, TextInput } from "react-admin";

export const PermissionCreate = props => (
  <Create title={"Create Permission"} {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Permission" source="id" />
      <TextInput label="Description" source="Description" />
    </SimpleForm>
  </Create>
);
