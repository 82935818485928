import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { blue, green } from '@material-ui/core/colors';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { Loading, Query } from 'react-admin';

const inflection = require("inflection");

const styles = {
    odd: {
        backgroundColor: blue[200],
    },
    even: {
        backgroundColor: blue[100],
    },
    completed: {
        backgroundColor: green[200],
    },
}

const ReportsTable = props => {
    const {
        // amazing,
        stages,
        requirements,
        rows,
    } = props;
    return (
        <Table padding="dense">
            <TableHead>
                <TableRow>
                    {/* <TableCell align="center" rowSpan={2}>Member ID</TableCell> */}
                    <TableCell align="center" rowSpan={2}>Agent Code</TableCell>
                    <TableCell align="center" rowSpan={2}>User ID</TableCell>
                    {
                        stages &&
                        stages.map(({Stage, TotalRequirement}, index) => (
                            <TableCell
                                key={index}
                                align="center"
                                colSpan={TotalRequirement}
                                style={Stage % 2 === 0 ? styles.even : styles.odd}
                            >
                                {`Stage ${Stage}`}
                            </TableCell>
                        ))
                    }
                </TableRow>
                <TableRow>
                    {
                        requirements &&
                        requirements.map(({Stage, Ref, RoomNight}, index) => (
                            <TableCell
                                key={index}
                                align="center"
                                style={Stage%2 === 0 ? styles.even : styles.odd}
                            >
                                {inflection.titleize(Ref) + ` (${RoomNight} RN)`}
                            </TableCell>
                        ))
                    }
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    rows && rows.map(({MemberID, AgentCode, UserID, stages, ...rest0}) => {
                        return (
                            <TableRow
                                key={MemberID}
                                hover={true}
                            >
                                {/* <TableCell
                                    align="center"
                                >
                                    { MemberID }
                                </TableCell> */}
                                <TableCell
                                    align="center"
                                >
                                    { AgentCode }
                                </TableCell>
                                <TableCell
                                    align="center"
                                >
                                    { UserID }
                                </TableCell>
                                {
                                    stages.map(({Stage, requirements, ...rest1}) => requirements.map(({progress: {Completed, RoomNight, TargetRoomNight, ...progress}, ...rest2}, index) => (
                                        <TableCell
                                            key={index}
                                            align="center"
                                            style={Completed && Boolean(Completed) === true ? styles.completed : (Stage ? (Stage%2 === 0 ? styles.even : styles.odd) : undefined)}
                                        >
                                            {/* { RoomNight ? `${RoomNight}/${TargetRoomNight}` : `-` } */}
                                            { RoomNight ? `${RoomNight}` : `-` }
                                            {/* { RoomNight || `-` } */}
                                        </TableCell>
                                    )))
                                }
                            </TableRow>
                        )
                    })
                }
            </TableBody>
        </Table>
    )
}

const Reports = ({match: {params: {id}}, ...props}) => (
    <Card>
        <CardContent>
            <Query
                type="GET_ONE"
                resource="amazing/progresses"
                payload={{
                    id
                }}
            >
                {
                    ({data, loading, error}) => {
                        if (loading) { return <Loading />; }
                        if (error) { return <p>ERROR</p>; }
                        return (<ReportsTable {...data} />)
                    }
                }
            </Query>
        </CardContent>
    </Card>
)

export default Reports;