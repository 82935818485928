const inflection = require("inflection");

export const category = {
    CASH: "CASH",
    GARUDA: "GARUDA",
    HOLIDAY: "HOLIDAY",
    HOTEL: "HOTEL",
    PRODUCT: "PRODUCT",
    VOUCHER: "VOUCHER",
    TOKOPEDIA: "TOKOPEDIA",
    OVO: "OVO",
    MAP: "MAP",
    MAP_FOR_CWT: "MAP_FOR_CWT"
}

export const categoryOptions = Object.keys(category).map(key => {
    return {
        id: category[key],
        name: inflection.titleize(category[key]),
    };
});