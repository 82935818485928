import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, crudUpdateMany } from "react-admin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class BulkAction extends Component {
  handleClick = () => {
    const { basePath, crudUpdateMany, resource, selectedIds } = this.props;

    crudUpdateMany(resource, selectedIds, { Status: "APPROVED"}, basePath);
  };

  render() {    
    return (
      <div>
        <Button label="Approve" onClick={this.handleClick}>
          <FontAwesomeIcon icon="check-circle" />
        </Button>
      </div>
      
    );
  }
}

export default connect(
  undefined,
  { crudUpdateMany }
)(BulkAction);
