import React, { Component } from "react";
import Chart from "react-apexcharts";
import axios from "axios";


export class RegisGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          id: "basic-bar",
          toolbar: {
            show: true,
          }
        },
        xaxis: {
          categories: [],
          // type: "datetime",
          width: 1300
        },
        plotOptions: {
          bar: {
            columnWidth: "50%"
          }
        }
      },
      series: [
        {
          name: "Registration",
          data: []
        }
      ]
    };
  }
  

   async fetchData(){
    const token = await localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
    const API_URL = process.env.REACT_APP_API_URL;
     await axios
      .get(`${API_URL}/members/reg`)
      .then(res => {
        let categories = [];
        let value = [];
        res.data.map(
          data =>
            categories.push(data["date_format(utc_created, '%Y-%m')"]) &&
            value.push(data["count(*)"])
        );

        for (let i = 0; i < categories.length; i++) {
          const yearMonth = categories[i].split('-')
          switch(yearMonth[1]) {
            case "01":
              categories[i] = `Jan ${yearMonth[0]}`;
              break;
            case "02":
              categories[i] = `Feb ${yearMonth[0]}`;
              break;
            case "03":
              categories[i] = `Mar ${yearMonth[0]}`;
              break;
            case "04":
              categories[i] = `Apr ${yearMonth[0]}`;
              break;
            case "05":
              categories[i] = `May ${yearMonth[0]}`;
              break;
            case "06":
              categories[i] = `Jun ${yearMonth[0]}`;
              break;
            case "07":
              categories[i] = `Jul ${yearMonth[0]}`;
              break;
            case "08":
              categories[i] = `Aug ${yearMonth[0]}`;
              break;
            case "09":
              categories[i] = `Sep ${yearMonth[0]}`;
              break;
            case "10":
              categories[i] = `Oct ${yearMonth[0]}`;
              break;
            case "11":
              categories[i] = `Nov ${yearMonth[0]}`;
              break;
            case "12":
              categories[i] = `Dec ${yearMonth[0]}`;
              break;
            default:
              console.log("error");
              break;
          }
          // switch (categories[i]) {
          //   case "2019-01":
          //     categories[i] = "January 2019";
          //     break;
          //   case "2019-02":
          //     categories[i] = "February 2019";
          //     break;
          //   case "2019-03":
          //     categories[i] = "March 2019";
          //     break;
          //   case "2019-04":
          //     categories[i] = "April 2019";
          //     break;
          //   case "2019-05":
          //     categories[i] = "May 2019";
          //     break;
          //   case "2019-06":
          //     categories[i] = "June 2019";
          //     break;
          //   case "2019-07":
          //     categories[i] = "July 2019";
          //     break;
          //   case "2019-08":
          //     categories[i] = "August 2019";
          //     break;
          //   case "2019-09":
          //     categories[i] = "September 2019";
          //     break;
          //   case "2019-10":
          //     categories[i] = "October 2019";
          //     break;
          //   case "2019-11":
          //     categories[i] = "November 2019";
          //     break;
          //   case "2019-12":
          //     categories[i] = "December 2019";
          //     break;
          //   case "2020-01":
          //     categories[i] = "January 2020";
          //     break;
          //   case "2020-02":
          //     categories[i] = "February 2020";
          //     break;
          //   case "2020-03":
          //     categories[i] = "March 2020";
          //     break;
          //   default:
          //     console.log("error");
          //     break;
          // }
        }
        let test = this.state.options.xaxis.categories;
        
        if (value.length < categories.length) {
          categories.pop();
        }
        for( let i=0; i<value.length; i++) {
          if(value[i] === 0){
            categories.splice(i,1)
            value.splice(i,1)
          }
        }
        this.setState(prev => {
          prev.options.xaxis.categories = categories.map(data =>
            test.push(data)
          );
          prev.series.map(n => (n.data = value));
        });
      })
      .catch(error => console.log(error));
  }
 

  componentDidMount() {
      this.fetchData()
  }

  render() {

    const styles = {
      center: {
        textAlign: "center",
        fontFamily: "Roboto",
        fontWeight: "normal"
      },
      max: {
        minwidth: "100%"
      }
    };
    return (
      <div className="mixed-chart" id="basic-bar">
        <h3 style={styles.center}> Registration per Month</h3>
        {this.fetchData()? <Chart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          width={"1300"}
          height="300"
        />  : null}
        
        
      </div>
    );
  }
}

export default RegisGraph;
