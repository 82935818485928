import React, {Component} from "react";
import ReactApexChart from "react-apexcharts";
import axios from "axios";
import moment from "moment";

export class Points extends Component {
  constructor(props) {
    super(props);

    let months = [];
    let monthsRequired = 12

    for (let i = monthsRequired; i >= 1; i--) {
      months.push( moment().subtract(i, 'months').format('MMMM YYYY') )
    }

    console.log(months)

    this.state = {
      series: [
        {
          name: "Distributed Points",
          data: []
        },
        {
          name: "Claimed Points",
          data: []
        }
      ],
      options: {
        chart: {
          animations: {speed: 1500},
          height: 350,
          type: "line",
          id:"chart",
          shadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 1
          },
          toolbar: {
            show: false
          }
        },
        colors: ["#239023", "#b30000"],
        dataLabels: {
          enabled: true
        },
        stroke: {
          curve: "smooth"
        },
        title: {
          text: "Distributed and claimed points",
          align: "left"
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        markers: {
          size: 1
        },
        xaxis: {
          categories: months,
          title: {
            text: "Month"
          }
        },
        yaxis: {
          title: {
            text: "Points"
          },
          min: 0,
          max: 200000
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5
        }
      }
    };
  }

  async fetchData(){
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
    const API_URL = process.env.REACT_APP_API_URL;

    await axios
      .all([
        await axios.get(`${API_URL}/account-statements/distributed`),
        await axios.get(`${API_URL}/account-statements/claimed`)
      ])
      .then(
        await axios.spread((distributed, claimed) => {
          // penampung data dari distributed API
          let distDate = [];
          let distVal = [];
          // penampung data dari claimed API
          let claimedDate = [];
          let claimedVal = [];

          distributed.data.map(
            dist =>
              distVal.push(dist["sum(amount)"]) &&
              distDate.push(dist["date_format(date,'%M %Y')"])
          );
          claimed.data.map(
            cl =>
              claimedVal.push(cl["sum(amount)"]) &&
              claimedDate.push(cl["date_format(date,'%M %Y')"])
          );
            this.setState({
              series: [
                {
                  name: "Distributed Points",
                  data: distVal
                },
                {
                  name: "Claimed Points",
                  data: claimedVal
                }
              ]
            })
        })
        
        )
        
        .catch(error => console.log(error));
  }

   componentDidMount() {
    this.fetchData()
  }

  render() {
        
    const styles = {
        center: {
          textAlign: "center",
          fontFamily: "Roboto",
          fontWeight: 'normal'

        },
        max: {
            minwidth: '100%',
        }
    };    
    return (
      <div id="chart" className="chart">
      <h3 style={styles.center}> Distributed and Claimed Points</h3>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="line"
          height={300}
          width={1300}
        />
      </div>
    );
  }
}

export default Points
