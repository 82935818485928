import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  Toolbar,
  SaveButton,
  SelectInput,
  SelectArrayInput,
  ReferenceArrayInput
} from "react-admin";
import BackButton from '../Button/BackButton'

const PostEditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
    <BackButton/>
  </Toolbar>
);

export const AdminsEdit = props => (
  <Edit title={"Edit Country"} {...props}>
    <SimpleForm toolbar={<PostEditToolbar />}>
      <TextInput label="User ID" source="UserId" />
      <ReferenceArrayInput
        label="Country"
        source="CountryName"
        reference="countries"
        sort={{ field: "Name", order: "ASC" }}
      >
        <SelectArrayInput optionText="Name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        label="Permission"
        source="Permission"
        reference="permissions"
        sort={{ field: "id", order: "ASC" }}
      >
        <SelectArrayInput optionText="id" />
      </ReferenceArrayInput>
      <SelectInput
        source="Status"
        choices={[{ id: 0, name: "Non active" }, { id: 1, name: "Active" }]}
      />
      <TextInput label="Password" source="Password" type="password" />
    </SimpleForm>
  </Edit>
);
