import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { showNotification, Button } from "react-admin";
import { push } from "react-router-redux";
import DownIcon from "@material-ui/icons/ExpandMore";
import Tooltip from "@material-ui/core/Tooltip";

class DownButton extends Component {
    handleClick = () => {
        const { push, record, showNotification } = this.props;

        fetch(`${process.env.REACT_APP_API_URL}/pages/${record.id}/move/down`, {
            method: "PUT",
            headers: {
                accept: "application/json",
                "content-type": "application/json; charset=utf-8",
                authorization: `Bearer ${localStorage.token}`,
                "x-api-key": process.env.REACT_APP_API_KEY
            }
        })
            .then(() => {
                showNotification("Down Order Success");
                push("/pages");
            })
            .catch(e => {
                showNotification("Error: Down Order problem", "warning");
            });
    };

    render() {
        const {
            record: { Publish }
        } = this.props;

        return (
            Publish === true && (
                <Button onClick={this.handleClick}>
                    <Tooltip title="Down Order">
                        <DownIcon />
                    </Tooltip>
                </Button>
            )
        );
    }
}

DownButton.propTypes = {
    push: PropTypes.func,
    record: PropTypes.object,
    showNotification: PropTypes.func
};

export default connect(
    null,
    {
        showNotification,
        push
    }
)(DownButton);
