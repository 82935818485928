import inflection from "inflection";

export const status = {
    PENDING: "PENDING",
    APPROVED: "APPROVED",
    REJECTED: "REJECTED",
    CANCELLED: "CANCELLED"
};

export const statusOptions = Object.keys(status).map(key => {
    return {
        id: status[key],
        name: inflection.titleize(status[key])
    };
});
