import React from "react";
import {
    Edit,
    SimpleForm,
    DisabledInput,
    BooleanInput,
    LongTextInput,
    DateInput,
    SelectInput,
    TextInput,
    Toolbar,
    SaveButton,
    NumberInput,
    required,
    email
} from "react-admin";
import axios from "axios";

const emailValidation = async allValues => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.defaults.headers.common["X-API-KEY"] = process.env.REACT_APP_API_KEY;

    const { data: result } = await axios.post(
        `/validate/editProfile`,
        allValues
    );
    if (Object.keys(result).length > 0) {
        throw result;
    }
};

const PostEditToolbar = props => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);

export default props => (
    <Edit title={"Edit Member"} {...props}>
        <SimpleForm
            toolbar={<PostEditToolbar />}
            asyncValidate={emailValidation}
            asyncBlurFields={["Email"]}
        >
            <DisabledInput label="Id" source="id" />
            <DisabledInput source="AgentCode" label="Agent Code" />
            <DisabledInput source="agent.Name" label="Agent Name" />
            <BooleanInput source="IsBlacklisted" label="Blacklist Redemption" />
            <BooleanInput source="IsMultiUser" label="Multiuser" />
            <DisabledInput source="UserId" />
            <TextInput source="NIK" />
            <TextInput source="Name" />
            <TextInput source="NPWP" />
            <TextInput source="OpsigoUserId" label="Opsigo User Id" />
            <DateInput source="Birthdate" />
            <TextInput source="Email" validate={[required(), email()]} />
            <TextInput source="Phone" label="Phone Number" />
            <LongTextInput source="Address" />
            <TextInput source="City" />
            <SelectInput
                source="Gender"
                choices={[
                    { id: "M", name: "Male" },
                    { id: "F", name: "Female" }
                ]}
            />
            <SelectInput
                source="Status"
                choices={[
                    { id: "PENDING", name: "Pending" },
                    { id: "ACTIVE", name: "Active" },
                    { id: "FROZEN", name: "Frozen" },
                    { id: "REJECTED", name: "Rejected" },
                    { id: "DELETED", name: "Deleted" }
                ]}
            />
            <TextInput source="ClaimBankName" label="Bank Name" />
            <TextInput source="ClaimBankAddress" label="Bank Address" />
            <NumberInput source="ClaimBankAccount" label="Bank Account No" />
            <TextInput source="ClaimBankHolder" label="Bank Holder" />
            <TextInput source="ClaimSwift" label="Swift Code" />
            <TextInput source="ClaimBusinessReg" label="Business Reg No" />
            <TextInput source="ClaimGarudaMilesID" label="Garuda Miles ID" />
            <TextInput source="ClaimPassport" label="Passport No" />
        </SimpleForm>
    </Edit>
);
