import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { showNotification, Button } from "react-admin";
import { push } from "react-router-redux";
import ResetIcon from "@material-ui/icons/Restore";

class ResetPassword extends Component {
    handleClick = () => {
        const { push, record, showNotification } = this.props;
        fetch(
            `${process.env.REACT_APP_API_URL}/members/${
                record.id
            }/reset-password`,
            {
                method: "POST",
                headers: {
                    accept: "application/json",
                    "content-type": "application/json; charset=utf-8",
                    authorization: `Bearer ${localStorage.token}`,
                    "x-api-key": process.env.REACT_APP_API_KEY
                }
            }
        )
            .then(() => {
                showNotification("Reset Password Success");
                push("/members");
            })
            .catch(e => {
                showNotification("Error: Reset password problem", "warning");
            });
    };

    render() {
        return (
            <Button label="Reset PWD" onClick={this.handleClick}>
                <ResetIcon />
            </Button>
        );
    }
}

ResetPassword.propTypes = {
    push: PropTypes.func,
    record: PropTypes.object,
    showNotification: PropTypes.func
};

export default connect(
    null,
    {
        showNotification,
        push
    }
)(ResetPassword);
