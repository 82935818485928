import React from "react";
import { AutocompleteInput, BooleanInput, DateInput, FormDataConsumer, ImageField, ImageInput, minValue, maxValue, number, NumberInput, REDUX_FORM_NAME, ReferenceInput, required, SelectInput, SimpleForm, TextInput, DisabledInput } from "react-admin";
import { change } from "redux-form";
import { categories, categoryOptions, applicationCategories, applicationCategoryOptions } from "./config";

const inflection = require("inflection");

export default ({mode, ...props}) => (
    <SimpleForm
        {...props}
        redirect="list"
    >
        <FormDataConsumer>
            {
                ({dispatch, ...rest}) => (
                    <SelectInput
                        choices={categoryOptions}
                        onChange={
                            () => [
                                "Ref",
                                "Rating",
                                "Address",
                                "City",
                                "Country",
                                "Image"
                            ].forEach(field => dispatch(change(REDUX_FORM_NAME, field, null)))
                        }
                        source="Category"
                        validate={[required()]}
                    />
                )
            }
        </FormDataConsumer>
        <FormDataConsumer>
            {
                ({ formData: { Category, hotel, ...formData }, dispatch, ...rest }) => Category === 'HOTEL' ? (
                    <BooleanInput
                        source="freeTextRef"
                        defaultValue={mode === 'EDIT' && !hotel}
                        onChange={
                            () => dispatch(change(REDUX_FORM_NAME, 'Ref', null))
                        }
                    />
                ) : undefined
            }
        </FormDataConsumer>
        <FormDataConsumer>
            {
                ({ formData: { Category, freeTextRef, hotel, ...formData}, resource, ...rest }) => {
                    if (freeTextRef === true) {
                        return (
                            <TextInput
                                fullWidth={true}
                                label={Category ? `${inflection.titleize(Category)} Reference` : "Reference"}
                                source="Ref"
                                validate={[required()]}
                            />
                        );
                    } else if (freeTextRef === true && (mode === 'EDIT' && !hotel)) {
                        return (
                            <TextInput
                                fullWidth={true}
                                label={Category ? `${inflection.titleize(Category)} Reference` : "Reference"}
                                source="Ref"
                                validate={[required()]}
                            />
                        );
                    }
                    switch (Category) {
                    case categories.HOTEL:
                        return (
                            <ReferenceInput
                                label="Hotel Reference"
                                reference="hotels"
                                resource={resource}
                                sort={{ field: 'Name', order: 'ASC' }}
                                source="Ref"
                                validate={[required()]}
                            >
                                <AutocompleteInput
                                    options={{fullWidth: true}}
                                    optionText={choice => `${choice.id} - ${choice.Name}`}
                                    translateChoice={false}
                                />
                            </ReferenceInput>
                        );
                    default:
                        return (
                            <TextInput
                                fullWidth={true}
                                label={Category ? `${inflection.titleize(Category)} Reference` : "Reference"}
                                source="Ref"
                                validate={[required()]}
                            />
                        );
                    }
                }
            }
        </FormDataConsumer>
        <FormDataConsumer>
            {
                ({ formData: { Category }, ...rest }) => Category === categories.HOTEL ? (
                    <NumberInput 
                        fullWidth={true}
                        source="Rating"
                        validate={[
                            // required(),
                            number(),
                            minValue(1),
                            maxValue(5),
                        ]}
                    />
                ) : undefined
            }
        </FormDataConsumer>
        <FormDataConsumer>
            {
                ({ formData: { Category }, ...rest }) => Category === categories.HOTEL ? (
                    <TextInput
                        fullWidth={true}
                        source="Address"
                        // validate={[required()]}
                    />
                ) : undefined
            }
        </FormDataConsumer>
        <FormDataConsumer>
            {
                ({ formData: { Category }, ...rest }) => Category === categories.HOTEL ? (
                    <TextInput
                        fullWidth={true}
                        source="City"
                        // validate={[required()]}
                    />
                ) : undefined
            }
        </FormDataConsumer>
        <FormDataConsumer>
            {
                ({ formData: { Category }, ...rest }) => Category === categories.HOTEL ? (
                    <TextInput
                        fullWidth={true}
                        source="Country"
                        // validate={[required()]}
                    />
                ) : undefined
            }
        </FormDataConsumer>
        <FormDataConsumer>
            {
                ({ formData: { Category }, ...rest }) => Category === categories.HOTEL ? (
                    <TextInput
                        fullWidth={true}
                        source="Description"
                        // validate={[required()]}
                    />
                ) : undefined
            }
        </FormDataConsumer>
        <DateInput
            defaultValue={new Date()}
            label="Booking From"
            source="BookingFrom"
            validate={[required()]}
        />
        <DateInput
            defaultValue={new Date()}
            label="Booking To"
            source="BookingTo"
            validate={[required()]}
        />
        <DateInput
            defaultValue={new Date()}
            label="Stay From"
            source="StayFrom"
            validate={[required()]}
        />
        <DateInput
            defaultValue={new Date()}
            label="Stay To"
            source="StayTo"
            validate={[required()]}
        />
        <NumberInput
            defaultValue={1}
            source="Points"
            disabled={ mode === "EDIT" ? true : false }
            validate={[required(), number()]}
        />
        <NumberInput
            defaultValue={0}
            label="Gold Limit"
            source="GoldLimit"
            disabled={ mode === "EDIT" ? true : false }
            validate={[number()]}
        />
        <DisabledInput source="GoldBalance" />
        <NumberInput
            defaultValue={0}
            label="Silver Limit"
            source="SilverLimit"
            disabled={ mode === "EDIT" ? true : false }
            validate={[number()]}
        />
        <DisabledInput source="SilverBalance" />
        <FormDataConsumer>
            {
                ({dispatch, ...rest}) => (
                    <SelectInput
                        choices={applicationCategoryOptions}
                        label="Application Category"
                        onChange={() => dispatch(change(REDUX_FORM_NAME, 'AppliesTo', null))}
                        source="ApplicationCategory"
                        validate={[required()]}
                    />
                )
            }
        </FormDataConsumer>
        <FormDataConsumer>
            {
                ({ formData: { ApplicationCategory }, resource, ...rest }) => {
                    switch (ApplicationCategory) {
                        case applicationCategories.COUNTRY:
                            return (
                            <ReferenceInput
                                label="Promotion applies to all agent in"
                                reference="countries"
                                resource={resource}
                                sort={{ field: 'Name', order: 'ASC' }}
                                source="AppliesTo"
                                validate={[required()]}
                            >
                                <SelectInput
                                    fullWidth={true}
                                    optionText={choice => `${choice.id} - ${choice.Name}` }
                                />
                            </ReferenceInput>
                            );
                        case applicationCategories.AGENT:
                            return (
                            <ReferenceInput
                                label="Promotion applies to all member of agent"
                                reference="agents"
                                resource={resource}
                                sort={{ field: 'Name', order: 'ASC' }}
                                source="AppliesTo"
                                validate={[required()]}
                            >
                                <AutocompleteInput
                                    options={{fullWidth: true}}
                                    optionText={choice => `${choice.id} - ${choice.Name}`}
                                />
                            </ReferenceInput>
                            );
                        default:
                            return (
                                <TextInput
                                    fullWidth={true}
                                    label="Promotion applies to"
                                    source="AppliesTo"
                                    validate={[required()]}
                                />
                            );
                    }
                }
            }
        </FormDataConsumer>
        <BooleanInput source="Active" label="Mark as active" defaultValue={false} />
        <FormDataConsumer>
            {
                ({ formData: { Category }, ...rest }) => Category === categories.HOTEL ? (
                    <BooleanInput
                        defaultValue={false}
                        label="Display on Hotel Promotions page?"
                        source="Publish"
                        // validate={[required()]}
                    />
                ) : undefined
            }
        </FormDataConsumer>
        <FormDataConsumer>
            {
                ({ formData: { Category, Publish }, ...rest }) => Category === categories.HOTEL && Publish === true ? (
                    <ImageInput
                        accept="image/*"
                        // validate={[required()]}
                        source="Image"
                    >
                        <ImageField source="src" />
                    </ImageInput>
                ) : undefined
            }
        </FormDataConsumer>
    </SimpleForm>
)