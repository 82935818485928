import React from "react";
import {
    ChipField,
    Datagrid,
    DateField,
    EmailField,
    List,
    Pagination,
    TextField,
    BooleanField
    
} from "react-admin";

// const _ = require("lodash");

export default ({ permissions, ...props }) => (
    
    <List
        {...props}

        exporter={false} 
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
        perPage={25}
        sort={{
            field: "AgentCode",                                                                                                                                                                                                            
            order: "ASC"
        }}
    >
        <Datagrid>
            <TextField source="Name" label="Name" sortable={false} />
            <EmailField source="Email" sortable={false} />
            <TextField source="Phone" label="Phone" sortable={false} />
            <TextField source="agent.City" label="City" sortable={false} />
            <TextField source="Point" label="Points" sortable={false} />
            <TextField source="UserId" label="User ID" sortable={false}/>
            <TextField source="AgentCode" label="Agent Code" sortable={false} />
            <TextField source="LegacyAgentCode" label="Legacy Agent Code" sortable={false} />
            <TextField source="agent.Name" label="Agent Name" sortable={false} />
            <DateField source="RegisterDate" label="Registration Date"  sortable={false} />
            <DateField source="ApprovedDate" label="Approved Date"  sortable={false} />
            <ChipField source="Status" sortable={false} />
            <BooleanField source="GeniusMember" label="Genius Member" sortable={false} />
            <DateField source="CreatedDate" label="Created Date" showTime sortable={false} />

        </Datagrid>
    </List>
);
