import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Button, CREATE, DisabledInput, maxValue, number, NumberInput, refreshView, required, SaveButton, showNotification, SimpleForm, LongTextInput, withDataProvider } from 'react-admin';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { change, isSubmitting, submit } from 'redux-form';

const formName = "post-quick-add-member-points";

const styles = createStyles({
    button: {
        position: 'relative',
    },
    iconPaddingStyle: {
        marginRight: '0.5em',
    },
});

class AddPointsButton extends Component {
    state = {
        error: false,
        showDialog: false,
    };
    
    handleClick = () => {
        this.setState({ showDialog: true });
    };
    
    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    handleSaveClick = () => {
        const { submit } = this.props;

        // Trigger a submit of our custom quick create form
        // This is needed because our modal action buttons are oustide the form
        submit(formName);
    };

    handleSubmit = values => {
        const { change, dispatch, dataProvider } = this.props;

        dataProvider(CREATE, `account-statements/credit`, {
            data: values
        })
            .then(() => {
                dispatch(change(formName, 'Amount', 0));
                dispatch(change(formName, 'Description', `Earned points for `));
                dispatch(showNotification(`Points added`));
                dispatch(refreshView());
            })
            .catch(e => {
                console.error(e);
                dispatch(change(formName, 'Amount', 0));
                dispatch(change(formName, 'Description', `Earned points for `));
                dispatch(showNotification(`Error: Add points failed: ${e}`));
                dispatch(refreshView());
            });
    };

    render() {
        const { showDialog } = this.state;
        const { isSubmitting, record, className, classes, dispatch, refreshView } = this.props;
        if (record.Status !== 'ACTIVE') {
            return (<div />);
        }
        return (
            <Fragment>
                <Button
                    label="Add Pts"
                    onClick={this.handleClick}
                >
                    <AddIcon />
                </Button>
                <Dialog
                    open={showDialog}
                    onClose={this.handleCloseClick}
                    aria-labelledby={`${formName}-${record.id}`}
                    fullWidth={true}
                    onExited={
                        () => {
                            dispatch(change(formName, 'Amount', 0));
                            dispatch(change(formName, 'Description', 'Earned points for '));
                            dispatch(refreshView());
                        }
                    }
                >
                    <DialogTitle id={`${formName}-${record.id}`}>Add Points</DialogTitle>
                    <DialogContent>
                        <SimpleForm
                            form={formName}
                            resource="account-statements"
                            onSubmit={this.handleSubmit}
                            toolbar={null}
                            defaultValue={{
                                MemberID: record.id,
                                AgentCode: record.AgentCode,
                                UserId: record.UserId,
                                Points: record.Point,
                                Description: 'Earned points for ',
                                Source: 'MANUAL',
                            }}
                        >
                            <DisabledInput
                                fullWidth
                                source="MemberID"
                                label="Member ID"
                                validate={[required()]}
                            />
                            <DisabledInput
                                fullWidth
                                source="AgentCode"
                                label="Agent Code"
                                validate={[required()]}
                            />
                            <DisabledInput
                                fullWidth
                                source="UserId"
                                label="User ID"
                                validate={[required()]}
                            />
                            <DisabledInput
                                fullWidth
                                source="Points"
                                label="Current Points"
                                validate={[required()]}
                            />
                            <NumberInput
                                fullWidth
                                source="Amount"
                                validate={[required(), number(), maxValue(2147483647)]}
                            />
                            <LongTextInput
                                fullWidth
                                source="Description"
                                validate={[required()]}
                            />
                        </SimpleForm>
                    </DialogContent>
                    <DialogActions>
                        <SaveButton
                            saving={isSubmitting}
                            onClick={this.handleSaveClick}
                        />
                        <Button
                            size="large"
                            className={classnames(classes.button, className)}
                            variant="raised"
                            color="default"
                            label="Cancel"
                            onClick={this.handleCloseClick}
                        >
                            <CancelIcon
                                size={25}
                                thickness={2}
                                className={classes.iconPaddingStyle}
                            />
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

AddPointsButton.propTypes = {
    dataProvider: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    isSubmitting: isSubmitting(formName)(state),
});

const mapDispatchToProps = {
    change,
    refreshView,
    showNotification,
    submit
};

const enhance = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles),
    withDataProvider
);

export default enhance(AddPointsButton);