import React from "react";
import {
    Datagrid,
    List,
    Pagination,
    TextField,
    EditButton
} from "react-admin";

export default props => (
    <List
        {...props}
        exporter={true}
        bulkActionButtons={false}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
        perPage={25}
    >
        <Datagrid>
            <TextField
                source="Name"
                label="Category"
                sortable={false}
            />
            <TextField
                source="NameEn"
                label="Category En"
                sortable={false}
            />
            <EditButton />
        </Datagrid>
    </List>
);
