import React, { Component } from "react";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { MenuItemLink } from 'react-admin';
import MoreIcon from '@material-ui/icons/More';
import axios from 'axios'

export class PendingMembers extends Component {
    state = {
        memberApproval: []
    }
    componentDidMount() {
        const token = localStorage.getItem("token");
        if (token) {
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }
        const API_URL = process.env.REACT_APP_API_URL
        axios.get(`${API_URL}/members/approval`)
        .then(res => {
            let member = res.headers['x-total-count']
            var reverse = member.toString().split('').reverse().join(''),
            ribuan = reverse.match(/\d{1,3}/g);
            ribuan = ribuan.join('.').split('').reverse().join('');
            this.setState({ member : ribuan})
        })
        .catch(error => {
            console.log(error)
        })
    }

    render() {
        const {
            dense,
            onMenuClick
        } = this.props;
        const styles = {
            titleLink: { textDecoration: "none", color: "#000" },
            card: {
                borderLeft: "solid 4px #4c87e6",
                margin: "1em",
                minWidth: "30%",
                minHeight: 100,
                textAlign: "center"
            },
            h2Font : {
                fontWeight: "normal",
                paddingTop: "0px",
                paddingBottom: "0px"
            },
            numberFont : {
                fontSize: "3em",
                paddingTop: "0px",
                paddingBottom: "0px"
            },
            cardFont : {
                paddingTop: "0px",
                paddingBottom: "0px"
            }
        };
        return (
        <Card style={styles.card}>
            <CardContent style={ styles.cardFont }>
                <h2 style={ styles.h2Font }>Pending Members</h2>
                <h2 style={ styles.numberFont }>{this.state.member}</h2>
            </CardContent>
            <CardActions style={ styles.cardFont }>
                <MenuItemLink
                    to="/members/approval"
                    leftIcon={<MoreIcon />}
                    onClick={onMenuClick}
                    dense={dense}
                />
            </CardActions>
        </Card>
        );
    }
}
export default PendingMembers;
