import React from "react";
import { Datagrid, SimpleList, DateField, List, NumberField, Pagination, TextField, Responsive } from "react-admin";
import AccountFilter from "./Filter";
import Actions from './Action'

export default props => (
  <List 
    {...props} 
    filters={<AccountFilter/>} 
    sort={{field: 'Date', order: 'DESC' }}
    perPage={25}
    pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
    bulkActionButtons={false}
    actions = {<Actions permissions={props.permissions} />}
    exporter={false}
  >
    <Responsive
      small={
        <SimpleList
          primaryText={({Amount}) => `${Amount}`}
          secondaryText={({Date, Description, Source, member: {Name}}) => (
            <div>
              <p>Date: {Date}</p>
              <p>Description: {Description}</p>
              <p>Source: {Source}</p>
              <p>Name: {Name}</p>
            </div>
          )}
          tertiaryText={({Account}) => Account}
        />
      }
      medium={
      <Datagrid>
        <TextField source="member.Name" label="Name" sortable={false} />
        <TextField source="member.UserId" label="User ID" sortable={false} />
        <TextField source="member.AgentCode" label="Agent Code" sortable={false} />
        <TextField source="member.Email" label="Email" sortable={false} />
        <DateField source="Date" sortable={false} />
        <TextField source="Description" sortable={false} />
        <NumberField source="Account" sortable={false} />
        <NumberField source="PreviousBalance" label="Previous Balance" sortable={false} />
        <NumberField source="Amount" sortable={false} />
        <NumberField source="Balance" sortable={false} />
        <TextField source="Source" sortable={false} />
      </Datagrid>
      }
    />
  </List>
);