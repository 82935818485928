import React from "react";
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  NumberField
} from "react-admin";

export default props => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Summary">
        <TextField source="MemberID" />
        <TextField source="RewardID" label="Reward" />
        <TextField source="Country" />
        <TextField source="Name" />
        <NumberField source="Value" />
        <NumberField source="BonusValue" label="Bonus" />
        <NumberField source="TotalValue" label="Total" />
        <NumberField source="Points" />
        <TextField source="ApprovalStatus" label="Status" />
        <TextField source="ApprovalStage" label="Stage" />
        <DateField source="UTCChecked" label="Date Checked" showTime />
        <TextField source="CheckedBy" label="Checked By" />
        <DateField source="UTCPreApproved" label="Date PreApproved" showTime />
        <TextField source="PreApprovedBy" label="PreApproved By" />
        <DateField source="UTCApproved" label="Date Approved" showTime />
        <TextField source="ApprovedBy" label="Approved By" />
        <DateField source="UTCRejected" label="Date Rejected" showTime />
        <TextField source="RejectedBy" label="Rejected By" />
        <DateField source="UTCCreated" label="Date Created" showTime />
        <TextField source="CreatedBy" label="Created By" />
        <DateField source="UTCModified" label="Date Modified" showTime />
        <TextField source="ModifiedBy" label="Modified By" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
