import React from 'react';
import { BooleanField, DateField, Show, SimpleShowLayout, TextField } from 'react-admin';

export default props => (
    <Show
        {...props}
    >
        <SimpleShowLayout>
            <TextField source="id" label="ID" />
            <TextField source="AgentID" label="Agent ID" />
            <DateField source="BookingDate" label="Booking Date" />
            <DateField source="CheckIn" label="Check-In" />
            <DateField source="CheckOut" label="Check-Out" />
            <TextField source="City" />
            <TextField source="Country" />
            <TextField source="CreatedBy" label="Created By" />
            <TextField source="FirstName" label="First Name" />
            <BooleanField source="HasPoints" label="Has Points" />
            <BooleanField source="HasUser" label="Has User" />
            <TextField source="HotelCode" label="Hotel Code" />
            <TextField source="HotelName" label="Hotel Name" />
            <BooleanField source="InPS" label="In Powersuite" />
            <TextField source="IssuingDate" label="Issuing Date" />
            <TextField source="LastName" label="Last Name" />
            <TextField source="MemberID" label="Member ID" />
            <TextField source="ModifiedBy" label="Modified By" />
            <TextField source="NTA" />
            <TextField source="Night" />
            <TextField source="NumberRoom" label="Number of Room" />
            <BooleanField source="Processed" />
            <TextField source="ReservationCode" label="Reservation Code" />
            <TextField source="RoomType" label="Room Type" />
            <TextField source="Source" />
            <TextField source="SourceReference" label="Source Reference" />
            <TextField source="PNRNumber" label="PNR Number" />
            <TextField source="Title" />
            <DateField source="UTCCreated" label="Created Date" showTime />
            <DateField source="UTCModified" label="Modified Date" showTime />
            <TextField source="UserID" label="User ID" />
            <TextField source="VoucherNo" label="Voucher No." />
        </SimpleShowLayout>
    </Show>
)