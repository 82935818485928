import React from "react";
import { required, SimpleForm, TextInput, NumberInput } from "react-admin";
import axios from "axios";

const citiesValidation = async allValues => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.defaults.headers.common["X-API-KEY"] = process.env.REACT_APP_API_KEY;

    const { data: result } = await axios.post(`/validate/cities`, allValues);
    if (Object.keys(result).length > 0) {
        throw result;
    }
};

export default ({ mode, ...props }) => (
    <SimpleForm
        {...props}
        redirect="list"
        asyncValidate={citiesValidation}
        asyncBlurFields={["City", "Country"]}
    >
        <TextInput source="City" validate={[required()]} />
        <TextInput source="Country" validate={[required()]} />
        <TextInput
            source="CountryCode"
            label="Country Code"
            helperText="ISO 3166 - 2 (i.e. INDONESIA = ID)"
        />
        <TextInput
            source="Group"
            helperText="promosi hotel di kota tersebut akan dikelempokkan sesuai isi field ini jika dibuka sesuai negara kota"
        />
        <TextInput
            source="InternationalGroup"
            helperText="promosi hotel di kota tersebut akan dikelempokkan sesuai isi field ini jika dibuka diluar negara kota"
        />
        <NumberInput source="Points" defaultValue={0} validate={[required()]} />
    </SimpleForm>
);
