import RichTextInput from "ra-input-rich-text";
import React from "react";
import {
    BooleanInput,
    FormDataConsumer,
    ImageField,
    ImageInput,
    maxLength,
    ReferenceInput,
    ReferenceArrayInput,
    required,
    CheckboxGroupInput,
    SimpleForm,
    TextInput,
    REDUX_FORM_NAME,
    DateTimeInput,
    SelectInput
} from "react-admin";
import { change } from "redux-form";

const slugify = require("slugify");

const toolbar = [
    [
        "bold",
        "italic",
        "underline",
        "strike",
        { list: "ordered" },
        { list: "bullet" }
    ]
];

export default ({ mode, ...props }) => (
    <SimpleForm {...props} redirect="list">
        <ReferenceArrayInput
            label="Country"
            source="Country"
            reference="countries"
            validate={[required()]}
        >
            <CheckboxGroupInput
                optionText={({ id, Name }) => `${id} - ${Name}`}
                translateChoice={false}
            />
        </ReferenceArrayInput>
        <ReferenceInput label="Tier" style = {{width: 300}} source="Tier" reference="point-value/tier/distinct" helperText="leave it blank if you want to set for all member">
            <SelectInput optionText="TierName" />
        </ReferenceInput>
        <FormDataConsumer>
            {({ formData, dispatch, ...rest }) => (
                <TextInput
                    source="Title"
                    label="Title (local)"
                    validate={[required(), maxLength(255)]}
                    fullWidth={true}
                    onChange={e =>
                        formData.slugify
                            ? dispatch(
                                  change(
                                      REDUX_FORM_NAME,
                                      "Slug",
                                      slugify(e.target.value, { lower: true })
                                  )
                              )
                            : undefined
                    }
                />
            )}
        </FormDataConsumer>
        <FormDataConsumer>
            {({ formData, dispatch, ...rest }) => (
                <BooleanInput
                    source="slugify"
                    label="Create Slug From Title"
                    onChange={e =>
                        e.target.checked === true && formData.Title
                            ? dispatch(
                                  change(
                                      REDUX_FORM_NAME,
                                      "Slug",
                                      slugify(formData.Title, { lower: true })
                                  )
                              )
                            : undefined
                    }
                />
            )}
        </FormDataConsumer>
        <FormDataConsumer>
            {({ formData, ...rest }) => (
                <TextInput
                    source="Slug"
                    fullWidth={true}
                    validate={[maxLength(255)]}
                    helperText="leave it blank if you want to set the banner cannot be clicked"
                    defaultValue={
                        formData.Title &&
                        slugify(formData.Title, {
                            lower: true
                        })
                    }
                />
            )}
        </FormDataConsumer>
        <BooleanInput source="Custom" label="Direct link to Custom Page" />
        <TextInput
            source="InvitationLink"
            label="HTML Code Calendar"
            fullWidth={true}
            validate={[required()]}
        />
        <TextInput
            source="Link"
            label="Link to Event Calendar"
            fullWidth={true}
            validate={[required()]}
        />
        <TextInput
            source="LocationAddress"
            label="Location Address"
            fullWidth={true}
            validate={[required()]}
        />
        <TextInput
            source="GoogleMap"
            label="Google Map Link"
            fullWidth={true}
            validate={[required()]}
        />
        <TextInput
            source="TitleEn"
            label="Title (global)"
            fullWidth={true}
            validate={[required()]}
        />
        <RichTextInput
            source="Description"
            label="Terms &amp; Conditions (local)*"
            toolbar={toolbar}
            validate={[required()]}
        />
        <RichTextInput
            source="DescriptionEn"
            label="Terms &amp; Conditions (global)*"
            toolbar={toolbar}
            validate={[required()]}
        />
        <DateTimeInput label="Start Event Date" source="StartDate" validate={[required()]} />
        <DateTimeInput label="End Event Date" source="ExpirationDate" validate={[required()]} />
        <ImageInput
            source="Image"
            accept="image/*"
            label="banner"
        >
            <ImageField source="src" />
        </ImageInput>
        <ImageInput
            source="Thumb"
            accept="image/*"
            label="Thumbnail"
        >
            <ImageField source="src" />
        </ImageInput>
        <BooleanInput source="Active" />
        <FormDataConsumer>
            { 
                ({formData, ...rest}) => <DateTimeInput label="Start Notification Date" source="StartNotificationDate" validate={ formData.Active ? [required()] : undefined} />
            }
        </FormDataConsumer>
        <FormDataConsumer>
            { 
                ({formData, ...rest}) => <DateTimeInput label="End Notification Date" source="EndNotificationDate" validate={ formData.Active ? [required()] : undefined} />
            }
        </FormDataConsumer>
    </SimpleForm>
);
