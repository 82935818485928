import React from "react";
import ReactApexChart from "react-apexcharts";
import axios from "axios";
export class MemberGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "",
      series: [],
      options: {
        chart: {
          width: 600,
          type: "pie"
        },
        colors: ['#002db3', '#239023', '#6b6b47', '#b30000', '#b36b00', '#b300b3', '#b3b300'], // 35% light
        labels: [],
      }
    };
  }
    fetchData(){
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
    const API_URL = process.env.REACT_APP_API_URL;
    axios
      .get(`${API_URL}/dashboard-cache`)
      .then(res => {
        let datas =[]
        res.data.map(data => datas.push(data))
        let lastUpdate = []
        let item =[] //nama negara
        let total = [] // jumlah akun
        let label = [] // negara & jumlah
        datas.map(data=> item.push(data.Item) && total.push(data.Value) && lastUpdate.push(data.LastUpdate) 
        && label.push(`${data.Item} - ${data.Value}`))
        label.shift()
        item.shift()
        total.shift()
        
        this.setState({
          options: {
            labels: label,
          },
          series: total,
          date: lastUpdate[0]
        });
      })
      .catch(error => console.log(error));
  }
  
  componentDidMount() {
    this.fetchData()
  }
  
  render() {
    const styles = {
      center: {
        textAlign: "center",
        fontFamily: "Roboto",
        fontWeight: 'normal',
        marginBottom: 15
      },
      max: {
          minwidth: '100%',
          height: 600
      },
      h6font: {
        margin: 0,
        textAlign: 'center',
        fontFamily: 'Roboto',
        'fontWeight': 'normal'
      }
    }
    
    return (
      <div id="chart" style={styles.max}>
        <h3 style={styles.center}>Members per Country</h3>
        <h6 style={styles.h6font}> Last Update : {this.state.date}</h6>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="pie"
          height={"auto"}
          width={500}
        />
      </div>
    );
  }
}
export default MemberGraph;