import React from "react";
import {
  Filter,
  TextInput,
  SelectInput,
  ReferenceInput
} from "react-admin";

export default props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" />
    <ReferenceInput source="Country" reference="countries" sort={{ field: "Name", order: "ASC" }}>
      <SelectInput optionText="Name" />
    </ReferenceInput>
  </Filter>
)