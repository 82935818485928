const moment = require('moment');
const uuidv4 = require('uuid/v4');

const AmazingID = uuidv4();

const stages = 4;

const initialStageValue = {
    id: null,
    AmazingID,
    Stage: null,
    Title: null,
    Description: null,
    Requirement: null,
    BonusRequirement: null,
    TotalRequirement: null,
    Points: 0,
    BonusPoints: 0
}

const initialRequirementValue = {
    id: null,
    AmazingID,
    Stage: null,
    Category: null,
    Ref: null,
    Include: null,
    RoomNight: 0,
    Required: false,
    Points: 0
}

const generateStages = (values, amount) => {
    let stages = []
    for (let i = 1; i <= amount; i++) {
        stages = stages.concat({
            ...values,
            id: uuidv4(),
            Stage: i,
            Points: i * 10,
            BonusPoints: i * 10/2
        })
    }
    return stages
}

const generateRequirements = (values, amount) => {
    let requirements = []
    for (let i = 1; i <= amount; i++) {
        requirements = requirements.concat([
            {
                ...values,
                id: uuidv4(),
                Stage: i,
                Category: 'CITY',
                Ref: `City ${i}`,
                RoomNight: (amount - (i  -1)) * 10,
                Required: true
            },
            {
                ...values,
                id: uuidv4(),
                Stage: i,
                Category: 'HOTEL',
                Ref: `Hotel ${i}`,
                RoomNight: (amount - (i  -1)) * 1,
                Required: false
            }
        ])
    }
    return requirements;
}

export const requirementCategoryChoices = [
    {
        id: 'CITY',
        name: 'City',
    },
    {
        id: 'HOTEL',
        name: 'Hotel',
    },
    // {
    //     id: 'COUNTRY',
    //     name: 'Country',
    // }
]

export const defaultValue = {
    id: AmazingID,
    Title: `Amazing Race ${moment().format('YYYY')}`,
    Tagline: null,
    Description: null,
    Start: moment().format('YYYY-MM-DD'),
    End: moment().format('YYYY-MM-DD'),
    Points: 0,
    BonusPoints: 0,
    Active: false,
    stages: generateStages(initialStageValue, stages),
    requirements: generateRequirements(initialRequirementValue, stages),
}