import React from "react";
import {
    BooleanField,
    Datagrid,
    ImageField,
    List,
    NumberField,
    Pagination,
    TextField
} from "react-admin";
import Filter from "./Filter";
import { withStyles } from "@material-ui/core/styles";

export default withStyles({
    image: { maxHeight: "3rem" }
})(({ classes, ...props }) => (
    <List
        {...props}
        // exporter={false}
        filters={<Filter />}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
        perPage={25}
    >
        <Datagrid rowClick="edit">
            <TextField
                source="Country"
                label="Reward Country"
                sortable={false}
            />
            <TextField source="Category" sortable={false} />
            <TextField source="Name" label="Name (locale)" sortable={false} />
            <TextField source="NameEn" label="Name (global)" sortable={false} />
            <NumberField source="Value" sortable={false} />
            <NumberField
                source="BonusValue"
                label="Bonus Value"
                sortable={false}
            />
            <NumberField source="Points" sortable={false} />
            <NumberField
                source="PointsDiscount"
                label="Points Discount"
                sortable={false}
            />
            <ImageField classes={classes} source="Image" sortable={false} />
            <BooleanField source="Active" sortable={false} />
        </Datagrid>
    </List>
));
