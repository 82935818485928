import jsonServerProvider from "ra-data-json-server";
import { fetchUtils } from "react-admin";

const httpClient = (url, options = {}) => {
  //console.log(url, options);

  if (!options.headers) {
    options.headers = new Headers({
      Accept: "application/json"
    });
  }
  const token = localStorage.getItem("token");
  options.headers.set("Authorization", `Bearer ${token}`);
  options.headers.set("X-API-KEY", process.env.REACT_APP_API_KEY);

  if (
    options.method &&
    ["POST", "PUT"].includes(options.method) &&
    options.body
  ) {
    const object = JSON.parse(options.body);
    if (object.hasFile) {
      let formData = new FormData();
      for (const key in object) {
        if (["hasFile", "files"].includes(key)) {
          continue;
        }
        if (object.hasOwnProperty(key)) {
          let element = object[key];
          if (object.files.includes(key)) {
            element = require("./dataUriToBlob")(element);
          }
          formData.append(key, element);
        }
      }
      //console.log(options.body, 'OPTIONS BODY');

      options.body = formData;
    }
  }
  //console.log(url, options);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = jsonServerProvider(
  `${process.env.REACT_APP_API_URL}`,
  httpClient
);

export default dataProvider;
