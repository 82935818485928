import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { showNotification, Button } from "react-admin";
import { push } from "react-router-redux";
import ActivateIcon from "@material-ui/icons/Done";
import Tooltip from "@material-ui/core/Tooltip";

class ActivateButton extends Component {
    handleClick = () => {
        const { push, record, showNotification } = this.props;
        fetch(`${process.env.REACT_APP_API_URL}/faq/${record.id}/activate`, {
            method: "PUT",
            headers: {
                accept: "application/json",
                "content-type": "application/json; charset=utf-8",
                authorization: `Bearer ${localStorage.token}`,
                "x-api-key": process.env.REACT_APP_API_KEY
            }
        })
            .then(() => {
                showNotification("Activate Success");
                push("/faq");
            })
            .catch(e => {
                showNotification("Error: Activate problem", "warning");
            });
    };

    render() {
        const {
            record: { Active }
        } = this.props;

        return (
            Active === false && (
                <Button onClick={this.handleClick}>
                    <Tooltip title="Activate">
                        <ActivateIcon />
                    </Tooltip>
                </Button>
            )
        );
    }
}

ActivateButton.propTypes = {
    push: PropTypes.func,
    record: PropTypes.object,
    showNotification: PropTypes.func
};

export default connect(
    null,
    {
        showNotification,
        push
    }
)(ActivateButton);
