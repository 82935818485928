// in src/pages/admin/Admin.js
import Chip from "@material-ui/core/Chip";
import React from "react";
import { ChipField, Datagrid, EditButton, List, TextField, Pagination } from "react-admin";
import AdminFilter from "./Filter.js"

const PermissionField = ({ record }) => (
  <span>
    {record.Permission &&
      record.Permission.map(item => (
        <Chip
          key={item}
          label={item}
        />
      ))}
  </span>
);

const CountryField = ({ record }) => (
  <span>
    {record.CountryName &&
      record.CountryName.map(item => (
        <Chip
          key={item}
          label={item}
        />
      ))}
  </span>
);

export const Admins = props => (
  <List
    {...props}
    filters={<AdminFilter/>} 
    bulkActionButtons={false}
    // exporter={false}
    pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
    perPage={25}
    sort={{ field: "UserId", order: "ASC" }}
  >
    <Datagrid>
      <TextField source="UserId" label="User ID" sortable={false} />
      <CountryField label="Country" />
      <PermissionField label="Permission" />
      <ChipField source="Status" sortable={false}/>
      <EditButton />
    </Datagrid>
  </List>
);
