import React from "react";
import {
    BooleanInput,
    ImageField,
    ImageInput,
    minValue,
    number,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput
} from "react-admin";
import { categoryOptions } from "./config";
import RichTextInput from "ra-input-rich-text";

const toolbar = [
    [
        "bold",
        "italic",
        "underline",
        "strike",
        { list: "ordered" },
        { list: "bullet" },
        "clean"
    ]
];

export default props => (
    <SimpleForm {...props} redirect="list">
        <ReferenceInput
            source="Country"
            reference="countries"
            sort={{
                field: "Name",
                order: "ASC"
            }}
            label="Reward Country"
            validate={[required()]}
        >
            <SelectInput optionText={({ id, Name }) => `${id} - ${Name}`} />
        </ReferenceInput>
        <SelectInput
            source="Category"
            choices={categoryOptions}
            validate={[required()]}
        />
        <TextInput
            source="Name"
            label="Name (locale)"
            validate={[required()]}
        />
        <TextInput source="NameEn" label="Name (global)" />
        <RichTextInput
            source="Description"
            label="Description (locale)"
            toolbar={toolbar}
            validate={[required()]}
        />
        <RichTextInput
            source="DescriptionEn"
            label="Description (global)"
            toolbar={toolbar}
        />
        <NumberInput
            source="Value"
            validate={[required(), number(), minValue(0)]}
        />
        <NumberInput
            source="BonusValue"
            label="Bonus Value"
            validate={[number(), minValue(0)]}
        />
        <NumberInput
            source="Points"
            validate={[required(), number(), minValue(0)]}
        />
        <NumberInput
            source="PointsDiscount"
            label="Points Discount"
            validate={[number(), minValue(0)]}
        />
        <NumberInput
            source="Cost"
            validate={[required(), number(), minValue(0)]}
        />
        <BooleanInput source="Active" />
        {props.mode === "CREATE" && (
            <ImageInput source="Image" accept="image/*" validate={[required()]}>
                <ImageField source="src" />
            </ImageInput>
        )}
        {props.mode === "EDIT" && (
            <ImageInput source="Image" accept="image/*">
                <ImageField source="src" />
            </ImageInput>
        )}
    </SimpleForm>
);
