import React from "react";
import { Filter, TextInput, SelectInput } from "react-admin";
import { statusOptions } from "./config";

export default props => (
    <Filter {...props}>
        <TextInput source="UserID" label="User ID" alwaysOn />
        <TextInput source="AgentCode" label="Agen Code" alwaysOn />
        <TextInput source="NewAgentCode" label="New Agent Code" alwaysOn />
        <SelectInput source="Status" choices={statusOptions} alwaysOn />
    </Filter>
);
