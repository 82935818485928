
import React from "react";
import { Create, ImageField, ImageInput, NumberInput, required, SimpleForm, TextInput } from "react-admin";

export default props => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="id" label="ID" validate={[required()]}/>
      <TextInput source="PSHotelID" label="Powersuite Hotel ID" />
      <TextInput source="Name" validate={[required()]} />
      <NumberInput source="Rating" />
      <TextInput source="Address" />
      <TextInput source="City" validate={[required()]} />
      <TextInput source="Country" validate={[required()]} />
      <ImageInput source="Image" accept="image/*">
        <ImageField source="src" />
      </ImageInput>
    </SimpleForm>
  </Create>
);
