// in src/pages/area/Area.js
import React from "react";
import { List, Datagrid, TextField, EditButton } from "react-admin";

export const Area = props => (
  <List
    title="All Agent List"
    {...props}
    sort={{ field: "id", order: "ASC" }}
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField
        source="CountryId"
        label="Country"
        style={{ fontSize: 12 }}
      />
      <TextField source="Name" label="City Name" style={{ fontSize: 12 }} />
      <TextField source="Status" label="Status" style={{ fontSize: 12 }} />
      <EditButton />
    </Datagrid>
  </List>
);
