import React from 'react';
import { approvalStatus, approvalStage } from './config';
import CustomList from './CustomList';

export default props => (
    <CustomList
        {...props}
        filter={{
            ApprovalStatus: approvalStatus.PENDING,
            ApprovalStage: approvalStage.ADMIN,
        }}
    />
);