import React from 'react';
import { Create, SimpleForm, AutocompleteInput, ReferenceInput } from 'react-admin';

export default props => (
    <Create
        {...props}
    >
        <SimpleForm redirect="/powersuite">
            <ReferenceInput label="Supplier" source="id" reference="suppliers">
                <AutocompleteInput optionText="Name" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
)