import React, { Component } from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import axios from 'axios'

export class UnclaimedPoints extends Component {
    constructor(props) {
        super(props);
    
    this.state = {
        unclaimedPoints: [],
        date: "",
    }
    }
    async fetchData(){
        const token = localStorage.getItem("token");
        if (token) {
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }
        const API_URL = process.env.REACT_APP_API_URL

        await axios.get(`${API_URL}/dashboard-cache`)
        .then(res => {
            let unclaimed = res.data[0].Value
            let lastUpdate = res.data[0].LastUpdate
            var reverse = unclaimed.toString().split('').reverse().join(''),
            ribuan = reverse.match(/\d{1,3}/g);
            ribuan = ribuan.join('.').split('').reverse().join('');
            this.setState({ unclaimed : ribuan, date:lastUpdate})
            
        })
        .catch(error => 
        console.log(error))
    }
    
    componentDidMount() {
        this.fetchData()
    }
    render() {
        const styles = {
            titleLink: { textDecoration: "none", color: "#000" },
            card: {
                borderLeft: "solid 4px #4c87e6",
                margin: "1em",
                minWidth: "30%",
                minHeight: 230,
                textAlign: "center"
            },
            h2Font : {
                fontWeight: "normal",
                paddingTop: "0px",
                paddingBottom: "0px"
            },
            numberFont : {
                fontSize: "3em",
                paddingTop: "0px",
                paddingBottom: "0px"
            },
            cardFont : {
                paddingTop: "0px",
                paddingBottom: "0px"
            }
        };
        return (
          <Card style={styles.card}>
              <CardContent style={ styles.cardFont }>
                  <h2 style={ styles.h2Font }>Unclaimed Points</h2>
                  <h5 style={ styles.h2Font }> Last Update : {this.state.date}</h5>
                  <h2 style={ styles.numberFont }>{this.state.unclaimed}</h2>
              </CardContent>
          </Card>
        );
    }
}

export default UnclaimedPoints;
