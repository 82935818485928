import React from 'react';
import { BooleanField, DateField, FunctionField, ImageField, NumberField, Show, SimpleShowLayout, TextField } from 'react-admin';
import { categories } from './config';

export default props => (
    <Show
        {...props}
    >
        <SimpleShowLayout>
            <TextField source="Category" />
            <TextField source="Ref" />
            <FunctionField source="Ref" label="Reference" render={
                ({Category, Ref, hotel}) => Category === categories.HOTEL && hotel && hotel.Name ? hotel.Name : Ref
            } />
            <FunctionField source="Rating" render={
                ({Category, Rating, hotel}) => Category === categories.HOTEL && hotel && !Rating && hotel.Rating ? hotel.Rating : Rating
            } />
            <FunctionField source="City" render={
                ({Category, City, hotel}) => Category === categories.HOTEL && hotel && !City && hotel.City ? hotel.City : City
            } />
            <FunctionField source="Country" render={
                ({Category, Country, hotel}) => Category === categories.HOTEL && hotel && !Country && hotel.Country ? hotel.Country : Country
            } />
            <DateField source="BookingFrom" label="Booking From" />
            <DateField source="BookingTo" label="Booking To" />
            <DateField source="StayFrom" label="Stay From" />
            <DateField source="StayTo" label="Stay To" />
            <NumberField source="Points" />
            <NumberField source="GoldLimit" label="Gold Limit" />
            <NumberField source="GoldBalance" label="Gold Balance" />
            <NumberField source="SilverLimit" label="Silver Limit" />
            <NumberField source="SilverBalance" label="Silver Balance" />
            <TextField source="ApplicationCategory" label="Application Category" />
            <TextField source="AppliesTo" label="Applies To" />
            <ImageField source="Image" />
            <BooleanField source="Active" />
            <BooleanField source="Publish" />
            <DateField source="UTCCreated" label="Created Date" showTime />
            <TextField source="CreatedBy" label="Created By" />
            <DateField source="UTCModified" label="Modified Date" showTime />
            <TextField source="ModifiedBy" label="Modified By" />
        </SimpleShowLayout>
    </Show>
)