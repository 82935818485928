import React from "react";
import { Datagrid, List, Pagination, TextField } from "react-admin";


export default props => (
  <List
    {...props}
    bulkActionButtons={false}
    // exporter={false}
    pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
    perPage={25}
    sort={{
      field: "Points",
      order: "DESC",
    }}
  >
    <Datagrid>
      <TextField source="id" label="Supplier Id" sortable={false} />
      <TextField source="JarvisSupplierId" label="Jarvis Supplier Id" sortable={false} />
      <TextField source="JarvisSupplierName" label="Jarvis Supplier Name" sortable={false} />
    </Datagrid>
  </List>
);