import React from "react";
import {
    Datagrid,
    List,
    Pagination,
    TextField,
    NumberField
} from "react-admin";

export default props => (
    <List
        {...props}
        exporter={false}
        bulkActionButtons={false}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
        perPage={25}
    >
        <Datagrid rowClick="edit">
            <TextField source="City" sortable={false} />
            <TextField source="Country" sortable={false} />
            <TextField
                source="CountryCode"
                label="Country Code"
                sortable={false}
            />
            <TextField source="Group" sortable={false} />
            <TextField
                source="InternationalGroup"
                label="International Group"
                sortable={false}
            />
            <NumberField source="Points" sortable={false} />
        </Datagrid>
    </List>
);
