import React from "react";
import { BooleanField, Button, Datagrid, DateField, List, Pagination, TextField } from "react-admin";
import { Link } from 'react-router-dom';
import Filter from "./Filter.js";

const LinkButton = ({basePath, record: { id }}) => (
    <Button component={Link} label="Progress" to={`${basePath}/${id}`} />
)

export default props => (
    <List
        {...props}
        // exporter={false}
        filters={<Filter />}
        bulkActionButtons={false}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]}/>}
        perPage={25}
        sort={{
            field: "Active",
            order: "DESC"
        }}
    >
        <Datagrid>
            <TextField source="Title" sortable={false}/>
            <DateField source="Start" sortable={false}/>
            <DateField source="End" sortable={false}/>
            <BooleanField source="Active" sortable={false}/>
            <LinkButton />
        </Datagrid>
    </List>
);