import React from "react";
import {
    Datagrid,
    DateField,
    List,
    Pagination,
    Responsive,
    SimpleList,
    ShowButton,
    TextField,
    showNotification,
    refreshView,
    fetchStart,
    fetchEnd,
    Button,
    FunctionField
} from "react-admin";
import Actions from "./Actions";
import Filter from "./Filter.js";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { connect } from "react-redux";

const CheckMemberButton = connect(
    null,
    {
        refreshView,
        showNotification
    }
)(props => (
    <Button
        label="Check Member"
        onClick={() => {
            const { record, refreshView, showNotification } = props;
            fetchStart();
            fetch(`${process.env.REACT_APP_API_URL}/opsigo/members`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json; charset=utf-8",
                    Authorization: `Bearer ${localStorage.token}`,
                    "X-API-KEY": process.env.REACT_APP_API_KEY
                },
                method: "POST",
                body: JSON.stringify({
                    id: record.id
                })
            })
                .then(response => response.json())
                .then(response => {
                    showNotification(
                        response.message || "Member checked",
                        response.error ? "warning" : "info"
                    );
                    refreshView();
                    fetchEnd();
                })
                .catch(error => {
                    console.error(error);
                    fetchEnd();
                });
        }}
    >
        <CheckCircleIcon />
    </Button>
));

const CheckSupplierButton = connect(null, {
    refreshView,
    showNotification,
})(props => (
    <Button label='Check Supplier' onClick={() => {
        const {
            record,
            refreshView,
            showNotification,
        } = props;
        fetchStart();
        fetch(`${process.env.REACT_APP_API_URL}/opsigo/supplier`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${localStorage.token}`,
                'X-API-KEY': process.env.REACT_APP_API_KEY,
            },
            method: 'POST',
            body: JSON.stringify({
                id: record.id,
            }),
        })
            .then(response => response.json())
            .then(response => {
                showNotification(response.message || 'Supplier checked', response.error ? 'warning' : 'info');
                refreshView();
                fetchEnd();
            })
            .catch(error => {
                console.error(error);
                fetchEnd();
            });
    }}>
        <CheckCircleIcon />
    </Button>
));

const DistributePointsButton = connect(
    null,
    {
        refreshView,
        showNotification
    }
)(props => (
    <Button
        label="Distribute Pts"
        onClick={() => {
            const { record, refreshView, showNotification } = props;
            fetchStart();
            fetch(`${process.env.REACT_APP_API_URL}/opsigo/points`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json; charset=utf-8",
                    Authorization: `Bearer ${localStorage.token}`,
                    "X-API-KEY": process.env.REACT_APP_API_KEY
                },
                method: "POST",
                body: JSON.stringify({
                    id: record.id
                })
            })
                .then(response => response.json())
                .then(response => {
                    showNotification(
                        response.message || "Points distributed",
                        response.error ? "warning" : "info"
                    );
                    refreshView();
                    fetchEnd();
                })
                .catch(error => {
                    console.error(error);
                    fetchEnd();
                });
        }}
    >
        <CheckCircleIcon />
    </Button>
));

const AddtionalButton = props => {
    const { record } = props;
    if (!record.InPs && !record.HasPoints && !record.Processed) {

        if (!record.HasUser && !record.InPs) return <CheckMemberButton {...props} />
        else if (!record.HasSupplier && !record.InPs) return (<CheckSupplierButton {...props} />)
        else return (<DistributePointsButton {...props} />)
    }
    return <div />;
};

export default props => (
    <List
        {...props}
        filters={<Filter />}
        actions={<Actions permissions={props.permissions} />}
        bulkActionButtons={false}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
        perPage={25}
        sort={{
            field: "BookingDate",
            order: "DESC"
        }}
    >
        <Responsive
            small={
                <SimpleList
                    primaryText={({ id }) => id}
                    // secondaryText={}
                    // tertiaryText={}
                    linkType="show"
                />
            }
            medium={
                <Datagrid>
                    <TextField
                        source="PNRNumber"
                        label="PNR Number"
                        sortable={false}
                    />
                    <TextField
                        source="JarvisSupplierName"
                        label="Supplier Name"
                        sortable={false}
                    />
                    <TextField
                        source="VoucherNo"
                        label="Voucher Number"
                        sortable={false}
                    />
                    <DateField
                        source="BookingDate"
                        label="Booking Date"
                        sortable={false}
                    />
                    <TextField
                        source="HotelName"
                        label="Hotel"
                        sortable={false}
                    />
                    <DateField
                        source="CheckIn"
                        label="Check-In"
                        sortable={false}
                    />
                    <DateField
                        source="CheckOut"
                        label="Check-Out"
                        sortable={false}
                    />
                    <TextField
                        source="NumberRoom"
                        label="Room"
                        sortable={false}
                    />
                    <TextField source="Night" sortable={false} />
                    <TextField label="RN" sortable={false} />
                    <FunctionField
                        label="Pax Name"
                        render={PaxName =>
                            `${PaxName.FirstName} ${PaxName.LastName}`
                        }
                        sortable={false}
                    />
                    <TextField
                        source="UserID"
                        label="User ID"
                        sortable={false}
                    />
                    <TextField
                        source="AgentID"
                        label="Agent Code"
                        sortable={false}
                    />
                    <ShowButton />
                    <AddtionalButton />
                </Datagrid>
            }
        />
    </List>
);
