import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { goBack } from 'react-router-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
    }
});

class BackButton extends Component {

    handleClick = () => {
        this.props.goBack();
    };

    render() {
        const { classes } = this.props;
        return  (
            <div>
                <Button variant="contained" color="primary" onClick={this.handleClick} className={classes.button}>Back</Button>
            </div>
        )
            
    }
}

export default compose(
    withStyles(styles),
    connect(null, {
        goBack,
    })
)(BackButton);