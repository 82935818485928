import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  Toolbar,
  SaveButton,
  SelectInput,
  ReferenceInput
} from "react-admin";

const PostEditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const AreaEdit = props => (
  <Edit title={"Edit Country"} {...props}>
    <SimpleForm toolbar={<PostEditToolbar />}>
      <ReferenceInput
        label="Country"
        source="CountryId"
        reference="countries"
        sort={{ field: "id", order: "ASC" }}
      >
        <SelectInput optionText="Name" />
      </ReferenceInput>
      <TextInput label="City Name" source="Name" />
      <SelectInput
        source="Status"
        choices={[{ id: 0, name: "Non active" }, { id: 1, name: "Active" }]}
      />
    </SimpleForm>
  </Edit>
);
