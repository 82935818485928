import React from "react";
import { BooleanField, Datagrid, DateField, DeleteButton, EditButton, List, Pagination, Responsive, SimpleList, TextField } from "react-admin";
import Filter from "./Filter.js";
import ToggleActiveButton from "./ToggleActiveButton";

const moment = require("moment")

export default props => (
    <List
        {...props}
        // exporter={false}
        filters={<Filter />}
        bulkActionButtons={false}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]}/>}
        perPage={25}
        sort={{
            field: "Active",
            order: "DESC"
        }}
    >
        <Responsive
            small={
                <SimpleList
                    primaryText={({Title}) => Title}
                    secondaryText={({Start, End}) => `${moment(Start).format('YYYY-MM-DD')} - ${moment(End).format('YYYY-MM-DD')}`}
                    // tertiaryText={}
                    linkType="show"
                />
            }
            medium={
                <Datagrid rowClick="show">
                    <TextField source="Title" sortable={false}/>
                    <DateField source="Start" sortable={false}/>
                    <DateField source="End" sortable={false}/>
                    <BooleanField source="Active" sortable={false}/>
                    <ToggleActiveButton />
                    <EditButton />
                    <DeleteButton />
                </Datagrid>
            }
        />
    </List>
);