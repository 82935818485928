import React from "react";
import { Filter, ReferenceInput, SelectInput } from "react-admin";

export default props => {
  return (
    <Filter {...props}>
      <ReferenceInput source="Country" reference="countries" alwaysOn>
        <SelectInput optionText="Name" />
      </ReferenceInput>
    </Filter>
  );
};
