import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { showNotification, Button } from "react-admin";
import { push } from "react-router-redux";
import PublishIcon from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";

class PublishButton extends Component {
    handleClick = () => {
        const { push, record, showNotification } = this.props;
        fetch(`${process.env.REACT_APP_API_URL}/pages/${record.id}/publish`, {
            method: "PUT",
            headers: {
                accept: "application/json",
                "content-type": "application/json; charset=utf-8",
                authorization: `Bearer ${localStorage.token}`,
                "x-api-key": process.env.REACT_APP_API_KEY
            }
        })
            .then(() => {
                showNotification("Publish Success");
                push("/pages");
            })
            .catch(e => {
                showNotification("Error: Publish problem", "warning");
            });
    };

    render() {
        const {
            record: { Publish }
        } = this.props;
        return (
            Publish === false && (
                <Button onClick={this.handleClick}>
                    <Tooltip title="Publish">
                        <PublishIcon />
                    </Tooltip>
                </Button>
            )
        );
    }
}

PublishButton.propTypes = {
    push: PropTypes.func,
    record: PropTypes.object,
    showNotification: PropTypes.func
};

export default connect(
    null,
    {
        showNotification,
        push
    }
)(PublishButton);
