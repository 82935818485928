import React from "react";
import { Filter, TextInput, NullableBooleanInput, SelectInput, ReferenceInput } from "react-admin";

export default props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <ReferenceInput source="Country" reference="countries" sort={{ field: "Name", order: "ASC" }}>
      <SelectInput optionText="Name" />
    </ReferenceInput>
    <NullableBooleanInput source="IsXML" label="XML" />
    <NullableBooleanInput source="IsPanorama" label="Panorama" />
    <NullableBooleanInput source="IsBlacklisted" label="Blacklist" />
    <NullableBooleanInput source="IsAffiliate" label="Affiliate" />
  </Filter>
);