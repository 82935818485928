import React from "react";
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField
} from "react-admin";

export default props => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Summary">
        <TextField source="id" />
        <TextField source="OrderId" />
        <TextField source="OrderNo" />
        <TextField source="Name" />
        <TextField source="MemberId" />
        <TextField source="ReceiverName" />
        <TextField source="ReceiverPhone" />
        <TextField source="Status" />
        <TextField source="Message" />
        <TextField source="Request"  />
        <TextField source="Response" />
        <DateField source="UTCCreated" showTime />
        <DateField source="UTCModified" showTime />
      </Tab>
    </TabbedShowLayout>
  </Show>
)
