import React from "react";
import {
    Datagrid,
    List,
    Pagination,
    TextField,
    DateField,
    NumberField,
    RichTextField,
    EditButton
} from "react-admin";

export default props => (
    <List
        {...props}
        exporter={false}
        bulkActionButtons={false}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
        perPage={25}
    >
        <Datagrid>
            <TextField
                source="TierName"
                label="Tier Name"
                sortable={false}
            />
            <TextField
                source="TierNameEn"
                label="Tier Name En"
                sortable={false}
            />
            <RichTextField
                source="TierDesc"
                label="Benefit"
                sortable={false}
            />
            <RichTextField
                source="TierDescEn"
                label="Benefit En"
                sortable={false}
            />
            <NumberField
                source="TierValue"
                label="Tier Value"
                sortable={false}
            />
            <NumberField
                source="DividerValue"
                label="Divider"
                sortable={false}
            />
            <NumberField
                source="MinSalesValue"
                label="Minimal Sales Value"
                sortable={false}
            />
            <DateField
                source="UTCCreated"
                label="UTC Created"
                sortable={false}
                showTime
                options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }}
            />
            <EditButton />
        </Datagrid>
    </List>
);
