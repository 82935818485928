import React from "react"
import {
    Filter,
    TextInput,
    NullableBooleanInput
} from "react-admin"

export default props => {
    return (
        <Filter {...props}>
            <TextInput label="Search" source="q" />
            <NullableBooleanInput source="Active" />
        </Filter>
    )
}