import React from "react";
import { Datagrid, DateField, ImageField, NumberField, Pagination, ReferenceManyField, Show, Tab, TabbedShowLayout, TextField } from "react-admin";

export default props => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Summary">
        <TextField source="id" label="ID" />
        <TextField source="PSHotelID" label="Powersuite Hotel ID" />
        <TextField source="Name" />
        <NumberField source="Rating" />
        <TextField source="Address" />
        <TextField source="City" />
        <TextField source="Country" />
        <ImageField source="Image" />
        <DateField source="UTCCreated" label="Created Date" showTime />
        <TextField source="CreatedBy" label="Created By" />
        <DateField source="UTCModified" label="Modified Date" showTime />
        <TextField source="ModifiedBy" label="Modified By" />
      </Tab>
      <Tab label="Transactions" path="transactions">
        <ReferenceManyField
          label="Transactions"
          reference="transactions"
          target="HotelId"
          source="id"
          pagination={<Pagination />}
          sort={{field: "BookingDate", order: "DESC"}}
        >
          <Datagrid>
            <DateField source="BookingDate" label="Booking Date" sortable={false} />
            <TextField source="HotelName" label="Hotel" sortable={false} />
            <DateField source="CheckIn" label="Check-In" sortable={false} />
            <DateField source="CheckOut" label="Check-Out" sortable={false} />
            <NumberField source="Room" sortable={false} />
            <NumberField source="Night" sortable={false} />
            <NumberField source="Quantity" sortable={false} />
            <TextField source="City" sortable={false} />
            <TextField source="Country" sortable={false} />
            <TextField source="PaxName" label="Pax Name" sortable={false} />
            <NumberField source="SalesBase" label="Sales Base" sortable={false} />
            <TextField source="CustomerCode" label="Customer Code" sortable={false} />
            <TextField source="UserID" label="User ID" sortable={false} />
            <TextField source="MemberID" label="MGF User ID" sortable={false} />
            <NumberField source="GroupLineNo" label="Group Line No." sortable={false} />
            <TextField source="CustomerName" label="Customer Name" sortable={false} />
            <TextField source="CustomerCity" label="Customer City" sortable={false} />
            <TextField source="CustomerCountry" label="Customer Country" sortable={false} />
            <NumberField source="Points" sortable={false} />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
