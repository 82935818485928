import React from "react";
import { Datagrid, List, Pagination, TextField } from "react-admin";

export const Country = props => (
  <List
    {...props}
    // exporter={false}
    bulkActionButtons={false}
    pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
    perPage={25}
    sort={{
      field: "Name",
      order: "ASC"
    }}
  >
    <Datagrid>
      <TextField source="id" label="Code" sortable={false} />
      <TextField source="Name" label="Name" sortable={false} />
    </Datagrid>
  </List>
);
