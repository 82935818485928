import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, crudUpdateMany  } from 'react-admin';

const MarkAsPanorama = ({ basePath, crudUpdateMany, resource, selectedIds }) => (
    <Button 
        label="Mark as Panorama Agent"
        onClick={
            () => crudUpdateMany(resource, selectedIds, {
                IsPanorama: true
            }, basePath)
        }
    />
);

const MarkAsXML = ({ basePath, crudUpdateMany, resource, selectedIds }) => (
    <Button
        label="Mark as XML Agent"
        onClick={
            () => crudUpdateMany(resource, selectedIds, {
                IsXML: true
            }, basePath)
        }
    />
);

const BulkActionButtons = props => (
    <Fragment>
        <MarkAsPanorama {...props} />
        <MarkAsXML {...props} />
    </Fragment>
);

export default connect(undefined, { crudUpdateMany })(BulkActionButtons);