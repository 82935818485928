const XLSX = require('xlsx');
const inflection = require('inflection');

module.exports = file => new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = () => {
        const oFile = XLSX.read(fileReader.result, {
            type: 'array',
            cellDates: true
        })
        const json = XLSX.utils.sheet_to_json(oFile.Sheets[oFile.SheetNames[0]])
            .map(data => {
                const newData = {}
                for (const key in data) {
                    if (data.hasOwnProperty(key)) {
                        const element = data[key];
                        newData[inflection.camelize(key.split(' ').join('_'))] = element;
                    }
                }
                return newData;
            });
        return resolve(json);
    }
    fileReader.onerror = () => reject();
});