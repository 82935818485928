import React from 'react';
import { CardActions, ExportButton } from 'react-admin';

export default ({
    filters,
    showFilter,
    displayedFilters,
    currentSort,
    exporter,
    filterValues,
    resource,
    total,
}) => (
    <CardActions>
        {
            filters && React.cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })
        }
        <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
            maxResults={50000}
        />
        {/* <RefreshButton /> */}
    </CardActions>
)
