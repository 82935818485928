import React from "react";
import { Filter, SelectInput, NullableBooleanInput, ReferenceInput } from "react-admin";
import { categoryOptions } from './config';

export default props => {
  return (
    <Filter {...props}>
      <SelectInput
        source="Category"
        choices={categoryOptions}
        alwaysOn
      />
      <NullableBooleanInput
        source="Active"
        alwaysOn
      />
      <ReferenceInput source="Country" reference="countries" sort={{ field: "Name", order: "ASC" }} alwaysOn >
        <SelectInput optionText="Name" />
      </ReferenceInput>
    </Filter>
  );
};
