import React from 'react';
import { 
    Create, 
    SimpleForm, 
    FileInput, 
    FileField, 
    required,
    SaveButton, 
    Toolbar,
} from 'react-admin';
import ExcelTemplateButton from '../Button/ExcelTemplate/ExcelTemplateButton';

const PostCreateToolbar = props => (
    <Toolbar {...props}>
        <SaveButton />
        <ExcelTemplateButton
            dataTemplate="BULK_DISTRIBUTE_POINTS"
        />
    </Toolbar>
);

export default props => (
    <Create {...props}>
        <SimpleForm 
            toolbar={<PostCreateToolbar />}
            redirect={false}>
            <FileInput
                source="upload"
                label="Upload File Bulk Distribute Points"
                validate={[required()]}
            >
                <FileField
                    source="src"
                    title="title"
                />
            </FileInput>
        </SimpleForm>
    </Create>
);