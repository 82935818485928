import { withStyles } from "@material-ui/core/styles";
import React from "react";
import {
    BooleanField,
    Datagrid,
    DateField,
    ImageField,
    List,
    Pagination,
    TextField,
    NumberField
} from "react-admin";
import Chip from "@material-ui/core/Chip";
import PageFilter from "./Filter.js";
import ActivateButton from "./ActivateButton";
import DeactivateButton from "./DeactivateButton";
import PublishButton from "./PublishButton";
import UnpublishButton from "./UnpublishButton";
import UpButton from "./UpButton";
import DownButton from "./DownButton";
import FirstButton from "./FirstButton";
import LastButton from "./LastButton";
import _ from "lodash";

const CountryField = ({ record }) => (
    <span>
        {record.Country &&
            record.Country.map(item => <Chip key={item} label={item} />)}
    </span>
);

export default withStyles({
    image: {
        maxHeight: "3rem"
    }
})(({ classes, permissions, ...props }) => (
    <List
        {...props}
        // exporter={false}
        filters={<PageFilter />}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
        perPage={25}
    >
        <Datagrid rowClick="edit">
            <TextField source="Title" label="Title (locale)" sortable={false} />
            <TextField
                source="TitleEn"
                label="Title (global)"
                sortable={false}
            />
            <TextField source="Slug" sortable={false} />
            <CountryField label="Country" />
            <ImageField classes={classes} source="Image" sortable={false} />
            <NumberField source="Order" sortable={false} />
            <BooleanField source="Active" sortable={false} />
            <BooleanField source="Publish" sortable={false} />
            <BooleanField source="Custom" sortable={false} />
            <DateField
                source="ExpirationDate"
                label="Expire Date"
                sortable={false}
            />
            {permissions &&
                _.intersection(permissions, ["ALL", "SUPERADMIN", "MARKETING"])
                    .length > 0 && <ActivateButton />}
            {permissions &&
                _.intersection(permissions, ["ALL", "SUPERADMIN", "MARKETING"])
                    .length > 0 && <DeactivateButton />}
            {permissions &&
                _.intersection(permissions, ["ALL", "SUPERADMIN", "MARKETING"])
                    .length > 0 && <PublishButton />}
            {permissions &&
                _.intersection(permissions, ["ALL", "SUPERADMIN", "MARKETING"])
                    .length > 0 && <UnpublishButton />}
            {permissions &&
                _.intersection(permissions, ["ALL", "SUPERADMIN", "MARKETING"])
                    .length > 0 && <UpButton />}
            {permissions &&
                _.intersection(permissions, ["ALL", "SUPERADMIN", "MARKETING"])
                    .length > 0 && <DownButton />}
            {permissions &&
                _.intersection(permissions, ["ALL", "SUPERADMIN", "MARKETING"])
                    .length > 0 && <FirstButton />}
            {permissions &&
                _.intersection(permissions, ["ALL", "SUPERADMIN", "MARKETING"])
                    .length > 0 && <LastButton />}
        </Datagrid>
    </List>
));
