import { withStyles } from "@material-ui/core/styles";
import React from "react";
import {
    BooleanField,
    Datagrid,
    DateField,
    ImageField,
    List,
    Pagination,
    TextField,
    RichTextField,
    EditButton
} from "react-admin";
import Chip from "@material-ui/core/Chip";
import PageFilter from "./Filter.js";

const CountryField = ({ record }) => (
    <span>
        {record.Country &&
            record.Country.map(item => <Chip key={item} label={item} />)}
    </span>
);

export default withStyles({
    image: {
        maxHeight: "3rem"
    }
})(({ classes, permissions, ...props }) => (
    <List
        {...props}
        // exporter={false}
        filters={<PageFilter />}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
        perPage={25}
    >
        <Datagrid rowClick="edit">
            <TextField source="Title" label="Title (locale)" sortable={false} />
            <TextField
                source="TitleEn"
                label="Title (global)"
                sortable={false}
            />
            <TextField source="Tier" sortable={false} />
            <TextField source="Slug" sortable={false} />
            <TextField source="LocationAddress" sortable={false} />
            <TextField source="GoogleMap" label="Google Map" sortable={false} />
            <RichTextField source="InvitationLink" label='HTML Code' sortable={false} />
            <CountryField label="Country" />
            <ImageField classes={classes} source="Image" sortable={false} />
            <BooleanField source="Active" sortable={false} />
            <DateField
                source="StartDate"
                label="Start Date"
                sortable={false}
                showTime
            />
            <DateField
                source="ExpirationDate"
                label="Expire Date"
                sortable={false}
                showTime
            />
            <EditButton />
        </Datagrid>
    </List>
));
