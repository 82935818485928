import React from "react";
import { required, SimpleForm, TextInput } from "react-admin";

export default ({ mode, ...props }) => (
    <SimpleForm
        {...props}
        redirect="list"
    >
        <TextInput source="Name" label="Category" validate={[required()]} />
        <TextInput source="NameEn" label="Category En" />
    </SimpleForm>
);
