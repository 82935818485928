import React from 'react';
import { 
    Create, 
    FileField, 
    FileInput, 
    required, 
    SimpleForm,
    SaveButton, 
    Toolbar
} from 'react-admin';

const PostCreateToolbar = props => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);

export default props => (
    <Create {...props}>
        <SimpleForm 
            toolbar={<PostCreateToolbar />}
            redirect="list">
            <FileInput source="upload" label="Upload Opsigo" validate={[required()]}>
                <FileField source="src" title="title" />
            </FileInput>
        </SimpleForm>
    </Create>
)