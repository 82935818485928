import React from "react";
import _ from "lodash";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faUser,
    faUsers,
    faAngleDoubleRight,
    faBriefcase,
    faGift,
    faHistory,
    faCalendarAlt,
    faUserPlus,
    faFlag,
    faPlus,
    faBan,
    faCheckCircle,
    faGlobeAsia,
    faCity
} from "@fortawesome/free-solid-svg-icons";
import { Admin, Resource, Login } from "react-admin";
import authProvider from "./authProvider";
import dataProvider from "./dataProvider";
import enhanceDataProvider from "./enhanceDataProvider";
import Layout from "./Layout";
import {
    List as MemberList,
    Show as MemberShow,
    Edit as MemberEdit
} from "./pages/member";
import {List as unclaimedPointsList }from './pages/unclaimedPoints'
import { Dashboard } from "./dashboard";
import { MemberApproval } from "./pages/memberApproval/MemberApproval";
import { Country } from "./pages/country/Country";
// import { CountryCreate } from "./pages/country/CountryCreate";
// import { CountryEdit } from "./pages/country/CountryEdit";
import { Area } from "./pages/area/Area";
import { AreaCreate } from "./pages/area/AreaCreate";
import { AreaEdit } from "./pages/area/AreaEdit";
import ChangeAgentList from "./pages/changeAgent/List";
import ChangeAgentShow from "./pages/changeAgent/Show";
import UpdateJarvisShow from "./pages/agent/UpdateJarvisCode";
import BulkUploadHotels from './pages/hotel/BulkUpload'
import BulkDistributePoints from "./pages/agent/BulkDistributePoints";
import BulkUpdateUserId from "./pages/powersuite/BulkUpdateUserId";
import PointCreateFromSupplier from './pages/powersuite/CreateFromSupplier'
import { Admins } from "./pages/admin/Admins";
import { AdminsCreate } from "./pages/admin/AdminsCreate";
import { AdminsEdit } from "./pages/admin/AdminsEdit";
import { Permission } from "./pages/permission/Permission";
import { PermissionCreate } from "./pages/permission/PermissionCreate";
import { PermissionEdit } from "./pages/permission/PermissionEdit";
import AccountStatement from "./pages/accountStatement/List";
import AccountStatementShow from "./pages/accountStatement/Show";
import { AmazingCreate, AmazingEdit, AmazingList, AmazingShow } from "./pages";
import {
    List as PowersuiteList,
    Show as PowersuiteShow,
    Create as PowersuiteCreate
} from "./pages/powersuite";
import TransactionList from "./pages/transaction/List.js";
import TransactionShow from "./pages/transaction/Show.js";
import {
    Create as HotelCreate,
    // HotelUpload as HotelUpload,
    Edit as HotelEdit,
    List as HotelList,
    Show as HotelShow
} from "./pages/hotel";
import Reward from "./pages/reward/List";
import RewardCreate from "./pages/reward/Create";
import RewardEdit from "./pages/reward/Edit";
import {
    Edit as SupplierEdit,
    List as SupplierList,
    Show as SupplierShow
} from "./pages/supplier";
import Page from "./pages/page/List";
import PageCreate from "./pages/page/Create";
import PageEdit from "./pages/page/Edit";
import {
    Create as PromotionCreate,
    Edit as PromotionEdit,
    List as PromotionList,
    Show as PromotionShow
} from "./pages/promotion";
import Redemption from "./pages/redemption/List";
import RedemptionShow from "./pages/redemption/Show";
import TestimonialList from "./pages/testimonial/List";
import TestimonialCreate from "./pages/testimonial/Create";
import TestimonialEdit from "./pages/testimonial/Edit";
import TestimonialShow from "./pages/testimonial/Show";
import {
    List as OpsigoList,
    Show as OpsigoShow,
    Create as OpsigoCreate
} from "./pages/opsigo";
import {
    List as Agent,
    Edit as AgentEdit,
    Show as AgentShow,
    Create as AgentCreate
} from "./pages/agent";
import {
    List as Cities,
    Edit as CitiesEdit,
    Create as CitiesCreate
} from "./pages/cities";
import {
    List as LookupList,
    Edit as LookupEdit,
    Create as LookupCreate
} from "./pages/lookup";
import {
    List as PointList,
    Edit as PointEdit,
    Create as PointCreate
} from "./pages/pointValue";
import {
    List as FaqList,
    Edit as FaqEdit,
    Create as FaqCreate
} from "./pages/faq";
import {
    List as FaqCategoryList,
    Edit as FaqCategoryEdit,
    Create as FaqCategoryCreate
} from "./pages/faqCategory";
import {
    List as TncList,
    Edit as TncEdit,
    Create as TncCreate
} from "./pages/tnc";
import {
    List as RedemptionRequestList,
    Show as RedemptionRequestShow
} from "./pages/redemptionRequest"
import Event from "./pages/event/List";
import EventCreate from "./pages/event/Create";
import EventEdit from "./pages/event/Edit";

import customRoutes from "./customRoutes";

library.add(
    faUser,
    faUsers,
    faAngleDoubleRight,
    faBriefcase,
    faGift,
    faHistory,
    faCalendarAlt,
    faUserPlus,
    faFlag,
    faPlus,
    faBan,
    faCheckCircle,
    faGlobeAsia,
    faCity
);

const enhancedDataProvider = enhanceDataProvider(dataProvider);

const App = () => (
    <Admin
        customRoutes={customRoutes}
        dataProvider={enhancedDataProvider}
        authProvider={authProvider}
        appLayout={Layout}
        loginPage={() => (
            <Login backgroundImage="https://source.unsplash.com/VieM9BdZKFo" />
        )}
        dashboard={Dashboard}
    >
        {permissions => [
            permissions &&
                _.intersection(permissions, [
                    "SUPERADMIN",
                    "SUPERVISOR",
                    "ADMIN"
                ]).length > 0 && (
                    <Resource
                        key="Account Statement"
                        name="account-statements"
                        list={AccountStatement}
                        show={AccountStatementShow}
                    />
                ),
            <Resource key="Point History" name="point/member"/>,
            permissions &&
                _.intersection(permissions, ["SUPERADMIN", "USER"]).length > 0 && (
                    <Resource
                        key="Admin"
                        name="admins"
                        list={Admins}
                        create={AdminsCreate}
                        edit={AdminsEdit}
                    />
                ),
            <Resource
                key="Admins"
                name="admins"
                edit={AdminsEdit}
            />,
            permissions &&
                _.intersection(permissions, [
                    "SUPERADMIN",
                    "SUPERVISOR",
                    "ADMIN"
                ]).length > 0 && (
                    <Resource
                        key="Agent"
                        name="agents"
                        list={Agent}
                        edit={AgentEdit}
                        show={AgentShow}
                        create={AgentCreate}
                    />
                ),
            permissions &&
                _.intersection(permissions, ["MARKETING"]).length > 0 && (
                    <Resource
                        key="Agent"
                        name="agents"
                        list={Agent}
                        show={AgentShow}
                    />
                ),
            <Resource key="Amazing Progress" name="amazing/progresses" />,
            <Resource key="Amazing Transaction" name="amazing/transactions" />,
            <Resource
                key="Amazing"
                name="amazing"
                create={AmazingCreate}
                edit={AmazingEdit}
                list={AmazingList}
                show={AmazingShow}
            />,
            <Resource
                key="Area"
                name="areas"
                list={Area}
                create={AreaCreate}
                edit={AreaEdit}
            />,
            permissions &&
                _.intersection(permissions, [
                    "SUPERADMIN",
                    "SUPERVISOR",
                    "ADMIN"
                ]).length > 0 && (
                    <Resource
                        key="Change Agent"
                        name="change-agent"
                        list={ChangeAgentList}
                        show={ChangeAgentShow}
                    />
                ),
            <Resource key="Update Jarvis" name="jarvis-code" create={UpdateJarvisShow}/>,
            <Resource key="Upload Hotels" name="bulk-upload-hotels" create={BulkUploadHotels}/>,
            <Resource key="Bulk Distribute Points" name="bulk-distribute-points" create={BulkDistributePoints}/>,
            <Resource key="Bulk Update User Id" name="powersuite/bulk-update" create={BulkUpdateUserId}/>,
            <Resource key="Distribute Point From Supplier" name="powersuite/from-supplier" create={PointCreateFromSupplier}/>,
            permissions &&
                _.intersection(permissions, ["SUPERADMIN", "USER"]).length > 0 && (
                    <Resource
                        key="City"
                        name="hotels/cities"
                        create={CitiesCreate}
                        edit={CitiesEdit}
                        list={Cities}
                    />
                ),
            <Resource key="Country" name="countries" list={Country} />,
            <Resource key="hotels-cities" name="hotels/cities" />,
            <Resource key="hotels-countries" name="hotels/countries" />,
            permissions &&
                _.intersection(permissions, [
                    "SUPERADMIN",
                    "SUPERVISOR",
                    "ADMIN",
                    "MARKETING"
                ]).length > 0 && (
                    <Resource
                        key="Hotel"
                        name="hotels"
                        create={HotelCreate}
                        edit={HotelEdit}
                        list={HotelList}
                        show={HotelShow}
                    />
                ),
            permissions &&
                _.intersection(permissions, [
                    "SUPERADMIN",
                    "SUPERVISOR",
                    "ADMIN"
                ]).length > 0 && (
                    <Resource name="members/approval" list={MemberApproval} />
                ),
            <Resource name="members/status" />,
            permissions &&
                _.intersection(permissions, [
                    "SUPERADMIN",
                    "SUPERVISOR",
                    "ADMIN"
                ]).length > 0 && (
                    <Resource
                        key="Member"
                        name="members"
                        list={MemberList}
                        edit={MemberEdit}
                        show={MemberShow}
                    />
                ),
            permissions &&
                _.intersection(permissions, ["MARKETING"]).length > 0 && (
                    <Resource
                        key="Member"
                        name="members"
                        list={MemberList}
                        show={MemberShow}
                    />
                ),
                /* unclaimed points */

                permissions &&
                _.intersection(permissions, ["SUPERADMIN", "ADMIN"]).length >
                    0 && (
                    <Resource
                        key="Unclaimed Points"
                        name="unclaimed-points"
                        list={unclaimedPointsList}
                    />
                ),
            permissions &&
                _.intersection(permissions, ["SUPERADMIN", "ADMIN"]).length >
                    0 && (
                    <Resource
                        key="Opsigo"
                        name="opsigo"
                        list={OpsigoList}
                        show={OpsigoShow}
                        create={OpsigoCreate}
                    />
                ),
            permissions &&
                _.intersection(permissions, ["SUPERADMIN", "MARKETING"])
                    .length > 0 && (
                    <Resource
                        key="Page"
                        name="pages"
                        list={Page}
                        create={PageCreate}
                        edit={PageEdit}
                    />
                ),
            permissions &&
                _.intersection(permissions, ["SUPERADMIN", "MARKETING"])
                    .length > 0 && (
                    <Resource
                        key="Event"
                        name="events"
                        list={Event}
                        create={EventCreate}
                        edit={EventEdit}
                    />
                ),
            permissions &&
                _.intersection(permissions, ["SUPERADMIN", "USER"]).length > 0 && (
                    <Resource
                        key="Permission"
                        name="permissions"
                        list={Permission}
                        create={PermissionCreate}
                        edit={PermissionEdit}
                    />
                ),
            permissions &&
                _.intersection(permissions, ["SUPERADMIN", "ADMIN"]).length >
                    0 && (
                    <Resource
                        key="Powersuite"
                        name="powersuite"
                        list={PowersuiteList}
                        show={PowersuiteShow}
                        create={PowersuiteCreate}
                    />
                ),
            <Resource
                key="Promotion Application Categories"
                name="promotions/application-categories"
            />,
            <Resource
                key="Promotion Categories"
                name="promotions/categories"
            />,
            permissions &&
                _.intersection(permissions, [
                    "SUPERADMIN",
                    "SUPERVISOR",
                    "ADMIN",
                    "MARKETING"
                ]).length > 0 && (
                    <Resource
                        key="Promotion"
                        name="promotions"
                        list={PromotionList}
                        create={PromotionCreate}
                        edit={PromotionEdit}
                        show={PromotionShow}
                    />
                ),
            <Resource
                key="Redemption"
                name="redemptions"
                list={Redemption}
                show={RedemptionShow}
            />,
            permissions &&
                _.intersection(permissions, [
                    "SUPERADMIN",
                    "SUPERVISOR",
                    "MARKETING",
                    "ADMIN"
                ]).length > 0 && (
                    <Resource
                        key="Reward"
                        name="rewards"
                        list={Reward}
                        create={RewardCreate}
                        edit={RewardEdit}
                    />
                ),
            permissions &&
                _.intersection(permissions, [
                    "SUPERADMIN",
                    "SUPERVISOR",
                    "MARKETING",
                    "ADMIN"
                ]).length > 0 && (
                    <Resource
                        key="RedemptionRequest"
                        name="redemption-request"
                        list={RedemptionRequestList}
                        show={RedemptionRequestShow}
                    />
                ),
            permissions &&
                _.intersection(permissions, [
                    "SUPERADMIN",
                    "SUPERVISOR",
                    "ADMIN"
                ]).length > 0 && (
                    <Resource
                        key="Supplier"
                        name="suppliers"
                        list={SupplierList}
                        show={SupplierShow}
                        edit={SupplierEdit}
                    />
                ),
            permissions &&
                _.intersection(permissions, ["SUPERADMIN", "MARKETING"])
                    .length > 0 && (
                    <Resource
                        key="Testimoni"
                        name="testimonials"
                        list={TestimonialList}
                        show={TestimonialShow}
                        create={TestimonialCreate}
                        edit={TestimonialEdit}
                    />
                ),
            permissions &&
                _.intersection(permissions, ["SUPERADMIN", "ADMIN", "MARKETING"])
                    .length > 0 && (
                    <Resource
                        key="Faq"
                        name="faq"
                        list={FaqList}
                        create={FaqCreate}
                        edit={FaqEdit}
                    />
                ),
            permissions &&
                _.intersection(permissions, ["SUPERADMIN", "ADMIN", "MARKETING"])
                    .length > 0 && (
                    <Resource
                        key="faq-category"
                        name="faq-category"
                        list={FaqCategoryList}
                        create={FaqCategoryCreate}
                        edit={FaqCategoryEdit}
                    />
                ),
            permissions &&
                _.intersection(permissions, ["SUPERADMIN", "ADMIN", "MARKETING"])
                    .length > 0 && (
                    <Resource
                        key="tnc"
                        name="tnc"
                        list={TncList}
                        create={TncCreate}
                        edit={TncEdit}
                    />
                ),
            permissions &&
                _.intersection(permissions, ["SUPERADMIN", "ADMIN", "SUPERVISOR"])
                    .length > 0 && (
                    <Resource
                        key="lookup"
                        name="lookup"
                        list={LookupList}
                        create={LookupCreate}
                        edit={LookupEdit}
                    />
                ),
            permissions &&
                _.intersection(permissions, ["SUPERADMIN", "ADMIN", "SUPERVISOR"])
                    .length > 0 && (
                    <Resource
                        key="point-value"
                        name="point-value"
                        list={PointList}
                        create={PointCreate}
                        edit={PointEdit}
                    />
                ),
            <Resource
                key="tier-info"
                name="point-value/tier/distinct"
            />,
            permissions &&
                _.intersection(permissions, [
                    "SUPERADMIN",
                    "SUPERVISOR",
                    "ADMIN"
                ]).length > 0 && (
                    <Resource
                        key="Transaction"
                        name="transactions"
                        list={TransactionList}
                        show={TransactionShow}
                    />
                ),
            <Resource key="Transactions Report" name="transactions/reports" />,
            <Resource key="Unregister Suppliers" name="suppliers/unregister-suppliers" />,
            <Resource key="Active Promotions" name="promotions/active-promotion" />,
            <Resource key="My Account" name="my-account" />
        ]}
    </Admin>
);

export default App;
