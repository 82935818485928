import React from 'react';
import { 
    Edit, 
    TextInput, 
    SimpleForm, 
    Toolbar,
    SaveButton,
    DisabledInput
} from 'react-admin';
import BackButton from '../Button/BackButton'

const PostEditToolbar = props => (
    <Toolbar {...props}>
      <SaveButton />
      <BackButton/>
    </Toolbar>
);

export default props => {
    let id = localStorage.id
    return (
        <Edit
            id={id.replace(/^"(.*)"$/, '$1')}
            redirect={false}
            title="My Account"
            {...props}
        >
            <SimpleForm
                toolbar={<PostEditToolbar />}
            >
                <DisabledInput label="User ID" source="UserId" />
                <TextInput label="Password" source="Password" type="password" />
            </SimpleForm>
        </Edit>
    )
}