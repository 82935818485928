import React from "react";
import {
  Edit,
  SimpleForm,
  Toolbar,
  SaveButton,
  NumberInput
} from "react-admin";

const CustomToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export default props => (
  <Edit {...props}>
    <SimpleForm redirect="list" toolbar={<CustomToolbar />}>
      <NumberInput source="Points" />
    </SimpleForm>
  </Edit>
);
