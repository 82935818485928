import React from "react"
import {
    DateInput,
    Filter,
    TextInput
} from "react-admin"

export default props => {
    return (
        <Filter {...props}>
            <TextInput label="Agent Code" source="AgentCode" />
            <TextInput label="User Id" source="UserID" /> 
            <TextInput label="PNR Number" source="PNRNumber" />
            <TextInput label="Voucher Number" source="VoucherNumber" />
            <TextInput label="Hotel" source="hotel" />
            <DateInput source="CheckIn" label="Check In Date" options={{ format: 'MM-DD-YYYY' }} />
            <DateInput source="CheckOut" label="Check Out Date" options={{ format: 'MM-DD-YYYY' }} />
        </Filter>
    )
}