import React from 'react';
import { Create } from 'react-admin';
import { defaultValue } from './config';
import Form from './Form';

export default props => (
    <Create {...props}>
        <Form
            defaultValue={defaultValue}
            mode="CREATE"
        />
    </Create>
);
