import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput
} from "react-admin";

export const AreaCreate = props => (
  <Create title={"Create Country"} {...props}>
    <SimpleForm redirect="list">
      <ReferenceInput
        label="Country"
        source="CountryId"
        reference="countries"
        sort={{ field: "id", order: "ASC" }}
      >
        <SelectInput optionText="Name" />
      </ReferenceInput>
      <TextInput label="City Name" source="Name" />
    </SimpleForm>
  </Create>
);
