import React from 'react';
import { Create, SimpleForm, DateInput } from 'react-admin';

export default props => (
    <Create
        {...props}
    >
        <SimpleForm redirect="list">
            <DateInput source="CheckOut" />
        </SimpleForm>
    </Create>
)