import React from 'react';
import { CardActions, CreateButton, ExportButton, RefreshButton} from 'react-admin';
const _ = require('lodash');

export default ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    permissions,
    total
}) => (
    <CardActions>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }

        {
            _.intersection(permissions, ["SUPERADMIN"]).length > 0 &&
            <CreateButton
                basePath={basePath}
                label="Upload"
            />
        }
        
        { <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
            maxResult
        />}
        {<RefreshButton /> }
    </CardActions>
)