import React from "react";
import {
    Datagrid,
    List,
    NumberField,
    Pagination,
    TextField
} from "react-admin";
import ReportFilter from "./ReportFilter";
import Action from './Action'

export default props => (
    <List
        {...props}
        bulkActionButtons={false}
        actions = {<Action />}
        exporter={false}
        filters={<ReportFilter />}
        filterDefaultValues={{
            type: "mtd",
            group: "member"
            // CheckOutFrom: "2019-06-01"
        }}
        sort={{ field: "Quantity", order: "DESC" }}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
        perPage={25}
    >
        <Datagrid>
            <TextField
                source="members.UserId"
                label="User ID"
                sortable={false}
            />
            <TextField source="members.Name" label="Name" sortable={false} />
            <TextField
                source="CustomerCode"
                label="Agent Code"
                sortable={false}
            />
            <TextField
                source="CustomerName"
                label="Agent Name"
                sortable={false}
            />
            <TextField
                source="CustomerCountry"
                label="Agent Country"
                sortable={false}
            />
            <TextField source="members.Email" label="Email" sortable={false} />
            <TextField source="HotelId" label="Hotel ID" sortable={false} />
            <TextField source="HotelName" label="Hotel Name" sortable={false} />
            <TextField
                source="Country"
                label="Hotel Country"
                sortable={false}
            />
            <NumberField
                source="Quantity"
                label="Room Night"
                sortable={false}
            />
        </Datagrid>
    </List>
);
